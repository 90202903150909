import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";
import { faCalendarDays, faChild, faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalDetailsSchool from "./elements/ModalDetailsSchool";
import ModalOrderSchool from "./elements/ModalOrderSchool";

function School(props) {
  const { t } = useTranslation();
  const basePath = "kindgarden.direc.school.";

  const [modalShow, setModalShow] = useState(false);
  const [modalShowOrder, setModalShowOrder] = useState(false);

  return (
    <Row className="align-items-center mt-150">
      <Col lg={7}>
        <h4>
          <FontAwesomeIcon icon={faGraduationCap} />
          {t(basePath + "title.sub")} <span>{t(basePath + "title.main")}</span>
        </h4>
        <div className="sub-info">
          <span>
            <FontAwesomeIcon icon={faChild} />
            {t(basePath + "age")}
          </span>
          <span>
            <FontAwesomeIcon icon={faCalendarDays} />
            {t(basePath + "time")}
          </span>
          <div className="content">
            <p className="f-size-16">{t(basePath + "desc.one")}</p>
            <p className="f-size-16">{t(basePath + "desc.two")}</p>
          </div>
          <div className="d-flex mt-30">
            <Button onClick={() => setModalShow(true)} className="btn-st">
              {t("kindgarden.direc.btnMore")}
            </Button>
            <Button onClick={() => setModalShowOrder(true)} className="btn-st">
              {t("kindgarden.direc.btnBook")}
            </Button>
          </div>
        </div>
      </Col>
      <Col lg={5}>
        <div className="widget mt-100">
          <img src={require("../../../assets/img/kinder/school/img-1.jpg").default} alt="" />
          <img src={require("../../../assets/img/kinder/school/img-2.jpg").default} alt="" />
          <img
            className="shape"
            src={require("../../../assets/img/kinder/school/img-3.jpg").default}
            alt=""
            style={{
              width: "210px",
              height: "220px",
              top: "-130px",
              left: "0",
            }}
          />
        </div>
      </Col>

      <ModalOrderSchool show={modalShowOrder} onHide={() => setModalShowOrder(false)} />
      <ModalDetailsSchool show={modalShow} onHide={() => setModalShow(false)} />
    </Row>
  );
}

export default School;
