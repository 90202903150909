import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { faChild, faFaceSmile, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

import ModalOrderDrawing from "./elements/ModalOrderDrawing";

function Drawing(props) {
  const { t } = useTranslation();
  const basePath = "kindgarden.direc.drawing.";

  const [modalShowOrder, setModalShowOrder] = useState(false);

  const settings = {
    dots: false,
    arrows: true,
    speed: 800,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const sliderItem = [
    { img: require("../../../assets/img/kinder/drawing/img-1.jpg") },
    { img: require("../../../assets/img/kinder/drawing/img-2.jpg") },
    { img: require("../../../assets/img/kinder/drawing/img-3.jpg") },
    { img: require("../../../assets/img/kinder/drawing/img-4.jpg") },
    { img: require("../../../assets/img/kinder/drawing/img-5.jpg") },
  ];

  return (
    <Row className="align-items-center mt-150">
      <Col lg={5}>
        <Row>
          <Slider {...settings}>
            {sliderItem.map((item, i) => (
              <Col lg={3} md={6} key={i} className="mb-30">
                <div className="slid" key={i}>
                  <div className="item">
                    <div
                      className="img"
                      style={{
                        backgroundImage: `url(${item.img.default})`,
                      }}
                    ></div>
                  </div>
                </div>
              </Col>
            ))}
          </Slider>
        </Row>
      </Col>
      <Col lg={7} className="pl-30">
        <h4>
          <FontAwesomeIcon icon={faWandMagicSparkles} />
          {t(basePath + "title.sub")} <span>{t(basePath + "title.main")}</span>
        </h4>
        <div className="sub-info">
          <span>
            <FontAwesomeIcon icon={faFaceSmile} />
            {t(basePath + "age")}
          </span>
          <span>
            <FontAwesomeIcon icon={faChild} />
            {t(basePath + "time")}
          </span>
          <div className="content">
            <p className="f-size-16">{t(basePath + "desc.one")}</p>
            <p className="f-size-16">{t(basePath + "desc.two")}</p>
          </div>
          <div className="d-flex mt-30">
            <Button onClick={() => setModalShowOrder(true)} className="btn-st">
              {t("kindgarden.direc.btnBook")}
            </Button>
          </div>
        </div>
      </Col>

      <ModalOrderDrawing show={modalShowOrder} onHide={() => setModalShowOrder(false)} />
    </Row>
  );
}

export default Drawing;
