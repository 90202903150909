import React from "react";
import Routes from "./router/Routes";
import { LanguageProvider } from "./contexts/LanguageContext";

const App = () => {
  return (
    <>
      <LanguageProvider>
        <Routes />
      </LanguageProvider>
    </>
  );
};

export default App;
