export const TRANSLATIONS_FR = {
  navBar: {
    site: "Notre site web",
    btnHelp: "COMMENT VOUS POUVEZ AIDER",
    btnRequ: "DEMANDEZ DE L'AIDE",

    link: {
      home: "Domicile",
      center: "Centre pour enfants",
      about: "À propos de notre fonds",
      news: "Nouvelles",
      nft: "NFT Galerie",
      contact: "Contacts",
    },

    lang: {
      title: "Langue",
      "en-US": "Anglais",
      "ua-UA": "Ukrainien",
      "es-ES": "Espagnol",
      "fr-FR": "Français",
      "de-DE": "Allemand",
      "it-IT": "Italien",
      "pl-PL": "Polonais",
      "pt-PT": "Portugais",
    },
  },

  pageBanner: {
    home: "Domicile",
    about: "À propos de notre fonds",
    news: "Nouvelles",
    nft: "NFT Galerie",
    contact: "Contacts",
  },

  copy: {
    copy: "Copie",
    success: "Copié",
  },

  home: {
    banner: {
      title: {
        first: "ORGANISATION CARITATIVE",
        second: '"QUI, SI CE N\'EST PAS NOUS"',
      },
      desc: "Notre fonds « QUI, SI PAS NOUS » est entièrement responsable de l'utilisation des fonds collectés, chaque contribution aura un but direct. L'essentiel pour nous est votre confiance !",
      btnHelp: "Comment puis-je vous aider",
      btnRequ: "Demander de l'aide",
      btnDonat: "Sacrifice",
    },

    company: {
      title: "Fondation caritative Who If Not Us",
      desc: "Les enfants sont notre avenir. Ensemble, nous pouvons tout surmonter et rendre notre avenir sain, heureux et avec foi en notre pays. La fondation caritative Who If Not Us organise des collectes de fonds pour aider les enfants et leurs familles dans le besoin. Assistance aux enfants gravement malades, à savoir paiement pour le traitement et la période de réadaptation.",
      descMil: "Achat continu des munitions nécessaires aux forces armées ukrainiennes.",
      mission: {
        title: "La mission de la Fondation",
        desc: "Donnez le plus possible la joie de vivre aux enfants et apportez un soutien psychologique aux parents qui en ont besoin maintenant.",
      },
      purpose: {
        title: "Objectif du fonds",
        desc: "Organisation d'activités pour enfants qui affectent le développement des enfants. Accompagnement psychologique des enfants et de leurs parents. Soutien financier et humanitaire aux victimes du conflit armé en Ukraine.",
      },
      military: {
        title: "Soutien militaire",
        desc: "Fourniture aux défenseurs de munitions et de voitures dans les points les plus chauds d'Ukraine.",
      },
      widget: {
        title: {
          first: "Ensemble - nous allons changer le monde !",
          second: "Commencez avec nous maintenant!",
        },
      },
      work: {
        disability: "Nous aidons les personnes handicapées",
        retirees: "Nous aidons les retraités",
        orphans: "Nous aidons les orphelins",
        difficult: "Nous aidons les enfants touchés pendant le conflit armé en Ukraine",
        military: "Mise à disposition des forces armées",
      },
    },

    fees: {
      title: "Quand il n'y a plus d'espoir, plus de force et plus d'argent - nous pouvons être là pour vous !",
      feesContent: [
        {
          title: "650 000 UAH.",
          desc: "collectées pour aider les enfants",
        },
        {
          title: "93 500 UAH.",
          desc: 'consacré au programme "Guérir dans la joie"',
        },
        {
          title: "196 800 UAH.",
          desc: 'consacré au programme "Thérapie réparatrice"',
        },
        {
          title: "342 000 UAH.",
          desc: 'consacré au programme "Sauver notre vie ensemble"',
        },
      ],
    },

    our_help: {
      title: "Notre aide",
      desc: "Le restaurant Soul fish a ouvert un quartier général de secours pendant la guerre. Ils aident en nourriture pour les Forces armées, la police et les réfugiés-déplacés « Depuis 41 jours, chacun de nous aide autant que possible. C'est toute une énergie forte et invincible, elle nous a unis en un tout. Et grâce à cela, nous avançons.",
      btn: "Aider",
    },

    donat: {
      title: "Assistance aux enfants blessés pendant la guerre sur le territoire de l'Ukraine",
      btn: "Faire un don",
      btc: {
        title: "BTC",
        modal: {
          header: "BTC",
          title: "Portefeuille pour les dons BTC",
        },
      },
      usdt: {
        title: "USDT",
        modal: {
          header: "USDT TRC-20",
          title: "Portefeuille pour les dons USDT TRC-20",
        },
      },
      visa: {
        title: "Visa/MasterCard",
      },
      p2p: {
        title: "Р/Р",
        modal: {
          header: "Compte courant",
        },
      },
    },

    help: {
      title: "Comment pouvons-nous aider?",
      desc: "Peut-être avez-vous une façon d'aider une famille en particulier. Veuillez nous écrire et nous vous répondrons.",
      form: {
        name: "Votre nom",
        phone: "+380 99 999-99-99",
        select: {
          one: "Choisissez comment vous pouvez aider",
          two: "Argent",
          three: "Bénévole",
          four: "Spécialiste",
          five: "Notre aide",
        },
        mass: "Votre message",
        error: "Message non envoyé ! Veuillez remplir tout les champs.",
        success: "Message envoyé avec succès!",
        btn: "Envoyer un message",
      },
      specialityContent: [
        {
          title: "En tant que bénévole de la fondation",
          desc: "Vous devez être en mesure de participer aux projets de la fondation de manière permanente ou ponctuelle. Vous devez être âgé d'au moins 18 ans.",
        },
        {
          title: "En tant que spécialiste",
          desc: "En tant que médecin pour aider les personnes malades, en tant que psychologue ou autre spécialiste.",
        },
        {
          title: "En tant que blogueur Internet",
          desc: "Prêt à aider à la diffusion d'informations sur Internet.",
        },
        {
          title: "En tant que magasin partenaire",
          desc: "Mener des promotions liées aux choses et aux nécessités de base.",
        },
        {
          title: "Aide bénévole avec de l'argent",
          desc: "Nous acceptons les virements volontaires sur le compte carte de la caisse.",
        },
      ],
    },

    request: {
      title: "Demander de l'aide",
      desc: "Écrivez vos contacts, décrivez la situation en détail :",
      info: {
        title: "Vous pouvez obtenir des informations détaillées en contactant notre spécialiste :",
        name: "Victoria Oleksandrivna Chernyshova",
      },
    },

    transfer: {
      title: "Transfert d'argent",
      error: "Erreur",
      success: "Avec succès",
      thank: "Merci pour le soutien des enfants d'Ukraine",
    },
  },

  about: {
    info: {
      title: "Fondation caritative QUI, SI NON NOUS :",
      subtitle: "Comment tout a commencé…",
      desc: {
        one: "Le fonds caritatif a été enregistré en mai 2021, mais le véritable travail de soutien aux Ukrainiens est en cours depuis 2020.",
        two: "L'objectif principal du fonds \"Qui d'autre que nous?\" - aide aux enfants. Nos missions comprennent également l'accompagnement des personnes handicapées et des retraités. Notre fondation croit que la générosité et la générosité sont les qualités humaines les plus importantes.",
        three:
          "Nous sommes convaincus qu'en faisant le bien, nous devenons plus forts et plus heureux, améliorons les relations, créons un monde meilleur. Tout le monde devrait pouvoir partager du temps, de l'argent ou de l'énergie. C'est un moyen fiable de créer l'harmonie entre les gens.",
      },

      war: {
        title: "La guerre a changé chacun de nous",
        desc: {
          first:
            "La Fondation Who If Not Us continue de travailler 24 heures sur 24 et 7 jours sur 7 pour aider toute personne dans le besoin.",
          second: {
            main: "Les vêtements, la nourriture, les produits d'hygiène personnelle nous ont tous été envoyés de Pologne. Nous encourageons tout le monde à se joindre à notre combat contre notre ennemi commun et à rejoindre l'équipe ! Merci à tous ceux qui ont déjà apporté une contribution significative ! Ensemble, nous sommes capables de créer des choses incroyables ! Ensemble, nous marchons vers la victoire ! Gloire à l'Ukraine!",
            sub: "La victoire sera à nous !",
          },
        },
      },
    },

    what: {
      title: "Ce que nous faisons",
      item: {
        one: "Nous aidons les personnes handicapées",
        two: "Nous aidons les orphelins",
        three: "Nous aidons les retraités",
        four: "Nous aidons les enfants touchés pendant le conflit armé en Ukraine",
      },
    },

    history: {
      title: "Notre histoire",
      desc: {
        fond: "La fondation caritative WHO IF NOT WE a été créée dans le seul but de faire du bien aux gens. Nous essayons de travailler dans différentes directions :",
        believ:
          "Notre fondation croit que la gentillesse et la générosité sont les principales qualités humaines qui nous séparent des animaux.",
        generate:
          "La fondation caritative WHO IF NOT a été créée pour rassembler des personnes prêtes à aider. Nous essayons de faire en sorte que chacun, indépendamment de sa situation financière et de son âge, ait la possibilité d'aider les enfants. Notre organisation souhaite que chaque enfant malheureux ait une enfance heureuse et paisible.",
      },

      list: {
        money: "Soutien financier et psychologique aux personnes en situation critique;",
        therapy: "Traitement des enfants gravement malades;",
        moral: "Soutien aux enfants touchés par le conflit armé en Ukraine;",
      },
    },
  },

  news: {
    btn: "Lire la suite",
    report: "Reportage photo",
    thanks: "Lettres de remerciements",
    page: {
      latest: "Dernières nouvelles",
      help: {
        title: "Aider",
        desc: "Soyez bénévole ou aidez en tant que spécialiste. Appuie sur le bouton!",
        btn: "Sacrifice",
      },
    },
  },

  contact: {
    title: "Coordonnées",
    address: {
      title: "Adresse",
      desc: "Dnipro, rue Korolenka, 8 ans, bureau 10",
    },
    mail: {
      title: "Poster",
    },
    phone: {
      title: "Téléphoner",
    },
    work: {
      title: "Heures d'ouverture",
      desc: "Lundi au vendredi : 10h00 à 17h00",
    },
  },

  nft: {
    title: "Galerie",
    btn: "Acheter",
    price: "Prix",

    block: {
      title: {
        main: "L'équipe a créé le NFT de notre fonds, l'argent de la vente va aider les Forces armées Ukrainiennes",
        sub: "et les sans-abri.",
      },
    },

    info: {
      title: {
        main: "Février 2022 à Ukraine",
        sub: "la guerre est venue.",
      },

      desc: {
        war: "La guerre déclenchée par la Russie. Tous les citoyens de notre pays se sont unis en un tout - dans un bouclier d'acier dans la lutte et la confrontation d'un pays qui a déjà brisé des millions d'Ukrainiens ordinaires.",
        team: "L'équipe \"Qui, sinon nous\", comme des milliers d'autres associations caritatives, dès le premier jour de la guerre prend toute part possible au sort et à l'assistance de notre pays et de nos concitoyens.",
        friend:
          "Chers amis et philanthropes, nous attendons avec impatience toute aide et tout soutien que votre organisation peut fournir.",
      },
      together: "Après tout, ensemble nous sommes l'Ukraine",
    },

    nftContent: [
      {
        img: "4",
        cat: "Villes héroïques d'Ukraine",
        title: "Irpen",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304559263746621540",
      },
      {
        img: "1",
        cat: "Villes héroïques d'Ukraine",
        title: "Bucha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304558164234993764",
      },
      {
        img: "7",
        cat: "Villes héroïques d'Ukraine",
        title: "Okhtirka",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304557064723365988",
      },
      {
        img: "10",
        cat: "Villes héroïques d'Ukraine",
        title: "Chernihiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304553766188482660",
      },
      {
        img: "6",
        cat: "Villes héroïques d'Ukraine",
        title: "Mykolayiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304552666676854884",
      },
      {
        img: "2",
        cat: "Villes héroïques d'Ukraine",
        title: "Volnovakha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304551567165227108",
      },
      {
        img: "9",
        cat: "Villes héroïques d'Ukraine",
        title: "Kherson",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304550467653599332",
      },
      {
        img: "3",
        cat: "Villes héroïques d'Ukraine",
        title: "Gostomel",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304548268630343780",
      },
      {
        img: "8",
        cat: "Villes héroïques d'Ukraine",
        title: "Kharkiv",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304547169118716004",
      },
      {
        img: "5",
        cat: "Villes héroïques d'Ukraine",
        title: "Mariupol",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304546069607088228",
      },
    ],
  },

  kindgarden: {
    banner: {
      subtitle: "Notre centre pour enfants",
      title: "\"Qui, sinon nous\" , crée un conte de fées incroyablement créatif pour les enfants sous forme de master classes",
      btn: "Plus de détails",
    },

    donat: {
      title: "Nous vous serons reconnaissants de votre aide",

      switch: {
        one: "Une fois",
        monthly: "Mensuel",
      },

      other: "Un autre montant",
      btn: "Sacrifice",
      btnCrypto: "Faire un don en crypto-monnaie",

      modal: {
        title: "Crypto-monnaie",
        btc: "Portefeuille de dons BTC",
        usdt: "Portefeuille de dons USDT TRC-20",
      },
    },

    statistic: {
      title: "Chaque mois, nous passons",
      list: {
        one: "Classes de maître",
        two: "Classes de préparation à l'école",
        three: "Cours de peinture",
        four: "Journées caritatives mensuelles",
      },

      items: {
        one: "Nous apportons constamment de l'aide et soutenons les enfants qui en ont besoin.",
        two: "Nous coopérons avec des partenaires vedettes qui soutiennent nos activités.",
        three: "Nous organisons des événements sociaux pour soutenir chaque enfant qui a souffert de la guerre.",
      },

      desc: {
        one: "Chaque mois, nous recevons la visite d'environ",
        two: "enfants heureux qui attendent avec impatience la prochaine rencontre avec nous !",
      }
    },

    direc: {
      title: "Nos itinéraires",
      btnMore: "Plus de détails",
      btnBook: "Réserver une place",

      brain: {
        title: {
          sub: "Programme",
          main: "«Forme cérébrale»",
        },
        age: "De 3 à 4 ans",
        time: "Cours 2 fois par semaine, durée – 55 minutes.",
        desc: {
          one: "Que recevra l'enfant après les cours?",
          two: "Pourquoi tu devrais nous choisir?",
        },
        listFirst: [
          {
            item: "Améliorer la langue, apprendre de nouveaux mots sur des sujets, selon l'âge;",
          },
          {
            item: "Apprendra à compter et apprendra les lettres;",
          },
          {
            item: "S'adapte à l'équipe, s'habitue à être en compagnie d'enfants;",
          },
          {
            item: "Enseigner la discipline et l'ordre ;",
          },
        ],
        listSecond: [
          {
            item: "Vous n'avez pas besoin d'emmener votre enfant à des cours supplémentaires - les lettres, les chiffres, le dessin, les instruments de musique sont inclus dans la montre;",
          },
          {
            item: "Les enseignants créatifs préparent des tâches spéciales et des contes de fées pour travailler avec des lettres, grâce auxquelles les enfants s'en souviennent facilement, et tout le processus d'apprentissage de la lecture se déroule dans un jeu et sans difficultés;",
          },
          {
            item: "Plus de 500 jouets éducatifs utiles;",
          },
          {
            item: "Plus de 10 tâches en une seule leçon ! Les enfants ne s'ennuient pas, mais sont constamment occupés;",
          },
          {
            item: "Vous ne manquez pas un seul cours avec l'abonnement, nous apprécions votre argent et en l'absence de l'enfant, le cours manqué sera réglé avec le professeur ;",
          },
        ],
      },

      therapist: {
        title: {
          sub: "Programme",
          main: "«Orthophoniste»",
        },
        time: "Durée – 60 minutes.",
        desc: {
          one: "Dans le centre pour enfants, des cours d'orthophonie individuels sont organisés, au cours desquels l'orthophoniste définit et fixe les sons, les introduit dans le langage actif de l'enfant. Les enfants maîtrisent la gymnastique d'articulation, consolident les compétences acquises.",
          two: "Le travail d'un orthophoniste avec un enfant dépend de ses caractéristiques linguistiques et s'effectue selon un plan individuel. La durée du cours correctif dépend également de la complexité de la situation.",
          three: "Il est très important de remarquer et de corriger à temps les troubles du développement du langage tels que :",
        },
        listFirst: [
          {
            item: "Discours peu clair et peu clair;",
          },
          {
            item: "Troubles de la parole;",
          },
          {
            item: "Vocabulaire pauvre;",
          },
          {
            item: "Prononciation déformée de mots complexes;",
          },
          {
            item: "L'enfant ajoute ou omet des syllabes;",
          },
          {
            item: "Difficultés à traduire le texte lu;",
          },
          {
            item: "Difficultés à mémoriser des poèmes;",
          },
          {
            item: "Agrammatismes stables;",
          },
          {
            item: "Violation de l'accord des mots en genre, nombre et cas;",
          },
          {
            item: "Audition phonémique non formée;",
          },
          {
            item: "À l'avenir, l'enfant aura de gros problèmes d'alphabétisation;",
          },
        ],
      },

      school: {
        title: {
          sub: "Programme",
          main: "«Préparation à l'école»",
        },
        age: "De 4 à 6 ans",
        time: "Cours 2 ou 3 fois par semaine, durée - 55 minutes.",
        desc: {
          one: "Les cours collectifs permettront de traverser facilement une période d'adaptation difficile. L'enfant recevra la préparation nécessaire pour un apprentissage réussi, se sentira calme et confiant dans les cours.",
          two: "Les compétences acquises en communication vous aideront à trouver facilement de nouveaux amis.",
          three: "Pour les enfants à partir de 5 ans, la classe comprend trois blocs :",
          four: "Dans de nombreuses écoles, des tests d'admission des élèves de première année à l'école ont été introduits. Pendant nos cours, les enfants étudient toute la liste des questions posées lors d'un entretien à un futur élève de première année.",
        },
        list: {
          one: "Lecture - écriture;",
          two: "Mathématiques - Logique;",
          three: "Développement de la parole ;",
        },
      },

      drawing: {
        title: {
          sub: "Programme",
          main: "«La créativité n'a pas de limites»",
        },
        age: "Plus de 200 classes de maître ont été organisées",
        time: "Plus de 700 enfants visités",
        desc: {
          one: "Nous organisons des cours de maître culinaires sur tous les types de savon de notre propre production et créons des bonbons.",
          two: "À la fin de chaque événement, vous emportez votre travail avec vous - un pain d'épice ou une guimauve faits à la main, du savon ou des créations sucrées de vos propres mains. Vos chefs-d'œuvre deviendront d'agréables cadeaux pour parents et amis, ou décoreront les étagères de votre propre maison. Peut-être que quelqu'un découvrira de nouvelles opportunités et fera de la créativité son métier.",
        },
      },

      vocal: {
        title: {
          sub: "Programme",
          main: "«Cours de chant»",
        },
        time: "Dimanche à 11:00",
        desc: {
          one: "Nous avons des enseignants expérimentés qui savent révéler le potentiel créatif et artistique de chaque enfant. Après tout, tous les enfants sont individuels.",
          two: "Nous avons développé des programmes de formation uniques. Dans les cours de chant, nous apprenons aux enfants à respirer correctement en chantant, à contrôler leur voix afin de produire les sons nécessaires. Nous élargissons la gamme de la voix, renforçons les cordes vocales, ce qui est important pour les enfants. Après tout, leur appareil vocal est différent de celui d'un adulte, il est exposé à de lourdes charges et, dans certains cas, ils peuvent se plaindre de douleurs, d'irritations de la gorge et d'enrouement.",
          three: "Aux cours de chant pour enfants, nous initions les enfants à différents styles musicaux, leur inculquons le goût, leur apprenons à ressentir des chansons, à exprimer leurs émotions. Nous travaillons sur les barrières, la timidité, apprenons à chanter, sélectionnons du matériel musical (pop, jazz, académique), aiguisons l'habileté de l'interprétation vocale, scénarisons des concerts. Un enfant peut s'essayer en tant que chanteur, ainsi que membre d'un ensemble.",
          four: "Nous vous invitons à visiter nos cours de chant individuels pour enfants. Nous allons aider à révéler les talents de chaque enfant et à les rendre un peu plus heureux ! Le prix des cours est indiqué sur le site internet.",
        },
      },

      art: {
        title: {
          sub: "Programme",
          main: "«Studio d'art»",
        },
        age: "De 3 à 16 ans",
        time: "Durée – 60 minutes.",
        desc: {
          one: "Le réfrigérateur est-il entièrement recouvert de dessins d'enfants ? Nous savons comment les transformer en chefs-d'œuvre! Nous attendons déjà le bébé dans notre atelier de peinture pour enfants, où la créativité vit dans tous les coins ! Qui a dit que le dessin académique était difficile ? Notre artiste-enseignante est amoureuse de son travail et est convaincue que tout le monde peut dessiner. Elle apprendra aux enfants à donner vie à leurs fantasmes les plus fous sur un chevalet et les initiera à diverses techniques de peinture.",
          two: "La peinture pour enfants est conçue pour différents groupes d'âge - de 3 ans à 16 ans. Chaque groupe a son propre programme éducatif. En moyenne, la durée de la leçon est de 60 minutes. Pendant ce temps, les enfants ont le temps de s'immerger complètement dans le processus et de ne pas se désintéresser (cela est particulièrement vrai pour les petits enfants de maternelle actifs).",
          three: "Nous vous attendons dans notre centre !",
        },
      },

      modal: {
        title: "Réserver une place",
        form: {
          name: "Votre nom",
          phone: "+380 99 999-99-99",
          error: "Message non envoyé ! S'il vous plaît, veuillez remplir tous les champs.",
          success: "Message envoyé avec succès!",
          btn: "Envoyer un message",
        },
      },
    },
  },

  footer: {
    desc: {
      first: "Soyez bénévole ou aidez en tant que spécialiste.",
      second: "Appuie sur le bouton!",
    },
    btn: "Je veux être utile",
    list: {
      title: "Liens utiles",
      about: "À propos de notre fonds",
      news: "Nouvelles",
      contact: "Contacts",
    },
    info: {
      title: "Informations Complémentaires",
      one: "USRÉOU 44341172",
      two: "UA763003460000026004098334201",
      three: 'dans la JSC "ALPHA-BANK"',
      four: "IMF 300346",
      five: "USRÉOU 23494714",
    },
  },
};
