import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClinicMedical, faWheelchair, faChild, faBlind, faUserInjured } from "@fortawesome/free-solid-svg-icons";

const WhatDo = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "about.what.";

  return (
    <div className="about">
      <Container>
        <Row>
          <Col lg={12}>
            <h2 className="title">
              <FontAwesomeIcon icon={faClinicMedical} />
              {t(basePath + "title")}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col lg={3} md={6}>
            <div className="item-work">
              <FontAwesomeIcon icon={faWheelchair} />
              <h5>{t(basePath + "item.one")}</h5>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="item-work">
              <FontAwesomeIcon icon={faChild} />
              <h5>{t(basePath + "item.two")}</h5>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="item-work">
              <FontAwesomeIcon icon={faBlind} />
              <h5>{t(basePath + "item.three")}</h5>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="item-work">
              <FontAwesomeIcon icon={faUserInjured} />
              <h5>{t(basePath + "item.four")}</h5>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WhatDo;
