import React from "react";
import Banner from "../components/Kindergarten/Banner";
import Statistic from "../components/Kindergarten/Statistic";
import Donat from "../components/Kindergarten/Donat";
import Directions from "../components/Kindergarten/Directions";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

function Kindergarten() {
  return (
    <div className="kind">
      <Banner />
      <Statistic />
      <Donat />
      <Directions />
      <Contact />
      <Footer />
    </div>
  );
}

export default Kindergarten;
