export const TRANSLATIONS_PL = {
  navBar: {
    site: "Nasza strona internetowa",
    btnHelp: "JAK MOŻESZ POMÓC?",
    btnRequ: "SZUKAJ POMOCY",

    link: {
      home: "Dom",
      center: "Centrum dla dzieci",
      about: "O naszym funduszu",
      news: "Aktualności",
      nft: "Galeria NFT",
      contact: "Łączność",
    },

    lang: {
      title: "Język",
      "en-US": "język angielski",
      "ua-UA": "ukraiński",
      "es-ES": "hiszpański",
      "fr-FR": "Francuski",
      "de-DE": "Niemiecki",
      "it-IT": "Włoski",
      "pl-PL": "Polski",
      "pt-PT": "portugalski",
    },
  },

  pageBanner: {
    home: "Dom",
    about: "O naszym funduszu",
    news: "Aktualności",
    nft: "Galeria NFT",
    contact: "Łączność",
  },

  copy: {
    copy: "Kopiuj",
    success: "Skopiowane",
  },

  home: {
    banner: {
      title: {
        first: "FUNDACJA CHARYTATYWNA",
        second: "„KTO, JEŚLI NIE MY”",
      },
      desc: "Nasz fundusz „KTO, JEŚLI NIE MY” jest w pełni odpowiedzialny za wykorzystanie zebranych środków, każda wpłata będzie miała bezpośredni cel. Najważniejsze dla nas jest Twoje zaufanie!",
      btnHelp: "Jak możesz pomóc",
      btnRequ: "Zapytaj o pomoc",
      btnDonat: "Poświęcenie",
    },

    company: {
      title: "Fundacja Charytatywna Kto Jeśli Nie My",
      desc: "Dzieci to nasza przyszłość. Razem możemy przezwyciężyć wszystko i sprawić, by nasza przyszłość była zdrowa, szczęśliwa iz wiarą w nasz kraj. Fundacja Charytatywna „Kto jeśli nie my” organizuje zbiórki pieniędzy na pomoc potrzebującym dzieciom i ich rodzinom. Pomoc ciężko chorym dzieciom, czyli opłata za leczenie i okres rehabilitacji.",
      descMil: "Ciągłe pozyskiwanie niezbędnej amunicji dla Sił Zbrojnych Ukrainy.",
      mission: {
        title: "Misja Fundacji",
        desc: "Daj jak najwięcej radości życia dzieciom i zapewnij wsparcie psychologiczne rodzicom, którzy teraz tego potrzebują.",
      },
      purpose: {
        title: "Cel funduszu",
        desc: "Organizacja zajęć dla dzieci wpływających na rozwój dzieci. Wsparcie psychologiczne dla dzieci i ich rodziców. Wsparcie finansowe i humanitarne dla ofiar konfliktu zbrojnego na Ukrainie.",
      },
      military: {
        title: "Wsparcie wojskowe",
        desc: "Zaopatrzenie obrońców w amunicję i samochody w najgorętszych punktach Ukrainy.",
      },
      widget: {
        title: {
          first: "Razem - zmienimy świat!",
          second: "Zacznij z nami już teraz!",
        },
      },
      work: {
        disability: "Pomagamy osobom niepełnosprawnym",
        retirees: "Pomagamy emerytom",
        orphans: "Pomagamy sierotom",
        difficult: "Pomagamy dzieciom poszkodowanym podczas konfliktu zbrojnego na Ukrainie",
        military: "Zaopatrzenie Sił Zbrojnych",
      },
    },

    fees: {
      title: "Kiedy nie ma nadziei, siły ani pieniędzy - możemy być tam dla Ciebie!",
      feesContent: [
        {
          title: "650 000 UAH.",
          desc: "zbierane na pomoc dzieciom",
        },
        {
          title: "93 500 UAH.",
          desc: 'wydatki na program "Uzdrawianie z radością"',
        },
        {
          title: "196 800 UAH.",
          desc: 'spędzonych na programie "Terapia regeneracyjna"',
        },
        {
          title: "342 000 UAH.",
          desc: 'wydane na program "Razem ratujemy życie"',
        },
      ],
    },

    our_help: {
      title: "Nasza pomoc",
      desc: "Restauracja rybna Soul otworzyła podczas wojny kwaterę główną pomocy humanitarnej. Pomagają z żywnością dla Sił Zbrojnych, policji i przesiedleńców „Od 41 dni każdy z nas pomaga, ile tylko może. To wszystko jest mocną i niezwyciężoną energią, połączyła nas w jedną całość. I dzięki temu ruszamy.",
      btn: "Pomoc",
    },

    donat: {
      title: "Pomoc dzieciom poszkodowanym w czasie wojny na terytorium Ukrainy",
      btn: "Przekaż darowiznę",
      btc: {
        title: "BTC",
        modal: {
          header: "BTC",
          title: "Portfel na darowizny BTC",
        },
      },
      usdt: {
        title: "USDT",
        modal: {
          header: "USDT TRC-20",
          title: "Portfel na darowizny USDT TRC-20",
        },
      },
      visa: {
        title: "Visa/MasterCard",
      },
      p2p: {
        title: "Р/Р",
        modal: {
          header: "Rachunek bieżący",
        },
      },
    },

    help: {
      title: "Jak możemy pomóc?",
      desc: "Być może masz sposób, aby pomóc konkretnej rodzinie. Napisz do nas, a my skontaktujemy się z Tobą.",
      form: {
        name: "Twoje imię",
        phone: "+380 99 999-99-99",
        select: {
          one: "Wybierz, jak możesz pomóc",
          two: "Pieniądze",
          three: "Wolontariusz",
          four: "Specjalista",
          five: "Nasza pomoc",
        },
        mass: "Twoja wiadomość",
        error: "Wiadomość nie została wysłana! Proszę wypełnić wszystkie pola.",
        success: "Wiadomość wysłano poprawnie!",
        btn: "Wyślij wiadomość",
      },
      specialityContent: [
        {
          title: "Jako wolontariusz fundacji",
          desc: "Musisz być w stanie brać udział w projektach fundacji na stałe lub jednorazowo. Uczestnik musi mieć ukończone 18 lat.",
        },
        {
          title: "Jako specjalista",
          desc: "Jako lekarz pomagający chorym ludziom, jako psycholog lub inny specjalista.",
        },
        {
          title: "Jako bloger internetowy",
          desc: "Gotowy do pomocy w rozpowszechnianiu informacji w Internecie.",
        },
        {
          title: "Jako sklep partnerski",
          desc: "Prowadzenie promocji związanych z rzeczami i podstawowymi potrzebami.",
        },
        {
          title: "Dobrowolna pomoc pieniężna",
          desc: "Akceptujemy dobrowolne przelewy na rachunek karty Fundacji.",
        },
      ],
    },

    request: {
      title: "Zapytaj o pomoc",
      desc: "Napisz swoje kontakty, opisz szczegółowo sytuację:",
      info: {
        title: "Szczegółowe informacje uzyskasz kontaktując się z naszym specjalistą:",
        name: "Wiktoria Oleksandrivna Czernyszowa",
      },
    },

    transfer: {
      title: "Transfer pieniędzy",
      error: "Błąd",
      success: "Z powodzeniem",
      thank: "Dziękuję za wsparcie dzieci Ukrainy",
    },
  },

  about: {
    info: {
      title: "KTO, JEŚLI NIE MY Fundacja Charytatywna:",
      subtitle: "Jak to się wszystko zaczęło…",
      desc: {
        one: "Fundusz charytatywny został zarejestrowany w maju 2021 r., ale faktyczna praca na rzecz wsparcia Ukraińców trwa od 2020 r.",
        two: "Główny cel funduszu „Kto oprócz nas?” - pomoc dla dzieci. Do naszych zadań należy również wsparcie osób niepełnosprawnych i emerytów. Nasza fundacja wierzy, że hojność i hojność to najważniejsze cechy człowieka.",
        three:
          "Jesteśmy przekonani, że czyniąc dobro stajemy się silniejsi i szczęśliwsi, poprawiamy relacje, tworzymy lepszy świat. Każdy powinien mieć możliwość dzielenia się czasem, pieniędzmi lub energią. To niezawodny sposób na stworzenie harmonii między ludźmi.",
      },

      war: {
        title: "Wojna zmieniła każdego z nas",
        desc: {
          first:
            "Fundacja Who If Not Us nieprzerwanie pracuje 24 godziny na dobę, 7 dni w tygodniu, pomagając każdemu, kto jest w potrzebie.",
          second: {
            main: "Z Polski przysłano do nas odzież, żywność, środki higieny osobistej. Zachęcamy wszystkich do przyłączenia się do naszej walki z naszym wspólnym wrogiem i dołączenia do drużyny! Dziękuję wszystkim, którzy już wnieśli znaczący wkład! Razem jesteśmy w stanie tworzyć niesamowite rzeczy! Razem idziemy do zwycięstwa! Chwała Ukrainie!",
            sub: "Zwycięstwo będzie nasze!",
          },
        },
      },
    },

    what: {
      title: "Co robimy",
      item: {
        one: "Pomagamy osobom niepełnosprawnym",
        two: "Pomagamy sierotom",
        three: "Pomagamy emerytom",
        four: "Pomagamy dzieciom poszkodowanym podczas konfliktu zbrojnego na Ukrainie",
      },
    },

    history: {
      title: "Nasza historia",
      desc: {
        fond: "Fundacja Charytatywna „KTO JEŻELI NIE MY” powstała wyłącznie w celu czynienia dobra ludziom. Staramy się działać w różnych kierunkach:",
        believ: "Nasza fundacja wierzy, że życzliwość i hojność to główne cechy ludzkie, które oddzielają nas od zwierząt.",
        generate:
          "Fundacja Charytatywna WHO IF NOT powstała, aby zrzeszać ludzi chętnych do pomocy. Staramy się, aby każdy, bez względu na stan majątkowy i wiek, miał możliwość pomocy dzieciom. Nasza organizacja pragnie, aby każde nieszczęśliwe dziecko miało szczęśliwe i spokojne dzieciństwo.",
      },

      list: {
        money: "Wsparcie finansowe i psychologiczne dla osób w sytuacjach krytycznych;",
        therapy: "Leczenie ciężko chorych dzieci;",
        moral: "Wsparcie dla dzieci dotkniętych konfliktem zbrojnym na Ukrainie;",
      },
    },
  },

  news: {
    btn: "Czytaj więcej",
    report: "Fotoreportaż",
    thanks: "Listy z podziękowaniami",
    page: {
      latest: "Najnowsze wiadomości",
      help: {
        title: "Pomoc",
        desc: "Bądź wolontariuszem lub pomóż jako specjalista. Naciśnij przycisk!",
        btn: "Poświęcenie",
      },
    },
  },

  nft: {
    title: "Galeria",
    btn: "Kupić",
    price: "Cena £",

    block: {
      title: {
        main: "Zespół stworzył NFT naszego funduszu, pieniądze ze sprzedaży trafiają na pomoc Siłom Zbrojnym Ukrainy",
        sub: "i bezdomni.",
      },
    },

    info: {
      title: {
        main: "Luty 2022 na Ukrainę",
        sub: "nadeszła wojna.",
      },

      desc: {
        war: "Wojna rozpoczęta przez Rosję. Wszyscy obywatele naszego kraju są zjednoczeni w jednej całości – w stalowej tarczy w walce i konfrontacji kraju, który rozbił już miliony zwykłych Ukraińców.",
        team: "Zespół „Kto, jeśli nie my”, podobnie jak tysiące innych organizacji charytatywnych, od pierwszego dnia wojny bierze udział w losach i pomocy naszemu krajowi i naszym obywatelom.",
        friend: "Drodzy przyjaciele i filantropi, czekamy na wszelką pomoc i wsparcie, jakie może zapewnić Wasza organizacja.",
      },
      together: "W końcu razem jesteśmy Ukrainą",
    },

    nftContent: [
      {
        img: "4",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Irpen",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304559263746621540",
      },
      {
        img: "1",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Bucha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304558164234993764",
      },
      {
        img: "7",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Okhtirka",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304557064723365988",
      },
      {
        img: "10",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Chernihiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304553766188482660",
      },
      {
        img: "6",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Mykolayiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304552666676854884",
      },
      {
        img: "2",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Volnovakha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304551567165227108",
      },
      {
        img: "9",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Kherson",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304550467653599332",
      },
      {
        img: "3",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Gostomel",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304548268630343780",
      },
      {
        img: "8",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Kharkiv",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304547169118716004",
      },
      {
        img: "5",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Mariupol",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304546069607088228",
      },
    ],
  },

  contact: {
    title: "Informacje kontaktowe",
    address: {
      title: "Adres",
      desc: "Dniepr, ulica Korolenka, 8, biuro 10",
    },
    mail: {
      title: "Poczta",
    },
    phone: {
      title: "Telefon",
    },
    work: {
      title: "Godziny pracy",
      desc: "Od poniedziałku do piątku: 10:00 do 17:00",
    },
  },

  kindgarden: {
    banner: {
      subtitle: "Nasze centrum dla dzieci",
      title: "\"Kto jak nie my\", tworzy niezwykle kreatywną bajkę dla dzieci w formie kursów mistrzowskich",
      btn: "Więcej szczegółów",
    },

    donat: {
      title: "Będziemy wdzięczni za pomoc",

      switch: {
        one: "Jeden raz",
        monthly: "Miesięczny",
      },

      other: "Kolejna kwota",
      btn: "Poświęcenie",
      btnCrypto: "Przekaż darowiznę w kryptowalucie",

      modal: {
        title: "Kryptowaluty",
        btc: "Portfel darowizn BTC",
        usdt: "Portfel darowizn USDT TRC-20",
      },
    },

    statistic: {
      title: "Co miesiąc wydajemy",
      list: {
        one: "Kursy mistrzowskie",
        two: "Zajęcia przygotowujące do szkoły",
        three: "Lekcje malarstwa",
        four: "Comiesięczne dni akcji charytatywnej",
      },

      items: {
        one: "Nieustannie niesiemy pomoc i wspieramy dzieci, które jej potrzebują.",
        two: "Współpracujemy z gwiazdorskimi partnerami, którzy wspierają nasze działania.",
        three: "Organizujemy wydarzenia społeczne, aby wesprzeć każde dziecko, które ucierpiało w wyniku wojny.",
      },

      desc: {
        one: "Co miesiąc odwiedza nas około",
        two: "szczęśliwych dzieci, które nie mogą się doczekać kolejnego spotkania z nami!",
      }
    },

    direc: {
      title: "Nasze kierunki",
      btnMore: "Więcej szczegółów",
      btnBook: "Zarezerwuj miejsce",

      brain: {
        title: {
          sub: "Program",
          main: "«Sprawność mózgu»",
        },
        age: "Od 3 do 4 lat",
        time: "Zajęcia 2 razy w tygodniu, czas trwania – 55 minut.",
        desc: {
          one: "Co dziecko otrzyma po zajęciach?",
          two: "Dlaczego warto wybrać nas?",
        },
        listFirst: [
          {
            item: "Popraw język, ucz się nowych słów na tematy, w zależności od wieku;",
          },
          {
            item: "Nauczy się liczyć i nauczy się liter;",
          },
          {
            item: "Dostosowuje się do zespołu, przyzwyczaja się do przebywania w towarzystwie dzieci;",
          },
          {
            item: "Naucz dyscypliny i porządku;",
          },
        ],
        listSecond: [
          {
            item: "Nie musisz zabierać dziecka na dodatkowe zajęcia - w zegarku zawarte są litery, cyferki, rysunek, gra na instrumentach muzycznych;",
          },
          {
            item: "Kreatywni nauczyciele przygotowują specjalne zadania i bajki do pracy z literami, dzięki czemu dzieci łatwo je zapamiętują, a cały proces nauki czytania odbywa się w zabawie i bez trudności;",
          },
          {
            item: "Ponad 500 przydatnych zabawek edukacyjnych;",
          },
          {
            item: "Ponad 10 zadań na jednej lekcji! Dzieci nie są znudzone, ale ciągle zajęte;",
          },
          {
            item: "Z abonamentem nie przegapisz ani jednej lekcji, doceniamy Twoje pieniądze, aw przypadku nieobecności dziecka opuszczona lekcja zostanie dopracowana z nauczycielem;",
          },
        ],
      },

      therapist: {
        title: {
          sub: "Program",
          main: "«Logopeda»",
        },
        time: "Czas trwania – 60 minut.",
        desc: {
          one: "W ośrodku dziecięcym odbywają się indywidualne zajęcia logopedyczne, podczas których logopeda ustawia i utrwala dźwięki, wprowadza je w język aktywny dziecka. Dzieci opanowują gimnastykę artykulacyjną, utrwalają nabyte umiejętności.",
          two: "Praca logopedy z dzieckiem uzależniona jest od jego charakterystyki językowej i prowadzona jest według indywidualnego planu. Czas trwania kursu korekcyjnego zależy również od złożoności sytuacji.",
          three: "Bardzo ważne jest, aby z czasem zauważyć i korygować takie zaburzenia w rozwoju języka, jak:",
        },
        listFirst: [
          {
            item: "Niejasna, niewyraźna mowa;",
          },
          {
            item: "Wady mowy;",
          },
          {
            item: "Słabe słownictwo;",
          },
          {
            item: "Zniekształcona wymowa złożonych słów;",
          },
          {
            item: "Dziecko dodaje lub pomija sylaby;",
          },
          {
            item: "Trudności w tłumaczeniu czytanego tekstu;",
          },
          {
            item: "Trudności w zapamiętywaniu wierszy;",
          },
          {
            item: "Agramatyzmy stabilne;",
          },
          {
            item: "Naruszenie zgodności słów w rodzaju, liczbie i przypadku;",
          },
          {
            item: "Nieformowany słuch fonemiczny;",
          },
          {
            item: "W przyszłości dziecko ma duże problemy z umiejętnością czytania i pisania;",
          },
        ],
      },

      school: {
        title: {
          sub: "Program",
          main: "«Przygotowanie do szkoły»",
        },
        age: "Od 4 do 6 lat",
        time: "Zajęcia 2 lub 3 razy w tygodniu, czas trwania - 55 minut.",
        desc: {
          one: "Zajęcia grupowe ułatwią przejście przez trudny okres adaptacji. Dziecko otrzyma niezbędne przygotowanie do skutecznej nauki, będzie czuło się spokojnie i pewnie na zajęciach.",
          two: "Nabyte umiejętności komunikacyjne pomogą Ci łatwo znaleźć nowych przyjaciół.",
          three: "Dla dzieci od 5 roku życia zajęcia obejmują trzy bloki:",
          four: "W wielu szkołach wprowadzono testowanie pierwszoklasistów przed przyjęciem do szkoły. Podczas naszych zajęć dzieci zapoznają się z całą listą pytań zadawanych podczas rozmowy kwalifikacyjnej z przyszłym pierwszoklasistą.",
        },
        list: {
          one: "Czytanie - Pisanie;",
          two: "Matematyka - Logika;",
          three: "Rozwój mowy;",
        },
      },

      drawing: {
        title: {
          sub: "Program",
          main: "«Kreatywność nie ma granic»",
        },
        age: "Odbyło się ponad 200 kursów mistrzowskich",
        time: "Odwiedziło ponad 700 dzieci",
        desc: {
          one: "Prowadzimy mistrzowskie kursy kulinarne na wszystkich rodzajach mydeł własnej produkcji i tworzymy cukierki.",
          two: "Na zakończenie każdej imprezy zabierasz ze sobą swoją pracę - własnoręcznie własnoręcznie wykonany piernik lub ptasie mleczko, mydło czy słodkie kreacje. Twoje arcydzieła staną się przyjemnymi prezentami dla krewnych i przyjaciół lub ozdobią półki we własnym domu. Może ktoś odkryje nowe możliwości i sprawi, że kreatywność stanie się ich pracą.",
        },
      },

      vocal: {
        title: {
          sub: "Program",
          main: "«Lekcje śpiewu»",
        },
        time: "Niedziela o 11:00",
        desc: {
          one: "Mamy doświadczonych nauczycieli, którzy wiedzą, jak odkryć potencjał twórczy i artyzm każdego dziecka. W końcu wszystkie dzieci są indywidualne.",
          two: "Opracowaliśmy unikalne programy szkoleniowe. Na zajęciach wokalnych uczymy dzieci prawidłowego oddychania podczas śpiewania, panowania nad głosem w celu wydobycia niezbędnych dźwięków. Poszerzamy zakres głosu, wzmacniamy struny głosowe, co jest ważne dla dzieci. W końcu ich aparat głosowy różni się od dorosłego, jest narażony na duże obciążenia, aw niektórych przypadkach mogą narzekać na ból, podrażnienie gardła i chrypkę.",
          three: "Na lekcjach śpiewu dla dzieci zapoznajemy dzieci z różnymi stylami muzycznymi, zaszczepiamy gust, uczymy czuć piosenki, wyrażać emocje. Pracujemy nad barierami, nieśmiałością, uczymy się śpiewać, dobieramy materiał muzyczny (pop, jazz, akademicki), doskonalimy warsztat wokalny, koncerty estradowe. Dziecko może sprawdzić się zarówno jako wokalista, jak i członek zespołu.",
          four: "Zapraszamy na indywidualne lekcje śpiewu dla dzieci. Pomożemy odkryć talenty każdego dziecka i sprawimy, że będą trochę szczęśliwsze! Koszt zajęć wskazany jest na stronie internetowej.",
        },
      },

      art: {
        title: {
          sub: "Program",
          main: "«Studio Sztuki»",
        },
        age: "Od 3 do 16 lat",
        time: "Czas trwania – 60 minut.",
        desc: {
          one: "Czy cała lodówka jest pokryta rysunkami dzieci? Wiemy, jak zamienić je w arcydzieła! Już czekamy na maluszka w naszej pracowni malarskiej dla dzieci, gdzie kreatywność mieszka w każdym kącie! Kto powiedział, że rysunek akademicki jest trudny? Nasza nauczycielka-artystka jest zakochana w swojej pracy i jest przekonana, że ​​rysować może każdy. Nauczy dzieci, jak na sztalugach urzeczywistniać najśmielsze fantazje i zapozna je z różnymi technikami malarskimi.",
          two: "Malarstwo dla dzieci przeznaczone jest dla różnych grup wiekowych - od 3 lat do 16 lat. Każda grupa ma swój własny program edukacyjny. Średnio czas trwania lekcji wynosi 60 minut. W tym czasie dzieci mają czas, aby całkowicie zanurzyć się w procesie i nie stracić zainteresowania (dotyczy to zwłaszcza małych, aktywnych przedszkolaków).",
          three: "Czekamy na Ciebie w naszym centrum!",
        },
      },

      modal: {
        title: "Rezerwacja miejsca",
        form: {
          name: "Twoje imię",
          phone: "+380 99 999-99-99",
          error: "Wiadomość nie została wysłana! Proszę, proszę wypełnić wszystkie pola.",
          success: "Wiadomość wysłano poprawnie!",
          btn: "Wysłać wiadomość",
        },
      },
    },
  },

  footer: {
    desc: {
      first: "Bądź wolontariuszem lub pomóż jako specjalista.",
      second: "Naciśnij przycisk!",
    },
    btn: "chcę być użyteczny",
    list: {
      title: "Przydatne linki",
      about: "O naszym funduszu",
      news: "Aktualności",
      contact: "Łączność",
    },
    info: {
      title: "Dodatkowe informacje",
      one: "USREOU 44341172",
      two: "UA763003460000026004098334201",
      three: 'w UAB "ALFA-BANK"',
      four: "MIF 300346",
      five: "USREOU 23494714",
    },
  },
};
