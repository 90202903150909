export const TRANSLATIONS_PT = {
  navBar: {
    site: "O nosso sítio web",
    btnHelp: "COMO VOCÊ PODE AJUDAR",
    btnRequ: "PEDIR AJUDA",

    link: {
      home: "Casa",
      center: "Centro infantil",
      about: "Sobre nosso fundo",
      news: "Notícia",
      nft: "Galeria NFT",
      contact: "Contatos",
    },

    lang: {
      title: "Linguagem",
      "en-US": "inglês",
      "ua-UA": "ucraniano",
      "es-ES": "espanhol",
      "fr-FR": "francês",
      "de-DE": "alemão",
      "it-IT": "italiano",
      "pl-PL": "polonês",
      "pt-PT": "português",
    },
  },

  pageBanner: {
    home: "Casa",
    about: "Sobre nosso fundo",
    news: "Notícia",
    nft: "Galeria NFT",
    contact: "Contatos",
  },

  copy: {
    copy: "cópia de",
    success: "Copiado",
  },

  home: {
    banner: {
      title: {
        first: "FUNDAÇÃO DE CARIDADE",
        second: '"QUEM, SE NÃO NÓS"',
      },
      desc: 'Nosso fundo "QUEM, SE NÃO NÓS" é totalmente responsável pela utilização dos recursos arrecadados, cada contribuição terá uma finalidade direta. O principal para nós é a sua confiança!',
      btnHelp: "Como você pode ajudar",
      btnRequ: "Peça por ajuda",
      btnDonat: "Sacrifício",
    },

    company: {
      title: "Fundação Beneficente Quem Se Não Nós",
      desc: "As crianças são o nosso futuro. Juntos, podemos superar tudo e tornar nosso futuro saudável, feliz e com fé em nosso país. A Who If Not Us Charitable Foundation organiza angariação de fundos para ajudar crianças e suas famílias necessitadas. Assistência a crianças gravemente doentes, nomeadamente pagamento de tratamento e período de reabilitação.",
      descMil: "Aquisição contínua de munições necessárias para as Forças Armadas da Ucrânia.",
      mission: {
        title: "A missão da fundação",
        desc: "Dê o máximo possível a alegria da vida às crianças e forneça apoio psicológico aos pais que precisam agora.",
      },
      purpose: {
        title: "A finalidade do fundo",
        desc: "Organização de atividades infantis que afetam o desenvolvimento infantil. Apoio psicológico para crianças e seus pais. Apoio financeiro e humanitário às vítimas do conflito armado na Ucrânia.",
      },
      military: {
        title: "Apoio militar",
        desc: "Fornecendo munições e carros aos defensores nos pontos mais quentes da Ucrânia.",
      },
      widget: {
        title: {
          first: "Juntos - vamos mudar o mundo!",
          second: "Comece conosco agora!",
        },
      },
      work: {
        disability: "Ajudamos pessoas com deficiência",
        retirees: "Ajudamos aposentados",
        orphans: "Ajudamos os órfãos",
        difficult: "Ajudamos as crianças afetadas durante o conflito armado na Ucrânia",
        military: "Provisão das Forças Armadas",
      },
    },

    fees: {
      title: "Quando não há esperança, não há força e não há dinheiro - podemos estar lá para si!",
      feesContent: [
        {
          title: "650 000 UAH.",
          desc: "criado para ajudar as crianças",
        },
        {
          title: "93 500 UAH.",
          desc: "gasto no programa 'Cura com Alegria'",
        },
        {
          title: "196 800 UAH.",
          desc: "gasto no programa Salvar Vidas em Conjunto",
        },
        {
          title: "342 000 UAH.",
          desc: "gasto no programa Salvar Vidas em Conjunto",
        },
      ],
    },

    our_help: {
      title: "Nossa ajuda",
      desc: "O restaurante Soul Fish abriu um quartel-general de ajuda durante a guerra. Ajudam com alimentos para as Forças Armadas, a polícia e os deslocados refugiados “Há 41 dias, cada um de nós está ajudando como pode. Tudo isso é energia forte e invencível, nos uniu em um todo. E graças a isso estamos nos movendo.",
      btn: "Ajuda",
    },

    donat: {
      title: "Assistência a crianças feridas durante a guerra no território da Ucrânia",
      btn: "Faça o seu donativo",
      btc: {
        title: "BTC",
        modal: {
          header: "BTC",
          title: "Carteira para doações BTC",
        },
      },
      usdt: {
        title: "USDT",
        modal: {
          header: "USDT TRC-20",
          title: "Carteira para doações USDT TRC-20",
        },
      },
      visa: {
        title: "Visa/MasterCard",
      },
      p2p: {
        title: "Р/Р",
        modal: {
          header: "Conta corrente",
        },
      },
    },

    help: {
      title: "Como podemos ajudar?",
      desc: "Talvez tenha uma forma de ajudar uma determinada família. Por favor, escreva-nos e entraremos em contacto consigo.",
      form: {
        name: "Seu nome",
        phone: "+380 99 999-99-99",
        select: {
          one: "Escolha como pode ajudar",
          two: "Dinheiro",
          three: "Voluntário",
          four: "Especialista",
          five: "A nossa ajuda",
        },
        mass: "Sua mensagem",
        error: "Mensagem não enviada! Por favor preencha todos os campos.",
        success: "Mensagem enviada com sucesso!",
        btn: "Enviar mensagem",
      },
      specialityContent: [
        {
          title: "Como voluntário da fundação",
          desc: "Participar dos projetos do fundo de forma permanente ou única. Você deve ter mais de 18 anos.",
        },
        {
          title: "Como especialista",
          desc: "Como médico para ajudar pacientes, como psicólogo ou outro especialista.",
        },
        {
          title: "Como blogueiro da internet",
          desc: "Pronto para ajudar na divulgação de informações na Internet.",
        },
        {
          title: "Como loja parceira",
          desc: "Realização de promoções relacionadas a coisas e necessidades básicas.",
        },
        {
          title: "Ajuda voluntária com dinheiro",
          desc: "Aceitamos transferências voluntárias para a conta do cartão da Fundação.",
        },
      ],
    },

    request: {
      title: "Peça por ajuda",
      desc: "Escreva seus contatos, descreva a situação em detalhes:",
      info: {
        title: "Você pode obter informações detalhadas entrando em contato com nosso especialista:",
        name: "Victoria Oleksandrivna Chernyshova",
      },
    },

    transfer: {
      title: "Transferência de dinheiro",
      error: "Erro",
      success: "Com sucesso",
      thank: "Obrigado pelo apoio das crianças da Ucrânia",
    },
  },

  about: {
    info: {
      title: "QUEM, SE NÃO NÓS Fundação de Caridade",
      subtitle: "Como tudo começou…",
      desc: {
        one: "O fundo de caridade foi registrado em maio de 2021, mas o trabalho real de apoio aos ucranianos está em andamento desde 2020.",
        two: 'O principal objetivo do fundo "Quem senão nós?" - ajuda para crianças. Nossas tarefas também incluem o apoio a pessoas com deficiência e aposentados. Nossa fundação acredita que generosidade e generosidade são as qualidades humanas mais importantes.',
        three:
          "Estamos convencidos de que fazendo o bem nos tornamos mais fortes e felizes, melhoramos relacionamentos, criamos um mundo melhor. Todos devem poder compartilhar tempo, dinheiro ou energia. Esta é uma maneira confiável de criar harmonia entre as pessoas.",
      },

      war: {
        title: "A guerra mudou cada um de nós",
        desc: {
          first:
            "A Who If Not Us Foundation continua trabalhando 24 horas por dia, 7 dias por semana, para ajudar quem precisa.",
          second: {
            main: "Roupas, alimentos, produtos de higiene pessoal nos foram enviados da Polônia. Incentivamos todos a se juntarem à nossa luta contra nosso inimigo comum e se juntarem ao time! Obrigado a todos que já deram uma contribuição significativa! Juntos somos capazes de criar coisas incríveis! Juntos estamos marchando para a vitória! Glória à Ucrânia!",
            sub: "A vitória será nossa!",
          },
        },
      },
    },

    what: {
      title: "O que nós fazemos",
      item: {
        one: "Ajudamos pessoas com deficiência",
        two: "Ajudamos os órfãos",
        three: "Ajudamos aposentados",
        four: "Ajudamos as crianças afetadas durante o conflito armado na Ucrânia",
      },
    },

    history: {
      title: "Nossa história",
      desc: {
        fond: "A WHO IF NOT WE Charitable Foundation foi criada com o único propósito de fazer o bem às pessoas. Tentamos trabalhar em diferentes direções:",
        believ:
          "Nossa fundação acredita que bondade e generosidade são as principais qualidades humanas que nos separam dos animais.",
        generate:
          "A WHO IF NOT Charitable Foundation foi criada para reunir pessoas dispostas a ajudar. Tentamos garantir que todos, independentemente da situação financeira e da idade, tenham a oportunidade de ajudar as crianças. Nossa organização quer que cada criança infeliz tenha uma infância feliz e pacífica.",
      },

      list: {
        money: "Apoio financeiro e psicológico para pessoas em situações críticas;",
        therapy: "Tratamento de crianças gravemente doentes;",
        moral: "Apoio às crianças afetadas pelo conflito armado na Ucrânia;",
      },
    },
  },

  news: {
    btn: "Consulte Mais informação",
    report: "Foto reportagem",
    thanks: "Cartas de agradecimento",
    page: {
      latest: "Últimas notícias",
      help: {
        title: "Ajuda",
        desc: "Seja um voluntário ou ajude como um especialista. Aperte o botão!",
        btn: "Sacrifício",
      },
    },
  },

  nft: {
    title: "Galeria",
    btn: "Comprar",
    price: "Preço",

    block: {
      title: {
        main: "A equipe criou o NFT do nosso fundo, o dinheiro da venda vai para ajudar as Forças Armadas da Ucrânia",
        sub: "e moradores de rua.",
      },
    },

    info: {
      title: {
        main: "Fevereiro 2022 para a Ucrânia",
        sub: "a guerra veio.",
      },

      desc: {
        war: "A guerra começou pela Rússia. Todos os cidadãos do nosso país estão unidos em um todo - em um escudo de aço na luta e confronto de um país que já quebrou milhões de ucranianos comuns.",
        team: 'A equipe "Quem, se não nós", como milhares de outras instituições de caridade, desde o primeiro dia da guerra toma todas as partes possíveis no destino e assistência ao nosso país e nossos cidadãos.',
        friend: "Caros amigos e filantropos, estamos ansiosos por qualquer ajuda e apoio que sua organização possa fornecer.",
      },
      together: "Afinal, juntos somos a Ucrânia",
    },

    nftContent: [
      {
        img: "4",
        cat: "Cidades heróicas da Ucrânia",
        title: "Irpen",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304559263746621540",
      },
      {
        img: "1",
        cat: "Cidades heróicas da Ucrânia",
        title: "Bucha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304558164234993764",
      },
      {
        img: "7",
        cat: "Cidades heróicas da Ucrânia",
        title: "Okhtirka",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304557064723365988",
      },
      {
        img: "10",
        cat: "Cidades heróicas da Ucrânia",
        title: "Chernihiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304553766188482660",
      },
      {
        img: "6",
        cat: "Cidades heróicas da Ucrânia",
        title: "Mykolayiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304552666676854884",
      },
      {
        img: "2",
        cat: "Cidades heróicas da Ucrânia",
        title: "Volnovakha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304551567165227108",
      },
      {
        img: "9",
        cat: "Cidades heróicas da Ucrânia",
        title: "Kherson",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304550467653599332",
      },
      {
        img: "3",
        cat: "Cidades heróicas da Ucrânia",
        title: "Gostomel",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304548268630343780",
      },
      {
        img: "8",
        cat: "Cidades heróicas da Ucrânia",
        title: "Kharkiv",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304547169118716004",
      },
      {
        img: "5",
        cat: "Cidades heróicas da Ucrânia",
        title: "Mariupol",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304546069607088228",
      },
    ],
  },

  contact: {
    title: "Informações de contato",
    address: {
      title: "Endereço",
      desc: "Dnipro, rua Korolenka, 8, escritório 10",
    },
    mail: {
      title: "Correspondência",
    },
    phone: {
      title: "Telefone",
    },
    work: {
      title: "Jornada de trabalho",
      desc: "Segunda a Sexta: 10h00 às 17h00",
    },
  },

  kindgarden: {
    banner: {
      subtitle: "Nosso centro infantil",
      title: "\"Quem, senão nós\" crie um conto de fadas incrivelmente criativo para crianças na forma de master classes",
      btn: "Mais detalhes",
    },

    donat: {
      title: "Ficaremos gratos por sua ajuda",

      switch: {
        one: "Um tempo",
        monthly: "Por mês",
      },

      other: "Outra quantia",
      btn: "Sacrifício",
      btnCrypto: "Doe em criptomoeda",

      modal: {
        title: "Criptomoeda",
        btc: "Carteira de doação BTC",
        usdt: "Carteira de doação USDT TRC-20",
      },
    },

    statistic: {
      title: "Todos os meses nós gastamos",
      list: {
        one: "Master classes",
        two: "Aulas de preparação escolar",
        three: "Aulas de pintura",
        four: "Dias mensais de caridade",
      },

      items: {
        one: "Constantemente damos ajuda e apoiamos as crianças que precisam.",
        two: "Cooperamos com parceiros famosos que apoiam nossas atividades.",
        three: "Organizamos eventos sociais para apoiar todas as crianças que sofreram com a guerra.",
      },

      desc: {
        one: "Todos os meses recebemos a visita de cerca de",
        two: "crianças felizes que estão ansiosas pelo próximo encontro conosco!",
      }
    },

    direc: {
      title: "Nossas direções",
      btnMore: "Mais detalhes",
      btnBook: "Reserve um lugar",

      brain: {
        title: {
          sub: "Programa",
          main: "«Aptidão Cerebral»",
        },
        age: "De 3 a 4 anos",
        time: "Aulas 2 vezes por semana, duração – 55 minutos.",
        desc: {
          one: "O que a criança vai ganhar depois das aulas?",
          two: "Por que você deve escolher-nos?",
        },
        listFirst: [
          {
            item: "Aprimorar a linguagem, aprender novas palavras sobre temas, de acordo com a idade;",
          },
          {
            item: "Vai aprender a contar e aprender as letras;",
          },
          {
            item: "Adapta-se à equipa, habitua-se à companhia das crianças;",
          },
          {
            item: "Acostume-se com a disciplina e a ordem;",
          },
        ],
        listSecond: [
          {
            item: "Você não precisa levar seu filho para aulas adicionais - letras, números, desenhos, tocar instrumentos musicais estão incluídos no relógio; ",
          },
          {
            item: "Os professores criativos preparam tarefas especiais e contos de fadas para trabalhar com as letras, graças às quais as crianças se lembram delas com facilidade, e todo o processo de aprendizagem da leitura ocorre de forma lúdica e sem dificuldades;",
          },
          {
            item: "Mais de 500 brinquedos educativos úteis;",
          },
          {
            item: "Mais de 10 tarefas em uma lição! As crianças não estão entediadas, mas constantemente ocupadas;",
          },
          {
            item: "Você não perde uma única aula com a assinatura, agradecemos seu dinheiro e na ausência da criança, a aula perdida será trabalhada com o professor;",
          },
        ],
      },

      therapist: {
        title: {
          sub: "Programa",
          main: "«Terapeuta da fala»",
        },
        time: "Duração – 60 minutos.",
        desc: {
          one: "No centro infantil são realizadas aulas individuais de fonoaudiologia, durante as quais a fonoaudióloga fixa e fixa os sons, introduzindo-os na linguagem ativa da criança. As crianças dominam a ginástica de articulação, consolidam as habilidades adquiridas.",
          two: "O trabalho do fonoaudiólogo com uma criança depende de suas características de linguagem e é realizado de acordo com um plano individual. A duração do curso corretivo também depende da complexidade da situação.",
          three: "É muito importante perceber e corrigir a tempo tais distúrbios no desenvolvimento da linguagem como:",
        },
        listFirst: [
          {
            item: "Discurso pouco claro e pouco claro;",
          },
          {
            item: "Defeitos de fala;",
          },
          {
            item: "Vocabulário pobre;",
          },
          {
            item: "Pronúncia distorcida de palavras complexas;",
          },
          {
            item: "A criança acrescenta ou omite sílabas;",
          },
          {
            item: "Dificuldades na tradução do texto lido;",
          },
          {
            item: "Dificuldades em memorizar poemas;",
          },
          {
            item: "Agramatismos estáveis;",
          },
          {
            item: "Violação da concordância das palavras em gênero, número e maiúsculas e minúsculas;",
          },
          {
            item: "Audição fonêmica não formada;",
          },
          {
            item: "No futuro, a criança terá grandes problemas de alfabetização;",
          },
        ],
      },

      school: {
        title: {
          sub: "Programa",
          main: "«Preparação para a escola»",
        },
        age: "De 4 a 6 anos",
        time: "Aulas 2 ou 3 vezes por semana, duração - 55 minutos.",
        desc: {
          one: "As aulas em grupo facilitarão a passagem por um período difícil de adaptação. A criança receberá a preparação necessária para uma aprendizagem bem-sucedida, sentir-se-á calma e confiante nas aulas.",
          two: "As habilidades de comunicação adquiridas o ajudarão a encontrar novos amigos com facilidade.",
          three: "Para crianças a partir dos 5 anos, a aula inclui três blocos:",
          four: "Em muitas escolas, foi introduzido o teste de alunos da primeira série para admissão na escola. Durante nossas aulas, as crianças estudam toda a lista de perguntas feitas em uma entrevista para um futuro aluno da primeira série.",
        },
        list: {
          one: "Leitura - Escrita;",
          two: "Matemática - Lógica;",
          three: "Desenvolvimento da fala;",
        },
      },

      drawing: {
        title: {
          sub: "Programa",
          main: "«A criatividade não tem limites»",
        },
        age: "Mais de 200 masterclasses foram realizadas",
        time: "Mais de 700 crianças visitadas",
        desc: {
          one: "Realizamos master classes culinárias em todos os tipos de sabonetes de nossa própria produção e criamos doces.",
          two: "Ao final de cada evento, você leva seu trabalho com você - um pão de gengibre ou marshmallow feito à mão, sabonete ou criações doces com as próprias mãos. Suas obras-primas se tornarão presentes agradáveis ​​​​para parentes e amigos ou decorarão as prateleiras de sua própria casa. Talvez alguém descubra novas oportunidades e faça da criatividade o seu trabalho.",
        },
      },

      vocal: {
        title: {
          sub: "Programa",
          main: "«Aulas de canto»",
        },
        time: "Domingo às 11:00",
        desc: {
          one: "Temos professores experientes que sabem como revelar o potencial criativo e artístico de cada criança. Afinal, todas as crianças são individuais.",
          two: "Desenvolvemos programas de treinamento exclusivos. Nas aulas de canto, ensinamos as crianças a respirar corretamente enquanto cantam, a controlar a voz para produzir os sons necessários. Ampliamos o alcance da voz, fortalecemos as cordas vocais, o que é importante para as crianças. Afinal, seu aparelho vocal é diferente do de um adulto, é exposto a cargas pesadas e, em alguns casos, eles podem se queixar de dor, irritação na garganta e rouquidão.",
          three: "Nas aulas de canto para crianças, apresentamos às crianças diferentes estilos musicais, incutimos o gosto, ensinamos a sentir as canções, a expressar as suas emoções. Trabalhamos barreiras, timidez, aprendemos a cantar, selecionamos material musical (pop, jazz, acadêmico), aprimoramos a habilidade de execução vocal, shows de palco. Uma criança pode tentar ser vocalista, bem como membro de um conjunto.",
          four: "Convidamos você a visitar nossas aulas individuais de canto para crianças. Vamos ajudar a revelar os talentos de cada criança e deixá-los um pouco mais felizes! O custo das aulas é indicado no site.",
        },
      },

      art: {
        title: {
          sub: "Programa",
          main: "«Estúdio de arte»",
        },
        age: "Dos 3 aos 16 anos",
        time: "Duração – 60 minutos.",
        desc: {
          one: "A geladeira inteira está coberta de desenhos de crianças? Nós sabemos como transformá-los em obras-primas! Já estamos à espera do bebé no nosso atelier de pintura para crianças, onde a criatividade mora em cada recanto! Quem disse que desenho acadêmico é difícil? Nossa professora-artista é apaixonada por seu trabalho e tem a convicção de que qualquer um pode desenhar. Ela vai ensinar as crianças a dar vida às suas fantasias mais loucas em um cavalete e apresentá-las a várias técnicas de pintura.",
          two: "A pintura para crianças é projetada para diferentes faixas etárias - de 3 a 16 anos. Cada grupo tem seu próprio programa educacional. Em média, a duração da aula é de 60 minutos. Durante esse período, as crianças têm tempo para mergulhar completamente no processo e não perder o interesse (isso é especialmente verdadeiro para crianças pequenas e ativas do jardim de infância).",
          three: "Esperamos por si no nosso centro!",
        },
      },

      modal: {
        title: "Reservando um lugar",
        form: {
          name: "Seu nome",
          phone: "+380 99 999-99-99",
          error: "Mensagem não enviada! Por favor, preencha todos os campos.",
          success: "Mensagem enviada com sucesso!",
          btn: "Envie uma mensagem",
        },
      },
    },
  },

  footer: {
    desc: {
      first: "Seja um voluntário ou ajude como um especialista.",
      second: "Aperte o botão!",
    },
    btn: "Eu quero ser útil",
    list: {
      title: "Links Úteis",
      about: "Sobre nosso fundo",
      news: "Notícia",
      contact: "Contatos",
    },
    info: {
      title: "informação adicional",
      one: "USREOU 44341172",
      two: "UA763003460000026004098334201",
      three: 'em JSC "ALPHA-BANK"',
      four: "IMFs 300346",
      five: "USREOU 23494714",
    },
  },
};
