import React from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, FormControl, InputGroup, Modal, Row } from "react-bootstrap";
import useCopy from "../../../utils/useCopy";

const BtcModal = (props) => {
  // Translation
  const { t } = useTranslation();
  const basePath = "home.donat.btc.modal.";
  const { copyToClipboard, copyText } = useCopy();

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{t(basePath + "header")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-center">
          <Col lg={4}>
            <img src={require("../../../assets/img/military.png").default} alt="" />
          </Col>
          <Col lg={8}>
            <p>{t(basePath + "title")}</p>
            <InputGroup hasValidation>
              <InputGroup.Text onClick={copyToClipboard} data-value="wallet" data-tip={copyText} id="inputGroupPrepend">
                <FontAwesomeIcon icon={faCopy} />
              </InputGroup.Text>
              <FormControl
                readOnly
                id="copy-input-wallet"
                type="text"
                value="3E25TcUPAdLG5Ga7KZpudKURQ4a74YuP3e"
                aria-describedby="inputGroupPrepend"
                onClick={copyToClipboard}
              />
            </InputGroup>
            <ReactTooltip />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default BtcModal;
