import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollar, faHeart } from "@fortawesome/free-solid-svg-icons";

import BtcModal from "./Modal/BtcModal";
import UsdtModal from "./Modal/UsdtModal";
import P2pModal from "./Modal/P2pModal";

const Donat = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "home.donat.";

  const [modalShowBtc, setModalShowBtc] = useState(false);
  const [modalShowUsdt, setModalShowUsdt] = useState(false);
  const [modalShowP2p, setModalShowP2p] = useState(false);

  const pointMap = [
    { img: require("../../assets/img/icon/circle.svg") },
    { img: require("../../assets/img/icon/circle.svg") },
    { img: require("../../assets/img/icon/circle.svg") },
    { img: require("../../assets/img/icon/circle.svg") },
    { img: require("../../assets/img/icon/circle.svg") },
  ];

  return (
    <section id="donat" className="donat">
      <div className="points">
        {pointMap.map((item, i) => (
          <span key={i}>
            <img src={item.img.default} alt="" />
          </span>
        ))}
      </div>
      <Container>
        <Row className="justify-content-center">
          <Col lg={7}>
            <h2 className="title">
              <FontAwesomeIcon icon={faDollar} />
              {t(basePath + "title")}
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xl={2} lg={3} md={6}>
            <div className="item">
              <div className="header">
                <img src={require("../../assets/img/icon/BTC.svg").default} alt="" />
                <p>{t(basePath + "btc.title")}</p>
              </div>
              <div className="d-flex justify-content-center">
                <Button className="btn-st" onClick={() => setModalShowBtc(true)}>
                  <FontAwesomeIcon icon={faHeart} />
                  {t(basePath + "btn")}
                </Button>
              </div>
            </div>
          </Col>
          <Col xl={2} lg={3} md={6}>
            <div className="item">
              <div className="header">
                <img src={require("../../assets/img/icon/USDT.svg").default} alt="" />
                <p>{t(basePath + "usdt.title")}</p>
              </div>
              <div className="d-flex justify-content-center">
                <Button className="btn-st" onClick={() => setModalShowUsdt(true)}>
                  <FontAwesomeIcon icon={faHeart} />
                  {t(basePath + "btn")}
                </Button>
              </div>
            </div>
          </Col>
          <Col xl={2} lg={3} md={6}>
            <div className="item">
              <div className="header">
                <img src={require("../../assets/img/icon/mastercard.svg").default} alt="" />
                <p>{t(basePath + "visa.title")}</p>
              </div>
              <div className="d-flex justify-content-center">
                <Button href="https://pay.fondy.eu/s/doz0JFFDpesTUvk" className="btn-st">
                  <FontAwesomeIcon icon={faHeart} />
                  {t(basePath + "btn")}
                </Button>
              </div>
            </div>
          </Col>
          <Col xl={2} lg={3} md={6}>
            <div className="item">
              <div className="header">
                <img src={require("../../assets/img/icon/p2p.svg").default} alt="" />
                <p>{t(basePath + "p2p.title")}</p>
              </div>
              <div className="d-flex justify-content-center">
                <Button className="btn-st" onClick={() => setModalShowP2p(true)}>
                  <FontAwesomeIcon icon={faHeart} />
                  {t(basePath + "btn")}
                </Button>
              </div>
            </div>
          </Col>
          <Col xl={2} lg={3} md={6}>
            <div className="item">
              <div className="header">
                <img src={require("../../assets/img/icon/paypal.png").default} alt="" />
                <p>PayPal</p>
              </div>
              <div className="d-flex justify-content-center">
                <Button href="https://www.paypal.com/paypalme/ktoeslinemy" className="btn-st">
                  <FontAwesomeIcon icon={faHeart} />
                  {t(basePath + "btn")}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <BtcModal show={modalShowBtc} onHide={() => setModalShowBtc(false)} />
      <UsdtModal show={modalShowUsdt} onHide={() => setModalShowUsdt(false)} />
      <P2pModal show={modalShowP2p} onHide={() => setModalShowP2p(false)} />
    </section>
  );
};

export default Donat;
