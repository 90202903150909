import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Modal, Row } from "react-bootstrap";
import { faCalendarDays, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModalDetailsTherapist = (props) => {
  const { t } = useTranslation();
  const basePath = "kindgarden.direc.therapist.";

  let listFirst = [].concat(t(basePath + "listFirst", { returnObjects: true }));

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t(basePath + "title.sub")} {t(basePath + "title.main")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="kind ">
        <Row className="align-items-center k-direct">
          <Col lg={12}>
            <div className="sub-info">
              <span>
                <FontAwesomeIcon icon={faCalendarDays} />
                {t(basePath + "time")}
              </span>
              <div className="content">
                <p className="f-size-16">{t(basePath + "desc.one")}</p>
                <p className="f-size-16">{t(basePath + "desc.two")}</p>
                <p>{t(basePath + "desc.three")}</p>
                <ul>
                  {listFirst.map((item, i) => (
                    <li key={i}>
                      <FontAwesomeIcon icon={faCheck} />
                      {item.item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalDetailsTherapist;
