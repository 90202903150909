export const TRANSLATIONS_IT = {
  navBar: {
    site: "Il nostro sito web",
    btnHelp: "COME PUOI AIUTARE",
    btnRequ: "CHIEDERE AIUTO",

    link: {
      home: "Casa",
      center: "Centro per bambini",
      about: "Sul nostro fondo",
      news: "Notizia",
      nft: "Galleria NFT",
      contact: "Contatti",
    },

    lang: {
      title: "Lingua",
      "en-US": "inglese",
      "ua-UA": "ucraino",
      "es-ES": "spagnolo",
      "fr-FR": "francese",
      "de-DE": "tedesco",
      "it-IT": "italiano",
      "pl-PL": "polacco",
      "pt-PT": "portoghese",
    },
  },

  pageBanner: {
    home: "Casa",
    about: "Sul nostro fondo",
    news: "Notizia",
    nft: "Galleria NFT",
    contact: "Contatti",
  },

  copy: {
    copy: "copia",
    success: "copiato",
  },

  home: {
    banner: {
      title: {
        first: "FONDAZIONE CARITATEVOLE",
        second: '"CHI, SE NON NOI"',
      },
      desc: 'Il nostro fondo "CHI, SE NON NOI" è pienamente responsabile dell\'utilizzo dei fondi raccolti, ogni contributo avrà una finalità diretta. La cosa principale per noi è la tua fiducia!',
      btnHelp: "Come puoi aiutare",
      btnRequ: "Chiedere aiuto",
      btnDonat: "Sacrificio",
    },

    company: {
      title: "Fondazione di beneficenza Who If Not Us",
      desc: "I bambini sono il nostro futuro. Insieme, possiamo superare tutto e rendere il nostro futuro sano, felice e con fiducia nel nostro Paese. La Who If Not Us Charitable Foundation organizza raccolte fondi per aiutare i bambini e le loro famiglie in difficoltà. Assistenza a bambini gravemente malati, ovvero indennità per cure e periodo di riabilitazione.",
      descMil: "Approvvigionamento continuo delle munizioni necessarie per le forze armate ucraine.",
      mission: {
        title: "La missione della Fondazione",
        desc: "Dai il più possibile la gioia di vivere ai bambini e fornisci supporto psicologico ai genitori che ne hanno bisogno ora.",
      },
      purpose: {
        title: "Scopo del fondo",
        desc: "Organizzazione di attività per bambini che influenzano lo sviluppo dei bambini. Sostegno psicologico ai bambini e ai loro genitori. Sostegno finanziario e umanitario alle vittime del conflitto armato in Ucraina.",
      },
      military: {
        title: "Supporto militare",
        desc: "Fornitura di difensori con munizioni e automobili nei punti più caldi dell'Ucraina.",
      },
      widget: {
        title: {
          first: "Insieme, cambieremo il mondo!",
          second: "Inizia subito con noi!",
        },
      },
      work: {
        disability: "Aiutiamo le persone con disabilità",
        retirees: "Aiutiamo i pensionati",
        orphans: "Aiutiamo gli orfani",
        difficult: "Aiutiamo i bambini colpiti durante il conflitto armato in Ucraina",
        military: "Disposizione delle Forze Armate",
      },
    },

    fees: {
      title: "Quando non c'è speranza, non c'è forza e non ci sono soldi - noi possiamo essere lì per voi!",
      feesContent: [
        {
          title: "650 000 UAH.",
          desc: "raccolti per aiutare i bambini",
        },
        {
          title: "93 500 UAH.",
          desc: 'speso per il programma "Guarire con gioia"',
        },
        {
          title: "196 800 UAH.",
          desc: "speso nel programma 'Recovery Therapy'",
        },
        {
          title: "342 000 UAH.",
          desc: 'speso per il programma "Salvare la nostra vita insieme"',
        },
      ],
    },

    our_help: {
      title: "Il nostro aiuto",
      desc: "Il ristorante Soul fish ha aperto un quartier generale di soccorso durante la guerra. Aiutano con il cibo per le Forze armate, la polizia e gli sfollati “Da 41 giorni ognuno di noi aiuta per quanto possibile. Questa è tutta energia forte e invincibile, ci ha unito in un tutto. E grazie a questo ci muoviamo.",
      btn: "Aiuto",
    },

    donat: {
      title: "Assistenza ai bambini feriti durante la guerra sul territorio dell'Ucraina",
      btn: "Donare",
      btc: {
        title: "BTC",
        modal: {
          header: "BTC",
          title: "Portafoglio per le donazioni BTC",
        },
      },
      usdt: {
        title: "USDT",
        modal: {
          header: "USDT TRC-20",
          title: "Portafoglio per le donazioni USDT TRC-20",
        },
      },
      visa: {
        title: "Visa/MasterCard",
      },
      p2p: {
        title: "Р/Р",
        modal: {
          header: "Profilo corrente",
        },
      },
    },

    help: {
      title: "Come possiamo aiutare?",
      desc: "Forse avete un modo per aiutare una famiglia in particolare. Scriveteci e vi risponderemo.",
      form: {
        name: "Il tuo nome",
        phone: "+380 99 999-99-99",
        select: {
          one: "Scegli come puoi aiutare",
          two: "Soldi",
          three: "Volontario",
          four: "Specialista",
          five: "Il nostro aiuto",
        },
        mass: "Il tuo messaggio",
        error: "Messaggio non inviato! Per favore riempi tutti i campi.",
        success: "Messaggio inviato con successo!",
        btn: "Invia messaggio",
      },
      specialityContent: [
        {
          title: "Come volontario della fondazione",
          desc: "Devi essere in grado di partecipare ai progetti della fondazione in modo permanente o una tantum. Devi avere almeno 18 anni.",
        },
        {
          title: "Come specialista",
          desc: "Come medico per aiutare i malati, come psicologo o altro specialista.",
        },
        {
          title: "Come blogger di Internet",
          desc: "Pronto ad aiutare con la diffusione di informazioni su Internet.",
        },
        {
          title: "Come negozio partner",
          desc: "Condurre promozioni relative a cose e beni di prima necessità.",
        },
        {
          title: "Aiuto volontario con denaro",
          desc: "Accettiamo trasferimenti volontari sul conto della carta della fondazione.",
        },
      ],
    },

    request: {
      title: "Chiedere aiuto",
      desc: "Scrivi i tuoi contatti, descrivi la situazione nel dettaglio:",
      info: {
        title: "Puoi ottenere informazioni dettagliate contattando il nostro specialista:",
        name: "Victoria Oleksandrivna Chernyshova",
      },
    },

    transfer: {
      title: "Trasferimento di denaro",
      error: "Errore",
      success: "Con successo",
      thank: "Grazie per il sostegno dei bambini dell'Ucraina",
    },
  },

  about: {
    info: {
      title: "CHI, SE NON NOI Fondazione di beneficenza:",
      subtitle: "Come tutto è cominciato…",
      desc: {
        one: "Il fondo di beneficenza è stato registrato a maggio 2021, ma il vero lavoro di sostegno agli ucraini è in corso dal 2020.",
        two: 'L\'obiettivo principale del fondo "Chi se non noi?" - aiuto per i bambini. I nostri compiti includono anche il sostegno alle persone con disabilità e ai pensionati. La nostra fondazione crede che la generosità e la generosità siano le qualità umane più importanti.',
        three:
          "Siamo convinti che facendo del bene diventiamo più forti e più felici, miglioriamo le relazioni, creiamo un mondo migliore. Tutti dovrebbero essere in grado di condividere tempo, denaro o energia. Questo è un modo affidabile per creare armonia tra le persone.",
      },

      war: {
        title: "La guerra ha cambiato ognuno di noi",
        desc: {
          first:
            "La Fondazione Who If Not Us continua a lavorare 24 ore su 24, 7 giorni su 7 per aiutare chiunque ne abbia bisogno.",
          second: {
            main: "Vestiti, cibo, prodotti per l'igiene personale ci sono stati inviati dalla Polonia. Incoraggiamo tutti a unirsi alla nostra lotta contro il nostro nemico comune e ad unirsi alla squadra! Grazie a tutti coloro che hanno già dato un contributo significativo! Insieme siamo in grado di creare cose incredibili! Insieme stiamo marciando verso la vittoria! Gloria all'Ucraina!",
            sub: "La vittoria sarà nostra!",
          },
        },
      },
    },

    what: {
      title: "Cosa facciamo",
      item: {
        one: "Aiutiamo le persone con disabilità",
        two: "Aiutiamo gli orfani",
        three: "Aiutiamo i pensionati",
        four: "Aiutiamo i bambini colpiti durante il conflitto armato in Ucraina",
      },
    },

    history: {
      title: "La nostra storia",
      desc: {
        fond: "La Fondazione di beneficenza WHO IF NOT WE nasce con il solo scopo di fare del bene alle persone. Cerchiamo di lavorare in diverse direzioni:",
        believ:
          "La nostra fondazione crede che gentilezza e generosità siano le principali qualità umane che ci separano dagli animali.",
        generate:
          "La WHO IF NOT Charitable Foundation è stata creata per riunire persone disposte ad aiutare. Cerchiamo di assicurarci che tutti, indipendentemente dalla situazione finanziaria e dall'età, abbiano l'opportunità di aiutare i bambini. La nostra organizzazione vuole che ogni bambino infelice abbia un'infanzia felice e pacifica.",
      },

      list: {
        money: "Sostegno finanziario e psicologico alle persone in situazioni critiche;",
        therapy: "Trattamento di bambini gravemente malati;",
        moral: "Sostegno ai bambini colpiti dal conflitto armato in Ucraina;",
      },
    },
  },

  news: {
    btn: "Leggi di più",
    report: "Reportage fotografico",
    thanks: "Lettere di ringraziamento",
    page: {
      latest: "Ultime notizie",
      help: {
        title: "Aiuto",
        desc: "Sii un volontario o aiuta come specialista. Premi il bottone!",
        btn: "Sacrificio",
      },
    },
  },

  nft: {
    title: "Galleria",
    btn: "Acquistare",
    price: "Prezzo",

    block: {
      title: {
        main: "Il team ha creato la NFT del nostro fondo, i soldi della vendita vanno ad aiutare le forze armate Ucraine",
        sub: "e i senzatetto.",
      },
    },

    info: {
      title: {
        main: "Febbraio 2022 in Ucraina",
        sub: "è arrivata la guerra.",
      },

      desc: {
        war: "La guerra iniziata dalla Russia. Tutti i cittadini del nostro paese si sono uniti in un tutt'uno - in uno scudo d'acciaio nella lotta e nel confronto di un paese che ha già spezzato milioni di ucraini comuni.",
        team: 'La squadra "Chi, se non noi", come migliaia di altri enti di beneficenza, dal primo giorno di guerra prende ogni parte possibile nel destino e nell\'assistenza al nostro Paese e ai nostri cittadini.',
        friend:
          "Cari amici e filantropi, non vediamo l'ora di ricevere qualsiasi aiuto e supporto che la vostra organizzazione possa fornire.",
      },
      together: "Dopotutto, insieme siamo l'Ucraina",
    },

    nftContent: [
      {
        img: "4",
        cat: "Città eroiche dell'Ucraina",
        title: "Irpen",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304559263746621540",
      },
      {
        img: "1",
        cat: "Città eroiche dell'Ucraina",
        title: "Bucha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304558164234993764",
      },
      {
        img: "7",
        cat: "Città eroiche dell'Ucraina",
        title: "Okhtirka",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304557064723365988",
      },
      {
        img: "10",
        cat: "Città eroiche dell'Ucraina",
        title: "Chernihiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304553766188482660",
      },
      {
        img: "6",
        cat: "Città eroiche dell'Ucraina",
        title: "Mykolayiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304552666676854884",
      },
      {
        img: "2",
        cat: "Città eroiche dell'Ucraina",
        title: "Volnovakha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304551567165227108",
      },
      {
        img: "9",
        cat: "Città eroiche dell'Ucraina",
        title: "Kherson",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304550467653599332",
      },
      {
        img: "3",
        cat: "Città eroiche dell'Ucraina",
        title: "Gostomel",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304548268630343780",
      },
      {
        img: "8",
        cat: "Città eroiche dell'Ucraina",
        title: "Kharkiv",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304547169118716004",
      },
      {
        img: "5",
        cat: "Città eroiche dell'Ucraina",
        title: "Mariupol",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304546069607088228",
      },
    ],
  },

  contact: {
    title: "Informazioni sui contatti",
    address: {
      title: "Indirizzo",
      desc: "Dnipro, via Korolenka, 8 anni, ufficio 10",
    },
    mail: {
      title: "Posta",
    },
    phone: {
      title: "Telefono",
    },
    work: {
      title: "Ore lavorative",
      desc: "Dal lunedì al venerdì: dalle 10:00 alle 17:00",
    },
  },

  kindgarden: {
    banner: {
      subtitle: "Il nostro centro per bambini",
      title: "\"Chi, se non noi\" , crea una fiaba incredibilmente creativa per bambini sotto forma di corsi di perfezionamento",
      btn: "Più dettagli",
    },

    donat: {
      title: "Ti saremo grati per il tuo aiuto",

      switch: {
        one: "Una volta",
        monthly: "Mensile",
      },

      other: "Un altro importo",
      btn: "Sacrificio",
      btnCrypto: "Dona in criptovaluta",

      modal: {
        title: "Criptovaluta",
        btc: "Portafoglio per donazioni BTC",
        usdt: "Portafoglio per donazioni in USDT TRC-20",
      },
    },

    statistic: {
      title: "Ogni mese spendiamo",
      list: {
        one: "Corsi di perfezionamento",
        two: "Corsi di preparazione scolastica",
        three: "Lezioni di pittura",
        four: "Giornate mensili di beneficenza",
      },

      items: {
        one: "Diamo costantemente aiuto e sostegno ai bambini che ne hanno bisogno.",
        two: "Collaboriamo con partner stellari che supportano le nostre attività.",
        three: "Organizziamo eventi sociali per sostenere ogni bambino che ha sofferto a causa della guerra.",
      },

      desc: {
        one: "Ogni mese siamo visitati da circa",
        two: "bambini felici che non vedono l'ora del prossimo incontro con noi!",
      }
    },

    direc: {
      title: "Le nostre indicazioni",
      btnMore: "Più dettagli",
      btnBook: "Prenota un posto",

      brain: {
        title: {
          sub: "Programma",
          main: "«Forma fisica del cervello»",
        },
        age: "Da 3 a 4 anni",
        time: "Lezioni 2 volte a settimana, durata – 55 minuti.",
        desc: {
          one: "Cosa riceverà il bambino dopo le lezioni?",
          two: "Perché dovresti scegliere noi?",
        },
        listFirst: [
          {
            item: "Migliora la lingua, impara nuove parole su argomenti, in base all'età;",
          },
          {
            item: "Imparerà a contare e imparerà le lettere;",
          },
          {
            item: "Si adatta alla squadra, si abitua a stare in compagnia dei bambini;",
          },
          {
            item: "Abituati alla disciplina e all'ordine;",
          },
        ],
        listSecond: [
          {
            item: "Non è necessario portare tuo figlio a classi aggiuntive: lettere, numeri, disegno, suonare strumenti musicali sono inclusi nell'orologio;",
          },
          {
            item: "Gli insegnanti creativi preparano compiti speciali e fiabe per lavorare con le lettere, grazie alle quali i bambini le ricordano facilmente e l'intero processo di apprendimento della lettura avviene nel gioco e senza difficoltà;",
          },
          {
            item: "Più di 500 utili giocattoli educativi;",
          },
          {
            item: "Più di 10 attività in una lezione! I bambini non sono annoiati, ma costantemente impegnati;",
          },
          {
            item: "Non perdi una sola lezione con l'abbonamento, apprezziamo i tuoi soldi e in assenza del bambino, la lezione persa verrà elaborata con l'insegnante;",
          },
        ],
      },

      therapist: {
        title: {
          sub: "Programma",
          main: "«Logopedista»",
        },
        time: "Durata – 60 minuti.",
        desc: {
          one: "Nel centro per bambini si tengono lezioni individuali di logopedia, durante le quali il logopedista imposta e corregge i suoni, li introduce nel linguaggio attivo del bambino. I bambini padroneggiano la ginnastica articolare, consolidano le abilità acquisite.",
          two: "Il lavoro di un logopedista con un bambino dipende dalle sue caratteristiche linguistiche e viene svolto secondo un piano individuale. La durata del corso correttivo dipende anche dalla complessità della situazione.",
          three: "È molto importante notare e correggere tali disturbi nello sviluppo del linguaggio nel tempo come:",
        },
        listFirst: [
          {
            item: "Discorso poco chiaro e poco chiaro;",
          },
          {
            item: "Difetti del linguaggio;",
          },
          {
            item: "Vocabolario scadente;",
          },
          {
            item: "Pronuncia distorta di parole complesse;",
          },
          {
            item: "Il bambino aggiunge o omette sillabe;",
          },
          {
            item: "Difficoltà nella traduzione del testo letto;",
          },
          {
            item: "Difficoltà nel memorizzare poesie;",
          },
          {
            item: "Agrammatismi stabili;",
          },
          {
            item: "Violazione dell'accordo di parole in genere, numero e caso;",
          },
          {
            item: "Udito fonemico non formato;",
          },
          {
            item: "In futuro, il bambino ha grossi problemi con l'alfabetizzazione;",
          },
        ],
      },

      school: {
        title: {
          sub: "Programma",
          main: "«Preparazione per la scuola»",
        },
        age: "Dai 4 ai 6 anni",
        time: "Lezioni 2 o 3 volte a settimana, durata - 55 minuti.",
        desc: {
          one: "Le lezioni di gruppo renderanno facile attraversare un difficile periodo di adattamento. Il bambino riceverà la preparazione necessaria per un apprendimento di successo, si sentirà calmo e sicuro in classe.",
          two: "Le abilità comunicative acquisite ti aiuteranno a trovare facilmente nuovi amici.",
          three: "Per i bambini dai 5 anni di età, la classe comprende tre blocchi:",
          four: "In molte scuole sono state introdotte prove di ammissione a scuola per gli alunni di prima elementare. Durante le nostre lezioni, i bambini studiano l'intero elenco di domande che vengono poste durante un'intervista a un futuro alunno di prima elementare.",
        },
        list: {
          one: "Lettura - scrittura;",
          two: "Matematica - Logica;",
          three: "Sviluppo del linguaggio;",
        },
      },

      drawing: {
        title: {
          sub: "Programma",
          main: "«La creatività non ha limiti»",
        },
        age: "Sono state tenute più di 200 master class",
        time: "Più di 700 bambini visitati",
        desc: {
          one: "Conduciamo corsi di perfezionamento culinari su tutti i tipi di sapone di nostra produzione e creiamo caramelle.",
          two: "Alla fine di ogni evento, porti con te il tuo lavoro: un pan di zenzero o un marshmallow fatti a mano, sapone o dolci creazioni con le tue mani. I tuoi capolavori diventeranno piacevoli regali per parenti e amici, o decoreranno gli scaffali di casa tua. Forse qualcuno scoprirà nuove opportunità e farà della creatività il proprio lavoro.",
        },
      },

      vocal: {
        title: {
          sub: "Programma",
          main: "«Lezioni vocali»",
        },
        time: "Domenica alle 11:00",
        desc: {
          one: "Abbiamo insegnanti esperti che sanno come rivelare il potenziale creativo e l'abilità artistica di ogni bambino. Dopotutto, tutti i bambini sono individuali.",
          two: "Abbiamo sviluppato programmi di formazione unici. Nelle lezioni di canto insegniamo ai bambini a respirare correttamente mentre cantano, a controllare la voce per produrre i suoni necessari. Espandiamo la gamma della voce, rafforziamo le corde vocali, che è importante per i bambini. Dopotutto, il loro apparato vocale è diverso da quello di un adulto, è esposto a carichi pesanti e in alcuni casi possono lamentare dolore, irritazione alla gola e raucedine.",
          three: "Alle lezioni di canto per bambini, introduciamo i bambini a diversi stili musicali, instilliamo gusto, insegniamo loro a sentire le canzoni, esprimere le loro emozioni. Lavoriamo su barriere, timidezza, impariamo a cantare, selezioniamo materiale musicale (pop, jazz, accademico), affiniamo l'abilità dell'esecuzione vocale, concerti sul palco. Un bambino può provare se stesso come cantante, oltre che come membro di un ensemble.",
          four: "Ti invitiamo a visitare le nostre lezioni individuali di canto per bambini. Aiuteremo a rivelare i talenti di ogni bambino e a renderlo un po' più felice! Il costo delle lezioni è indicato sul sito web.",
        },
      },

      art: {
        title: {
          sub: "Programma",
          main: "«Studio d'Arte»",
        },
        age: "Dai 3 ai 16 anni",
        time: "Durata – 60 minuti.",
        desc: {
          one: "L'intero frigorifero è coperto di disegni dei bambini? Sappiamo come trasformarli in capolavori! Stiamo già aspettando il bambino nel nostro studio di pittura per bambini, dove la creatività vive in ogni angolo! Chi ha detto che il disegno accademico è difficile? La nostra insegnante-artista è innamorata del suo lavoro ed è convinta che chiunque possa disegnare. Insegnerà ai bambini come dare vita alle loro fantasie più sfrenate su un cavalletto e li introdurrà a varie tecniche pittoriche.",
          two: "La pittura per bambini è progettata per diverse fasce d'età, dai 3 ai 16 anni. Ogni gruppo ha il proprio programma educativo. In media, la durata della lezione è di 60 minuti. Durante questo periodo, i bambini hanno il tempo di immergersi completamente nel processo e di non perdere interesse (questo è particolarmente vero per i bambini dell'asilo piccoli e attivi).",
          three: "Ti aspettiamo nel nostro centro!",
        },
      },

      modal: {
        title: "Prenotazione di un posto",
        form: {
          name: "Il tuo nome",
          phone: "+380 99 999-99-99",
          error: "Messaggio non inviato! Si prega di compilare tutti i campi.",
          success: "Messaggio inviato con successo!",
          btn: "Mandare un messaggio",
        },
      },
    },
  },

  footer: {
    desc: {
      first: "Sii un volontario o aiuta come specialista.",
      second: "Premi il bottone!",
    },
    btn: "Voglio essere utile",
    list: {
      title: "link utili",
      about: "Sul nostro fondo",
      news: "Notizia",
      contact: "Contatti",
    },
    info: {
      title: "Informazioni aggiuntive",
      one: "USREOU 44341172",
      two: "UA763003460000026004098334201",
      three: 'in JSC "ALPHA-BANK"',
      four: "IFM 300346",
      five: "USREOU 23494714",
    },
  },
};
