import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChild, faHandHoldingHeart, faRankingStar } from "@fortawesome/free-solid-svg-icons";

function Statistic(props) {
  // Translation
  const { t } = useTranslation();
  const basePath = "kindgarden.statistic.";

  return (
    <section className="k-stat">
      <Container>
        <Row className="align-items-center mb-70">
          <Col lg={5}>
            <div className="widget">
              <ReactPlayer
                className="video"
                playing={true}
                controls={true}
                volume={0}
                url={require("../../assets/video/video.mp4").default}
              />
              <div className="shape-2">
                <img src={require("../../assets/img/kinder/element-2.svg").default} alt="" />
              </div>
            </div>
          </Col>
          <Col lg={7} className="info">
            <h3>
              <img src={require("../../assets/img/logo-icon.png").default} alt="" />
              {t(basePath + "title")}
            </h3>
            <Row>
              <Col lg={6} md={6}>
                <div className="item">
                  <span>30</span>
                  <p>{t(basePath + "list.one")}</p>
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="item">
                  <span>60</span>
                  <p>{t(basePath + "list.two")}</p>
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="item">
                  <span>24</span>
                  <p>{t(basePath + "list.three")}</p>
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="item">
                  <span>20</span>
                  <p>{t(basePath + "list.four")}</p>
                </div>
              </Col>
            </Row>
            <div className="subtitle">
              <p>
                {t(basePath + "desc.one")} <b>300</b> {t(basePath + "desc.two")}
              </p>
              <div className="shape-1">
                <img src={require("../../assets/img/kinder/element-3.svg").default} alt="" />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={4}>
            <div className="stat-item">
              <div className="icon">
                <FontAwesomeIcon icon={faHandHoldingHeart} />
              </div>
              <p>{t(basePath + "items.one")}</p>
              <div className="player">
                <ReactPlayer
                  className="video"
                  playing={false}
                  controls={true}
                  volume={0}
                  url={"https://www.youtube.com/shorts/TooW6U8qJwc"}
                />
              </div>
            </div>
          </Col>
          <Col lg={4} md={4}>
            <div className="stat-item">
              <div className="icon">
                <FontAwesomeIcon icon={faRankingStar} />
              </div>
              <p>{t(basePath + "items.two")}</p>
              <div className="player">
                <ReactPlayer
                  className="video"
                  playing={false}
                  controls={true}
                  volume={0}
                  url={"https://www.youtube.com/watch?v=I_3hYLp_lsU"}
                />
              </div>
            </div>
          </Col>
          <Col lg={4} md={4}>
            <div className="stat-item">
              <div className="icon">
                <FontAwesomeIcon icon={faChild} />
              </div>
              <p>{t(basePath + "items.three")}</p>
              <div className="player">
                <ReactPlayer
                  className="video"
                  playing={false}
                  controls={true}
                  volume={0}
                  url={"https://www.youtube.com/watch?v=xNMlk4qLJXo"}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Statistic;
