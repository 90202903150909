import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Form, InputGroup, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandHoldingMedical,
  faUser,
  faPhone,
  faPaperPlane,
  faInfoCircle,
  faCheck,
  faTimesCircle,
  faCheckCircle,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { send } from "emailjs-com";

const Hepl = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "home.help.";

  const [defaultValues, setDefaultValues] = useState({
    name: "",
    phone: "",
    email: "",
    select: "",
    textarea: "",
  });

  const handleChange = (e) => {
    setDefaultValues({ ...defaultValues, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    if (
      defaultValues.name === "" ||
      defaultValues.phone === "" ||
      defaultValues.email === "" ||
      defaultValues.textarea === ""
    ) {
      document.querySelector(".error").classList.add("active");
      document.querySelector(".success").classList.remove("active");
    } else {
      send("service_yt7ukk4", "template_r5mwdyf", defaultValues, "KwLPLw6G84xmWMfAa").then(
        (result) => {
          document.querySelector(".error").classList.remove("active");
          document.querySelector(".success").classList.add("active");
        },
        (error) => {
          console.log(error.text);
        }
      );
    }
  };

  useEffect(() => {
    setDefaultValues({
      ...defaultValues,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let specialityContent = [].concat(t(basePath + "specialityContent", { returnObjects: true }));

  return (
    <section id="help" className="help">
      <Container>
        <Row className="justify-content-center">
          <Col lg={7}>
            <h2 className="title">
              <FontAwesomeIcon icon={faHandHoldingMedical} />
              {t(basePath + "title")}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <div className="form-custom">
              <p>
                <FontAwesomeIcon icon={faInfoCircle} />
                {t(basePath + "desc")}
              </p>
              <Form>
                <Row>
                  <Form.Group as={Col} md="6">
                    <InputGroup>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Form.Control
                        name="name"
                        type="text"
                        placeholder={t(basePath + "form.name")}
                        value={defaultValues.name}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group as={Col} md="6">
                    <InputGroup>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon={faPhone} />
                      </InputGroup.Text>
                      <Form.Control
                        name="phone"
                        type="number"
                        value={defaultValues.phone}
                        onChange={handleChange}
                        placeholder={t(basePath + "form.phone")}
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group as={Col} md="12">
                    <InputGroup>
                      <InputGroup.Text id="inputGroupPrepend">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        name="email"
                        type="email"
                        value={defaultValues.email}
                        onChange={handleChange}
                        placeholder="example@email.com"
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group as={Col} md="12">
                    <InputGroup>
                      <Form.Select name="select" onChange={handleChange}>
                        <option value={0} disabled>
                          {t(basePath + "form.select.one")}
                        </option>
                        <option value={t(basePath + "form.select.two")}>{t(basePath + "form.select.two")}</option>
                        <option value={t(basePath + "form.select.three")}>{t(basePath + "form.select.three")}</option>
                        <option value={t(basePath + "form.select.four")}>{t(basePath + "form.select.four")}</option>
                        <option value={t(basePath + "form.select.five")}>{t(basePath + "form.select.five")}</option>
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group as={Col} md="12">
                    <InputGroup>
                      <Form.Control
                        name="textarea"
                        as="textarea"
                        value={defaultValues.textarea}
                        onChange={handleChange}
                        placeholder={t(basePath + "form.mass")}
                      />
                    </InputGroup>
                  </Form.Group>
                </Row>

                <div className="valid">
                  <span className="invalid-feedback error">
                    <FontAwesomeIcon icon={faTimesCircle} />
                    {t(basePath + "form.error")}
                  </span>
                  <span className="invalid-feedback success">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    {t(basePath + "form.success")}
                  </span>
                </div>

                <Button onClick={onSubmit} className="btn-st">
                  <FontAwesomeIcon icon={faPaperPlane} />
                  {t(basePath + "form.btn")}
                </Button>
              </Form>
            </div>
          </Col>
          <Col lg={6}>
            <ul>
              {specialityContent.map((item, i) => (
                <li key={i}>
                  <h5>
                    <FontAwesomeIcon icon={faCheck} />
                    {item.title}
                  </h5>
                  <p>{item.desc}</p>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hepl;
