import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";
import { faCalendarDays, faPersonChalkboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ModalDetailsTherapist from "./elements/ModalDetailsTherapist";
import ModalOrderTherapist from "./elements/ModalOrderTherapist";

function Therapist(props) {
  const { t } = useTranslation();
  const basePath = "kindgarden.direc.therapist.";

  const [modalShow, setModalShow] = useState(false);
  const [modalShowOrder, setModalShowOrder] = useState(false);

  return (
    <Row className="align-items-center mt-80">
      <Col lg={5}>
        <div className="widget">
          <img
            src={require("../../../assets/img/kinder/speech/img-1.jpg").default}
            alt=""
            style={{
              maxWidth: "70%",
            }}
          />
          <img
            className="shape"
            src={require("../../../assets/img/kinder/element-5.svg").default}
            alt=""
            style={{
              top: "30px",
              left: "calc(50% - 75px)",
              width: "130px",
              height: "230px",
            }}
          />
        </div>
      </Col>
      <Col lg={7} className="pl-30">
        <h4>
          <FontAwesomeIcon icon={faPersonChalkboard} />
          {t(basePath + "title.sub")} <span>{t(basePath + "title.main")}</span>
        </h4>
        <div className="sub-info">
          <span>
            <FontAwesomeIcon icon={faCalendarDays} />
            {t(basePath + "time")}
          </span>
          <div className="content">
            <p className="f-size-16">{t(basePath + "desc.one")}</p>
            <p className="f-size-16">{t(basePath + "desc.two")}</p>
          </div>
          <div className="d-flex mt-30">
            <Button onClick={() => setModalShow(true)} className="btn-st">
              {t("kindgarden.direc.btnMore")}
            </Button>
            <Button onClick={() => setModalShowOrder(true)} className="btn-st">
              {t("kindgarden.direc.btnBook")}
            </Button>
          </div>
        </div>
      </Col>

      <ModalOrderTherapist show={modalShowOrder} onHide={() => setModalShowOrder(false)} />
      <ModalDetailsTherapist show={modalShow} onHide={() => setModalShow(false)} />
    </Row>
  );
}

export default Therapist;
