import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDove } from "@fortawesome/free-solid-svg-icons";

const Fees = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "home.fees.";

  let feesContent = [].concat(t(basePath + "feesContent", { returnObjects: true }));

  return (
    <section className="fees">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6}>
            <h2 className="title">
              <FontAwesomeIcon icon={faDove} />
              {t(basePath + "title")}
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {feesContent.map((item, i) => (
            <Col lg={3} md={6} key={i}>
              <div className="item">
                <h4>{item.title}</h4>
                <p>{item.desc}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Fees;
