import React from "react";
import PageBanner from "../components/PageBanner";
import Info from "../components/About/Info";
import WhatDo from "../components/About/WhatDo";
import History from "../components/About/History";
import Hepl from "../components/Home/Hepl";
import Footer from "../components/Footer";

function About() {
  return (
    <>
      <PageBanner />
      <Info />
      <WhatDo />
      <History />
      <Hepl />
      <Footer />
    </>
  );
}

export default About;
