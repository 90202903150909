import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faMapMarkerAlt, faEnvelope, faPhone, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "contact.";

  return (
    <section className="contact">
      <Container>
        <Row className="justify-content-center">
          <Col lg={12}>
            <h2 className="title">
              <FontAwesomeIcon icon={faAddressCard} />
              {t(basePath + "title")}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col lg={3} md={6}>
            <div className="info">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <h5>{t(basePath + "address.title")}</h5>
              <p>{t(basePath + "address.desc")}</p>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="info">
              <FontAwesomeIcon icon={faEnvelope} />
              <h5>{t(basePath + "mail.title")}</h5>
              <p>
                <a href="mailto:turbota.dp@gmail.com">turbota.dp@gmail.com</a>
              </p>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="info">
              <FontAwesomeIcon icon={faPhone} />
              <h5>{t(basePath + "phone.title")}</h5>
              <p>
                <a href="tel:+38 (098) 187-9999">+38 (098) 187-9999</a>
              </p>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="info">
              <FontAwesomeIcon icon={faCalendarAlt} />
              <h5>{t(basePath + "work.title")}</h5>
              <p>{t(basePath + "work.desc")}</p>
            </div>
          </Col>
          <Col lg={12}>
            <iframe
              title="Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d88811.19313456902!2d34.966575332980305!3d48.477217585371825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dbe2e732669d95%3A0xa0be09d071e88412!2z0YPQuy4g0JrQvtGA0L7Qu9C10L3QutC-LCA4LCDQlNC90LjQv9GA0L4sINCU0L3QtdC_0YDQvtC_0LXRgtGA0L7QstGB0LrQsNGPINC-0LHQu9Cw0YHRgtGMLCA0OTAwMA!5e0!3m2!1sru!2sua!4v1637695216699!5m2!1sru!2sua"
              width="100%"
              height="500"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </Col>
        </Row>
      </Container>
      <div></div>
    </section>
  );
};

export default Contact;
