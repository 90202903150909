export const TRANSLATIONS_DE = {
  navBar: {
    site: "Unsere Website",
    btnHelp: "WIE SIE HELFEN KÖNNEN",
    btnRequ: "BITTE UM HILFE",

    link: {
      home: "Heim",
      center: "Kinderzentrum",
      about: "Über unseren Fonds",
      news: "Nachricht",
      nft: "NFT-Galerie",
      contact: "Kontakte",
    },

    lang: {
      title: "Sprache",
      "en-US": "Englisch",
      "ua-UA": "Ukrainisch",
      "es-ES": "Spanisch",
      "fr-FR": "Französisch",
      "de-DE": "Deutsch",
      "it-IT": "Italienisch",
      "pl-PL": "Polieren",
      "pt-PT": "Portugiesisch",
    },
  },

  pageBanner: {
    home: "Heim",
    about: "Über unseren Fonds",
    news: "Nachricht",
    nft: "NFT-Galerie",
    contact: "Kontakte",
  },

  copy: {
    copy: "Kopieren",
    success: "Kopiert",
  },

  home: {
    banner: {
      title: {
        first: "GEMEINSCHAFTLICHE STIFTUNG",
        second: '"WER, WENN NICHT WIR"',
      },
      desc: 'Unser Fonds "WER, WENN NICHT WIR" ist voll verantwortlich für die Verwendung der gesammelten Mittel, jeder Beitrag hat einen direkten Zweck. Das Wichtigste für uns ist Ihr Vertrauen!',
      btnHelp: "Wie kannst du helfen",
      btnRequ: "Bitten Sie um Hilfe",
      btnDonat: "Opfern",
    },

    company: {
      title: "Wer wenn nicht wir Wohltätigkeitsstiftung",
      desc: "Kinder sind unsere Zukunft. Gemeinsam können wir alles überwinden und unsere Zukunft gesund, glücklich und im Glauben an unser Land gestalten. Die Who If Not Us Charitable Foundation organisiert Spendenaktionen, um Kindern und ihren Familien in Not zu helfen. Hilfe für schwerkranke Kinder, nämlich Bezahlung der Behandlungs- und Rehabilitationszeit.",
      descMil: "Kontinuierliche Beschaffung der notwendigen Munition für die Streitkräfte der Ukraine.",
      mission: {
        title: "Der Auftrag der Stiftung",
        desc: "Schenken Sie Kindern so viel Lebensfreude wie möglich und leisten Sie psychologische Unterstützung für Eltern, die sie jetzt brauchen.",
      },
      purpose: {
        title: "Zweck des Fonds",
        desc: "Organisation von Aktivitäten für Kinder, die sich auf die Entwicklung von Kindern auswirken. Psychologische Unterstützung für Kinder und ihre Eltern. Finanzielle und humanitäre Unterstützung für die Opfer des bewaffneten Konflikts in der Ukraine.",
      },
      military: {
        title: "Militärische Unterstützung",
        desc: "Versorgung der Verteidiger mit Munition und Autos an den heißesten Orten der Ukraine.",
      },
      widget: {
        title: {
          first: "Gemeinsam – wir werden die Welt verändern!",
          second: "Starten Sie jetzt mit uns durch!",
        },
      },
      work: {
        disability: "Wir helfen Menschen mit Behinderungen",
        retirees: "Wir helfen Rentnern",
        orphans: "Wir helfen Waisenkindern",
        difficult: "Wir helfen Kindern, die während des bewaffneten Konflikts in der Ukraine betroffen sind",
        military: "Bereitstellung der Streitkräfte",
      },
    },

    fees: {
      title: "Wenn es keine Hoffnung, keine Kraft und kein Geld gibt - wir können für Sie da sein!",
      feesContent: [
        {
          title: "650 000 UAH.",
          desc: "wurden für die Unterstützung von Kindern gesammelt",
        },
        {
          title: "93 500 UAH.",
          desc: 'für das Programm "Heilen mit Freude" ausgegeben',
        },
        {
          title: "196 800 UAH.",
          desc: 'für das Programm "Wiederherstellende Therapie" ausgegeben',
        },
        {
          title: "342 000 UAH.",
          desc: 'für das Programm "Gemeinsam das Leben retten"',
        },
      ],
    },

    our_help: {
      title: "Unsere Hilfe",
      desc: "Das Soul Fish Restaurant eröffnete während des Krieges ein Hilfshauptquartier. Sie helfen mit Lebensmitteln für Bundeswehr, Polizei und Vertriebene „Seit 41 Tagen hilft jeder von uns, so gut er kann. Das alles ist eine starke und unbesiegbare Energie, die uns zu einem Ganzen vereint hat. Und dank dessen bewegen wir uns.",
      btn: "Hilfe",
    },

    donat: {
      title: "Hilfe für Kinder, die während des Krieges auf dem Territorium der Ukraine verletzt wurden",
      btn: "Spenden Sie",
      btc: {
        title: "BTC",
        modal: {
          header: "BTC",
          title: "Geldbörse für Spenden BTC",
        },
      },
      usdt: {
        title: "USDT",
        modal: {
          header: "USDT TRC-20",
          title: "Geldbörse für Spenden USDT TRC-20",
        },
      },
      visa: {
        title: "Visa/MasterCard",
      },
      p2p: {
        title: "Р/Р",
        modal: {
          header: "Aktuelles Konto",
        },
      },
    },

    help: {
      title: "Wie können wir helfen?",
      desc: "Vielleicht haben Sie eine Möglichkeit, einer bestimmten Familie zu helfen. Bitte schreiben Sie uns und wir werden uns bei Ihnen melden.",
      form: {
        name: "Dein Name",
        phone: "+380 99 999-99-99",
        select: {
          one: "Wählen Sie, wie Sie helfen können",
          two: "Geld",
          three: "Ehrenamtliche Mitarbeiter",
          four: "Spezialist",
          five: "Unsere Hilfe",
        },
        mass: "Ihre Nachricht",
        error: "Nachricht nicht gesendet! Bitte füllen Sie alle Felder aus.",
        success: "Nachricht erfolgreich gesendet!",
        btn: "Nachricht senden",
      },
      specialityContent: [
        {
          title: "Als Freiwilliger der Stiftung",
          desc: "Sie müssen in der Lage sein, sich dauerhaft oder einmalig an den Projekten der Stiftung zu beteiligen. Sie müssen mindestens 18 Jahre alt sein.",
        },
        {
          title: "Als Spezialist",
          desc: "Als Arzt, um kranken Menschen zu helfen, als Psychologe oder anderer Spezialist.",
        },
        {
          title: "Als Internetblogger",
          desc: "Bereit, bei der Verbreitung von Informationen im Internet zu helfen.",
        },
        {
          title: "Als Partnershop",
          desc: " Durchführung von Werbeaktionen in Bezug auf Dinge und Grundbedürfnisse.",
        },
        {
          title: "Ehrenamtliche Hilfe mit Geld",
          desc: "Wir akzeptieren freiwillige Überweisungen auf das Kartenkonto der Stiftung.",
        },
      ],
    },

    request: {
      title: "Bitten Sie um Hilfe",
      desc: "Schreiben Sie Ihre Kontakte, beschreiben Sie die Situation im Detail:",
      info: {
        title: "Detaillierte Informationen erhalten Sie bei unserem Spezialisten:",
        name: "Victoria Oleksandrivna Chernyshova",
      },
    },

    transfer: {
      title: "Geldüberweisung",
      error: "Fehler",
      success: "Erfolgreich",
      thank: "Vielen Dank für die Unterstützung der Kinder der Ukraine",
    },
  },

  about: {
    info: {
      title: "WER, WENN NICHT WIR Wohltätigkeitsstiftung:",
      subtitle: "Wie alles begann…",
      desc: {
        one: "Der Wohltätigkeitsfonds wurde im Mai 2021 registriert, aber die eigentliche Arbeit zur Unterstützung der Ukrainer läuft seit 2020.",
        two: 'Das Hauptziel des Fonds "Wer außer uns?" - Hilfe für Kinder. Zu unseren Aufgaben gehört auch die Betreuung von Menschen mit Behinderungen und Rentnern. Unsere Stiftung glaubt, dass Großzügigkeit und Großzügigkeit die wichtigsten menschlichen Eigenschaften sind.',
        three:
          "Wir sind davon überzeugt, dass wir durch Gutes tun stärker und glücklicher werden, Beziehungen verbessern und eine bessere Welt schaffen. Jeder sollte in der Lage sein, Zeit, Geld oder Energie zu teilen. Dies ist ein zuverlässiger Weg, um Harmonie zwischen Menschen zu schaffen.",
      },

      war: {
        title: "Der Krieg hat jeden von uns verändert",
        desc: {
          first: "Die Who If Not Us Foundation arbeitet weiterhin rund um die Uhr, um allen Bedürftigen zu helfen.",
          second: {
            main: "Kleidung, Lebensmittel, Körperpflegeprodukte wurden uns alle aus Polen zugeschickt. Wir ermutigen alle, sich unserem Kampf gegen unseren gemeinsamen Feind anzuschließen und dem Team beizutreten! Vielen Dank an alle, die bereits einen wesentlichen Beitrag geleistet haben! Zusammen sind wir in der Lage, unglaubliche Dinge zu erschaffen! Gemeinsam marschieren wir zum Sieg! Ruhm der Ukraine!",
            sub: "Der Sieg wird unser sein!",
          },
        },
      },
    },

    what: {
      title: "Was wir tun",
      item: {
        one: "Wir helfen Menschen mit Behinderungen",
        two: "Wir helfen Waisen",
        three: "Wir helfen Rentnern",
        four: "Wir helfen Kindern, die während des bewaffneten Konflikts in der Ukraine betroffen sind",
      },
    },

    history: {
      title: "Unsere Geschichte",
      desc: {
        fond: "Die WHO IF NOT WE Charitable Foundation wurde mit dem alleinigen Zweck gegründet, Menschen Gutes zu tun. Wir versuchen, in verschiedene Richtungen zu arbeiten:",
        believ:
          "Unsere Stiftung glaubt, dass Freundlichkeit und Großzügigkeit die wichtigsten menschlichen Eigenschaften sind, die uns von Tieren unterscheiden.",
        generate:
          "Die WHO IF NOT Charitable Foundation wurde gegründet, um Menschen zusammenzubringen, die bereit sind zu helfen. Wir versuchen sicherzustellen, dass jeder, unabhängig von seiner finanziellen Situation und seinem Alter, die Möglichkeit hat, Kindern zu helfen. Unsere Organisation möchte, dass jedes unglückliche Kind eine glückliche und friedliche Kindheit hat.",
      },

      list: {
        money: "Finanzielle und psychologische Unterstützung für Menschen in kritischen Situationen;",
        therapy: "Behandlung schwerkranker Kinder;",
        moral:
          "Unterstützung für Kinder, die vom bewaffneten Konflikt in der Ukraine betroffen sind;",
      },
    },
  },

  news: {
    btn: "Weiterlesen",
    report: "Fotobericht",
    thanks: "Dankesbriefe",
    page: {
      latest: "Neuesten Nachrichten",
      help: {
        title: "Hilfe",
        desc: "Seien Sie ehrenamtlich oder helfen Sie als Fachkraft. Drücken Sie den Knopf!",
        btn: "Opfern",
      },
    },
  },

  contact: {
    title: "Kontaktinformation",
    address: {
      title: "Die Anschrift",
      desc: "Dnipro, Straße Korolenka, 8, Büro 10",
    },
    mail: {
      title: "Post",
    },
    phone: {
      title: "Telefon",
    },
    work: {
      title: "Arbeitszeit",
      desc: "Montag bis Freitag: 10:00 bis 17:00 Uhr",
    },
  },

  nft: {
    title: "Galerie",
    btn: "Besorgen",
    price: "Preis",

    block: {
      title: {
        main: "Das Team hat die NFT unseres Fonds gegründet, das Geld aus dem Verkauf geht an die Streitkräfte der Ukraine",
        sub: "und Obdachlose.",
      },
    },

    info: {
      title: {
        main: "Februar 2022 in die Ukraine",
        sub: "der Krieg kam.",
      },

      desc: {
        war: "Der Krieg begann von Russland. Alle Bürger unseres Landes haben sich zu einem Ganzen vereint - zu einem Stahlschild im Kampf und in der Konfrontation eines Landes, das bereits Millionen gewöhnlicher Ukrainer gebrochen hat.",
        team: "Das Team „Wer, wenn nicht wir“, wie Tausende anderer Wohltätigkeitsorganisationen, nimmt vom ersten Tag des Krieges an jede erdenkliche Rolle am Schicksal und an der Hilfe für unser Land und unsere Bürger.",
        friend: "Liebe Freunde und Philanthropen, wir freuen uns über jede Hilfe und Unterstützung Ihrer Organisation.",
      },
      together: "Schließlich sind wir zusammen die Ukraine",
    },

    nftContent: [
      {
        img: "4",
        cat: "Heldenstädte der Ukraine",
        title: "Irpen",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304559263746621540",
      },
      {
        img: "1",
        cat: "Heldenstädte der Ukraine",
        title: "Bucha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304558164234993764",
      },
      {
        img: "7",
        cat: "Heldenstädte der Ukraine",
        title: "Okhtirka",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304557064723365988",
      },
      {
        img: "10",
        cat: "Heldenstädte der Ukraine",
        title: "Chernihiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304553766188482660",
      },
      {
        img: "6",
        cat: "Heldenstädte der Ukraine",
        title: "Mykolayiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304552666676854884",
      },
      {
        img: "2",
        cat: "Heldenstädte der Ukraine",
        title: "Volnovakha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304551567165227108",
      },
      {
        img: "9",
        cat: "Heldenstädte der Ukraine",
        title: "Kherson",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304550467653599332",
      },
      {
        img: "3",
        cat: "Heldenstädte der Ukraine",
        title: "Gostomel",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304548268630343780",
      },
      {
        img: "8",
        cat: "Heldenstädte der Ukraine",
        title: "Kharkiv",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304547169118716004",
      },
      {
        img: "5",
        cat: "Heldenstädte der Ukraine",
        title: "Mariupol",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304546069607088228",
      },
    ],
  },

  kindgarden: {
    banner: {
      subtitle: "Unser Kinderzentrum",
      title: "\"Wer, wenn nicht wir\", schafft in Form von Meisterkursen ein unglaublich kreatives Märchen für Kinder",
      btn: "Mehr Details",
    },

    donat: {
      title: "Wir werden Ihnen für Ihre Hilfe dankbar sein",

      switch: {
        one: "Einmal",
        monthly: "Monatlich",
      },

      other: "Ein weiterer Betrag",
      btn: "Opfern",
      btnCrypto: "Spenden Sie in Kryptowährung",

      modal: {
        title: "Kryptowährung",
        btc: "BTC-Spendengeldbörse",
        usdt: "USDT-Spendengeldbörse TRC-20",
      },
    },

    statistic: {
      title: "Jeden Monat verbringen wir",
      list: {
        one: "Meister Klasse",
        two: "Schulvorbereitungskurse",
        three: "Malunterricht",
        four: "Monatliche Wohltätigkeitstage",
      },

      items: {
        one: "Wir leisten ständig Hilfe und unterstützen Kinder, die sie brauchen.",
        two: "Wir arbeiten mit Starpartnern zusammen, die unsere Aktivitäten unterstützen.",
        three: "Wir organisieren gesellschaftliche Veranstaltungen, um jedes Kind zu unterstützen, das unter dem Krieg gelitten hat.",
      },

      desc: {
        one: "Jeden Monat besuchen uns ca.",
        two: "glückliche Kinder, die sich auf das nächste Treffen mit uns freuen!",
      }
    },

    direc: {
      title: "Unsere Wegbeschreibung",
      btnMore: "Mehr Details",
      btnBook: "Buchen Sie einen Platz",

      brain: {
        title: {
          sub: "Programm",
          main: "«Gehirn-Fitness»",
        },
        age: "Von 3 bis 4 Jahren",
        time: "Unterricht 2 Mal pro Woche, Dauer – 55 Minuten.",
        desc: {
          one: "Was bekommt das Kind nach dem Unterricht?",
          two: "Warum sollten Sie sich für uns entscheiden?",
        },
        listFirst: [
          {
            item: "Sprache verbessern, neue Wörter zu Themen lernen, je nach Alter;",
          },
          {
            item: "Lernt zu zählen und Buchstaben zu lernen;",
          },
          {
            item: "Passt sich dem Team an, gewöhnt sich an die Gesellschaft von Kindern;",
          },
          {
            item: "Disziplin und Ordnung lehren;",
          },
        ],
        listSecond: [
          {
            item: "Sie müssen Ihr Kind nicht zu zusätzlichen Klassen bringen - Buchstaben, Zahlen, Zeichnen, Spielen von Musikinstrumenten sind in der Uhr enthalten; ",
          },
          {
            item: "Kreative Lehrer bereiten spezielle Aufgaben und Märchen für die Arbeit mit Buchstaben vor, dank derer sich Kinder leicht an sie erinnern und der gesamte Prozess des Lesenlernens spielerisch und ohne Schwierigkeiten abläuft.",
          },
          {
            item: "Mehr als 500 nützliche Lernspielzeuge;",
          },
          {
            item: "Mehr als 10 Aufgaben in einer Lektion! Kinder sind nicht gelangweilt, sondern ständig beschäftigt;",
          },
          {
            item: "Sie verpassen mit dem Abonnement keine einzige Unterrichtsstunde, wir wissen Ihr Geld zu schätzen und bei Abwesenheit des Kindes wird die verpasste Unterrichtsstunde mit dem Lehrer ausgearbeitet;",
          },
        ],
      },

      therapist: {
        title: {
          sub: "Programm",
          main: "«Sprachtherapeut»",
        },
        time: "Dauer – 60 Minuten.",
        desc: {
          one: "Im Kinderzentrum finden individuelle Sprachtherapiekurse statt, bei denen der Logopäde Geräusche einstellt und fixiert und sie in die aktive Sprache des Kindes einführt. Kinder beherrschen Artikulationsgymnastik, festigen die erworbenen Fähigkeiten.",
          two: "Die Arbeit eines Logopäden mit einem Kind hängt von seinen sprachlichen Merkmalen ab und wird nach einem individuellen Plan durchgeführt. Die Dauer des Korrekturkurses hängt auch von der Komplexität der Situation ab.",
          three: "Es ist sehr wichtig, solche Störungen in der Sprachentwicklung rechtzeitig zu erkennen und zu korrigieren, wie:",
        },
        listFirst: [
          {
            item: "Unklare, undeutliche Sprache;",
          },
          {
            item: "Sprachfehler;",
          },
          {
            item: "Schwacher Wortschatz;",
          },
          {
            item: "Verzerrte Aussprache komplexer Wörter;",
          },
          {
            item: "Das Kind fügt Silben hinzu oder lässt sie weg;",
          },
          {
            item: "Schwierigkeiten bei der Übersetzung des gelesenen Textes;",
          },
          {
            item: "Schwierigkeiten beim Auswendiglernen von Gedichten;",
          },
          {
            item: "Stabile Agrammatismen;",
          },
          {
            item: "Verletzung der Vereinbarung von Wörtern in Geschlecht, Numerus und Groß-/Kleinschreibung;",
          },
          {
            item: "Keine bildung von phonemischem Gehör;",
          },
          {
            item: "In Zukunft hat das Kind große Probleme mit der Alphabetisierung;",
          },
        ],
      },

      school: {
        title: {
          sub: "Programm",
          main: "«Vorbereitung auf die Schule»",
        },
        age: "Von 4 bis 6 Jahren",
        time: "Unterricht 2 oder 3 Mal pro Woche, Dauer - 55 Minuten.",
        desc: {
          one: "Gruppenunterricht wird es leicht machen, eine schwierige Phase der Anpassung zu überstehen. Das Kind erhält die notwendige Vorbereitung für erfolgreiches Lernen, fühlt sich im Unterricht ruhig und sicher.",
          two: "Die erworbenen Kommunikationsfähigkeiten helfen Ihnen, leicht neue Freunde zu finden.",
          three: "Für Kinder ab 5 Jahren umfasst der Unterricht drei Blöcke:",
          four: "An vielen Schulen wurde eine Prüfung der Erstklässler für die Aufnahme in die Schule eingeführt. Während unseres Unterrichts studieren die Kinder den gesamten Fragenkatalog, der bei einem Vorstellungsgespräch mit einem zukünftigen Erstklässler gestellt wird.",
        },
        list: {
          one: "Lesen - Schreiben;",
          two: "Mathematik - Logik;",
          three: "Sprachentwicklung;",
        },
      },

      drawing: {
        title: {
          sub: "Programm",
          main: "«Der Kreativität sind keine Grenzen gesetzt»",
        },
        age: "Mehr als 200 Meisterkurse wurden abgehalten",
        time: "Mehr als 700 Kinder besuchten uns",
        desc: {
          one: "Wir führen kulinarische Meisterkurse für alle Arten von Seifen aus eigener Produktion durch und kreieren Süßigkeiten.",
          two: "Am Ende jeder Veranstaltung nehmen Sie Ihr Werk mit – ein handgemachter Lebkuchen oder Marshmallow, Seife oder süße Kreationen mit Ihren eigenen Händen. Ihre Meisterwerke werden zu angenehmen Geschenken für Verwandte und Freunde oder schmücken die Regale in Ihrem eigenen Zuhause. Vielleicht entdeckt jemand neue Möglichkeiten und macht Kreativität zu seinem Beruf.",
        },
      },

      vocal: {
        title: {
          sub: "Programm",
          main: "«Gesangsunterricht»",
        },
        time: "Sonntag um 11:00 Uhr",
        desc: {
          one: "Wir haben erfahrene Lehrer, die wissen, wie man das kreative Potenzial und die Kunstfertigkeit jedes Kindes zum Vorschein bringt. Schließlich sind alle Kinder individuell.",
          two: "Wir haben einzigartige Trainingsprogramme entwickelt. Im Gesangsunterricht bringen wir den Kindern bei, beim Singen richtig zu atmen, ihre Stimme zu kontrollieren, um die nötigen Töne zu erzeugen. Wir erweitern den Stimmumfang, stärken die Stimmbänder, was für Kinder wichtig ist. Schließlich ist ihr Stimmapparat anders als der eines Erwachsenen, er ist starken Belastungen ausgesetzt und sie können teilweise über Schmerzen, Reizungen im Hals und Heiserkeit klagen.",
          three: "Beim Gesangsunterricht für Kinder führen wir Kinder in verschiedene Musikstile ein, vermitteln Geschmack, bringen ihnen bei, Lieder zu fühlen, ihre Emotionen auszudrücken. Wir arbeiten an Barrieren, Schüchternheit, lernen singen, wählen musikalisches Material aus (Pop, Jazz, Akademiker), verfeinern die stimmliche Darbietung, Bühnenkonzerte. Ein Kind kann sich sowohl als Sänger als auch als Mitglied eines Ensembles versuchen.",
          four: "Wir laden Sie ein, unseren individuellen Gesangsunterricht für Kinder zu besuchen. Wir werden helfen, die Talente jedes Kindes zu entdecken und sie ein wenig glücklicher zu machen! Die Kosten für die Kurse sind auf der Website angegeben.",
        },
      },

      art: {
        title: {
          sub: "Programm",
          main: "«Kunstatelier»",
        },
        age: "Von 3 bis 16 Jahren",
        time: "Dauer – 60 Minuten.",
        desc: {
          one: "Ist der ganze Kühlschrank mit Kinderzeichnungen bedeckt? Wir wissen, wie man sie in Meisterwerke verwandelt! Wir warten schon auf das Baby in unserem Malatelier für Kinder, wo die Kreativität in jeder Ecke lebt! Wer hat gesagt, dass akademisches Zeichnen schwierig ist? Unsere Lehrer-Künstlerin liebt ihre Arbeit und ist überzeugt, dass jeder zeichnen kann. Sie bringt Kindern bei, wie sie ihre wildesten Fantasien auf einer Staffelei zum Leben erwecken können, und führt sie in verschiedene Maltechniken ein.",
          two: "Malen für Kinder ist für verschiedene Altersgruppen konzipiert - von 3 bis 16 Jahren. Jede Gruppe hat ihr eigenes pädagogisches Programm. Im Durchschnitt beträgt die Unterrichtsdauer 60 Minuten. In dieser Zeit haben die Kinder Zeit, ganz in den Prozess einzutauchen und das Interesse nicht zu verlieren (dies gilt besonders für kleine, aktive Kindergartenkinder).",
          three: "Wir erwarten Sie in unserem Zentrum!",
        },
      },

      modal: {
        title: "Platz reservieren",
        form: {
          name: "Ihren Namen",
          phone: "+380 99 999-99-99",
          error: "Nachricht nicht gesendet! Bitte, bitte füllen Sie alle Felder aus.",
          success: "Nachricht erfolgreich versendet!",
          btn: "Eine Nachricht schicken",
        },
      },
    },
  },

  footer: {
    desc: {
      first: "Seien Sie ehrenamtlich oder helfen Sie als Fachkraft.",
      second: "Drücken Sie den Knopf!",
    },
    btn: "Ich möchte nützlich sein",
    list: {
      title: "Nützliche Links",
      about: "Über unseren Fonds",
      news: "Nachricht",
      contact: "Kontakte",
    },
    info: {
      title: "Weitere Informationen",
      one: "USREOU 44341172",
      two: "UA763003460000026004098334201",
      three: 'in JSC "ALPHA-BANK"',
      four: "MFIs 300346",
      five: "USREOU 23494714",
    },
  },
};
