import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { faCalendarDays, faCheck, faChild } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModalDetailsSchool = (props) => {
  const { t } = useTranslation();
  const basePath = "kindgarden.direc.school.";

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t(basePath + "title.sub")} {t(basePath + "title.main")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="kind ">
        <Row className="align-items-center k-direct">
          <Col lg={12}>
            <div className="sub-info">
              <span>
                <FontAwesomeIcon icon={faChild} />
                {t(basePath + "age")}
              </span>
              <span>
                <FontAwesomeIcon icon={faCalendarDays} />
                {t(basePath + "time")}
              </span>
              <div className="content">
                <p className="f-size-16">{t(basePath + "desc.one")}</p>
                <p className="f-size-16">{t(basePath + "desc.two")}</p>
                <p>{t(basePath + "desc.three")}</p>
                <ul>
                  <li>
                    <FontAwesomeIcon icon={faCheck} />
                    {t(basePath + "list.one")}
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} />
                    {t(basePath + "list.two")}
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} />
                    {t(basePath + "list.three")}
                  </li>
                </ul>
                <p className="f-size-16">{t(basePath + "desc.four")} </p>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalDetailsSchool;
