export const TRANSLATIONS_UA = {
  navBar: {
    site: "Наш сайт",
    link: {
      home: "Головна",
      center: "Дитячий центр",
      about: "Про наш фонд",
      news: "Новини",
      nft: "Галерея NFT",
      contact: "Контакти",
    },

    lang: {
      title: "Мова",
      "en-US": "Англійська",
      "ua-UA": "Українська",
      "es-ES": "Іспанська",
      "fr-FR": "Французький",
      "de-DE": "Німецький",
      "it-IT": "Італійська",
      "pl-PL": "Польський",
      "pt-PT": "Португальська",
    },
  },

  pageBanner: {
    home: "Головна",
    about: "Про наш фонд",
    news: "Новини",
    nft: "Галерея NFT",
    contact: "Контакти",
  },

  copy: {
    copy: "Скопіювати",
    success: "Cкопійовано",
  },

  home: {
    banner: {
      title: {
        first: "БЛАГОДІЙНИЙ ФОНД",
        second: "«ХТО, ЯКЩО НЕ МИ»",
      },
      desc: "Наш фонд «ХТО, ЯКЩО НЕ МИ» несе повну відповідальність за використання зібраних коштів, кожен внесок буде мати пряме призначення. Головне для нас – ваша довіра!",
      btnHelp: "Як можна допомогти",
      btnRequ: "Запросити допомогу",
      btnDonat: "Пожертвувати",
    },

    company: {
      title: 'Благодійний фонд "Хто, якщо не ми"',
      desc: "Діти - це наше майбутнє. Разом, ми можемо подолати все і зробити наше майбутнє здоровим, щасливим та з вірою в свою країну. Благодійний фонд «Хто, якщо не ми» займається організацією збору коштів для допомоги дітям та їх родинам, які потребують цього. Допомога тяжкохворим дітям, а саме оплата лікування та  період реабілітації.",
      descMil: "Постійна закупівля необхідної амуніції для Збройних Сил України.",
      mission: {
        title: "Місія фонду",
        desc: "Дарувати, наскільки це можливо, радість життя дітям і надавати психологічну підтримку батькам які зараз цього потребують.",
      },
      purpose: {
        title: "Мета фонду",
        desc: "Організація дитячих заходів, що впливає на розвиток дітей. Психологічна підтримка дітей та їх батьків. Фінансова та гуманітарна підтримка постраждалих під час війни на території України.",
      },
      military: {
        title: "Підтримка військових",
        desc: "Забезпечення амуніцією та автівками захисників у найгарячіших точках України.",
      },
      widget: {
        title: {
          first: "Разом — ми змінемо світ!",
          second: "Почніть зараз разом з нами!",
        },
      },
      work: {
        disability: "Допомагаємо людям з інвалідністю",
        retirees: "Допомагаємо пенсіонерам",
        orphans: "Допомагаємо дітям-сиротам",
        difficult: "Допомога постраждалим дітям під час війни на території України",
        military: "Забезпечення ЗСУ",
      },
    },

    fees: {
      title: "Коли немає надії, сил і коштів – ми можемо бути поруч!",
      feesContent: [
        {
          title: "650 000 грн.",
          desc: "зібрали для допомоги дітям",
        },
        {
          title: "93 500 грн.",
          desc: "витрачено на програму «Лікуємося з радістю»",
        },
        {
          title: "196 800 грн.",
          desc: "витрачено на програму «Відновлювальна терапія»",
        },
        {
          title: "342 000 грн.",
          desc: "витрачено на програму «Рятуємо життя разом»",
        },
      ],
    },

    our_help: {
      title: "Наша допомога",
      desc: "Ресторан Soul fish відкрив під час війни штаб із допомоги. Допомагають із забезпеченням харчування ЗСУ,поліції та біженців-переселенців «Вже 41 день кожен із нас допомагає, як може. Це все міцна та непереможна енергія, вона нас згуртувала в одне ціле. І завдяки цьому ми рухаємось.",
      btn: "Допомогти",
    },

    donat: {
      title: "Допомога постраждалим дітям під час війни на території України",
      btn: "Пожертвувати",
      btc: {
        title: "BTC",
        modal: {
          header: "BTC",
          title: "Гаманець для пожертвовань BTC",
        },
      },
      usdt: {
        title: "USDT",
        modal: {
          header: "USDT TRC-20",
          title: "Гаманець для пожертвовань USDT TRC-20",
        },
      },
      visa: {
        title: "Visa/MasterCard",
      },
      p2p: {
        title: "Р/Р",
        modal: {
          header: "Розрахунковий рахунок",
        },
      },
    },

    help: {
      title: "Як можна допомогти?",
      desc: "Можливо, у вас є свій спосіб допомоги тій чи іншій сім’ї. Напишіть нам, будь ласка, ми з вами зв’яжемось.",
      form: {
        name: "Ваше ім`я",
        phone: "+380 99 999-99-99",
        select: {
          one: "Оберіть, як ви можете допомогти",
          two: "Грошима",
          three: "Волонтер",
          four: "Спеціалист",
          five: "Своя допомога",
        },
        mass: "Ваше повідомлення",
        error: "Повідомлення не надіслано! Будь ласка, заповніть будь-ласка всі поля.",
        success: "Повідомлення успішно відправлено!",
        btn: "Надіслати повідомлення",
      },
      specialityContent: [
        {
          title: "Як волонтер фонду",
          desc: "Брати участь у проєктах фонду постійно, або одноразово. Тобі повинно бути більш 18-ти років.",
        },
        {
          title: "Як спеціалист",
          desc: "Як лікар для допомоги хворим, як психолог, або інший фахівець.",
        },
        {
          title: "Як інтернет-блогер",
          desc: "Готовий допомагати з розповсюдженням інформації у інтернеті.",
        },
        {
          title: "Як магазин-партнер",
          desc: " Проведення акцій, пов'язаних з речами та товарами первинної необхідності.",
        },
        {
          title: "Добровільна допомога грошима",
          desc: "Ми приймаємо добровільні перекази на картковий рахунок фонду.",
        },
      ],
    },

    request: {
      title: "Запросити допомогу",
      desc: "Напишіть свої контакти, опишіть докладно ситуацію:",
      info: {
        title: "Отримати детальну інформацію ви можете, зв’язавшись з нашим фахівцем:",
        name: "Чернишова Вікторія Олександрівна",
      },
    },

    transfer: {
      title: "Переказ коштів",
      error: "Помилка",
      success: "Успішно",
      thank: "Дякуємо Вам за підтримку дітей України",
    },
  },

  about: {
    info: {
      title: "Благодійний фонд «ХТО, ЯКЩО НЕ МИ»",
      subtitle: "Як все починалось…",
      desc: {
        one: "Благодійний фонд зареєстрований в травні 2021 року, але фактична робота підтримки українців ведеться з 2020 року.",
        two: "Основна мета фонду «Хто, як не ми?» – допомога дітям. Також до наших задач відноситься підтримка людей-инвалідів та пенсіонерів. Наш фонд вірить в те, що щедрість і великодушність – найважливіші людські якості.",
        three:
          "Ми впевнені, що роблячи добро, ми стаємо сильнішими и щасливішими, покращуємо взаємовідносини, створюємо кращий світ. Кожна людина повинна вміти ділитися часом, грошима або енергією. Це надійний спосіб створення гармонії між людьми.",
      },

      war: {
        title: "Війна змінила кожного з нас",
        desc: {
          first: "Фонд «Хто, якщо не ми» продовжує працювати 24/7 аби встигнути допомогти кожному, хто цього потребує.",
          second: {
            main: "Одяг, продукти харчування, засоби особистої гігієни-все це нам відправили із Польщі. Закликаємо всіх долучатися до нашої боротьби з нашим спільним ворогом та долучайтесь до команди! Дякуємо всім, хто вже зробив свій вагомий внесок! Разом ми здатні творити неймовірні речі! Гуртом ми крокуємо до перемоги! Слава Україні!",
            sub: "Перемога буде за нами!",
          },
        },
      },
    },

    what: {
      title: "Що ми робимо",
      item: {
        one: "Допомагаємо людям з інвалідністю",
        two: "Допомагаємо дітям-сирітам",
        three: "Допомагаємо пенсіонерам",
        four: "Допомога постраждалим дітям під час війни на території України",
      },
    },

    history: {
      title: "Наша історія",
      desc: {
        fond: "Благодійний фонд «ХТО, ЯКЩО НЕ МИ» був створений з однією метою – робити людям добро. Ми намагаємося працювати в різних напрямках:",
        believ: "Наш фонд вірить в те, що доброта і щедрість – головні людські якості, які і відділяють нас від тварин.",
        generate:
          "Благодійний фонд «ХТО, ЯКЩО НЕ МИ» створений для об’єднання людей, які мають бажання допомагати. Ми намагаємося робити так, щоби у кожної людини, незалежно від матеріального стану та віку, була можливість допомагати дітям. Наша організація мріє, щоби кожна нещасна дитина мала щасливе та мирне дитинство.",
      },

      list: {
        money: "Фінансова та психологічна підтримка людей, які опинились в критичних ситуаціях;",
        therapy: "Лікування важкохворих дітей;",
        moral: "Підтримка дітей які постраждали під час збройного конфлікту на території України;",
      },
    },
  },

  news: {
    btn: "Читати далі",
    report: "Фотозвіт",
    thanks: "Листи вдячності",
    page: {
      latest: "Останні новини",
      help: {
        title: "Допомога",
        desc: "Бути волонтером, або допомогати як спеціаліст. Тисніть кнопку!",
        btn: "Пожертвувати",
      },
    },
  },

  contact: {
    title: "Контактна інформація",
    address: {
      title: "Адреса",
      desc: "м. Дніпро, вул. Короленка, 8, офис 10",
    },
    mail: {
      title: "Пошта",
    },
    phone: {
      title: "Телефон",
    },
    work: {
      title: "Робочі години",
      desc: "Понеділок – п'ятниця: з 10:00 до 17:00",
    },
  },

  nft: {
    title: "Галерея",
    btn: "Купити",
    price: "Ціна",

    block: {
      title: {
        main: "Команда створила NFT нашого фонду, гроші з продажу йдуть на допомогу ЗСУ України",
        sub: "та людям які залишились без житла.",
      },
    },

    info: {
      title: {
        main: "Лютий 2022 рік в Україну",
        sub: "прийшла війна.",
      },

      desc: {
        war: "Війна, яку розпочала Росія. Усі громадяни нашої країни об'єдналися в одне ціле – у сталевий щит у боротьбі та протистоянні країні, яка вже зламала мільйони доль простих українців.",
        team: "Команда «Хто, якщо не ми», як і тисячі інших благодійних організацій, із першого дня війни бере всю можливу участь у долі та допомозі нашій країні та нашим громадянам.",
        friend:
          "Дорогі друзі та благодійники, ми будемо раді будь-якій посильній допомозі та підтрімці, яку може надати ваша організація.",
      },
      together: "Адже разом ми – Україна",
    },

    nftContent: [
      {
        img: "4",
        cat: "Міста-герої України",
        title: "Ірпінь",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304559263746621540",
      },
      {
        img: "1",
        cat: "Міста-герої України",
        title: "Буча",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304558164234993764",
      },
      {
        img: "7",
        cat: "Міста-герої України",
        title: "Охтирка",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304557064723365988",
      },
      {
        img: "10",
        cat: "Міста-герої України",
        title: "Чернігів",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304553766188482660",
      },
      {
        img: "6",
        cat: "Міста-герої України",
        title: "Миколаїв",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304552666676854884",
      },
      {
        img: "2",
        cat: "Міста-герої України",
        title: "Волноваха",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304551567165227108",
      },
      {
        img: "9",
        cat: "Міста-герої України",
        title: "Херсон",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304550467653599332",
      },
      {
        img: "3",
        cat: "Міста-герої України",
        title: "Гостомель",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304548268630343780",
      },
      {
        img: "8",
        cat: "Міста-герої України",
        title: "Харків",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304547169118716004",
      },
      {
        img: "5",
        cat: "Міста-герої України",
        title: "Маріуполь",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304546069607088228",
      },
    ],
  },

  kindgarden: {
    banner: {
      subtitle: "Наш дитячий центр",
      title: "\"Хто, якщо не ми\" створюють неймовірно творчу казку для дітей у вигляді майстер-класів",
      btn: "Детальніше",
    },

    donat: {
      title: "Будемо вдячні за допомогу",

      switch: {
        one: "Разове",
        monthly: "Щомісяця",
      },

      other: "Інша сума",
      btn: "Пожертвувати",
      btnCrypto: "Пожертвувати в криптовалюті",

      modal: {
        title: "Криптовалюта",
        btc: "Гаманець для пожертвовань BTC",
        usdt: "Гаманець для пожертвовань USDT TRC-20",
      },
    },

    statistic: {
      title: "Щомісяця ми проводимо",
      list: {
        one: "Майстер-класів",
        two: "Занять з підготовки до школи",
        three: "Заняття з живопису",
        four: "Щоміячних благодійних днів",
      },

      items: {
        one: "Постійно видаємо допомогу та підтримуємо малюків які цього потребують.",
        two: "Співпрацюємо з зірковими партнерами які підтримують нашу діяльність.",
        three: "Влаштовуємо соціальні заходи для пітримки кожної дитини яка постаждала від війни.",
      },

      desc: {
        one: "Щомісяця нас відвідує близько",
        two: "щасливих малюків які з нетерпінням чекають наступної зустрічі за нами!",
      }
    },

    direc: {
      title: "Наші напрямки",
      btnMore: "Детальніше",
      btnBook: "Забронювати місце",

      brain: {
        title: {
          sub: "Програма",
          main: "«Brain Fitness»",
        },
        age: "Від 3-х до 4-х років",
        time: "Заняття 2 рази на тиждень, тривалість – 55 хв.",
        desc: {
          one: "Що дитина отримає після занять ?",
          two: "Чому вам потрібно обрати нас ?",
        },
        listFirst: [
          {
            item: "Покращить мову, дізнається нові слова на теми, відповідно до віку;",
          },
          {
            item: "Навчиться рахувати та вивчить літери;",
          },
          {
            item: "Адаптується до колективу, звикне бути у компанії дітей;",
          },
          {
            item: "Привчиться до дисципліни та порядку;",
          },
        ],
        listSecond: [
          {
            item: "Вам не потрібно водити дитину на додаткові гуртки – літери, цифри, малювання, гра на музичних інструментах включені у годинник; ",
          },
          {
            item: "Креативні педагоги готують спеціальні завдання та казки для роботи з літерами, завдяки яким діти легко їх запам'ятовують, а весь процес навчання читанню проходить у грі та без труднощів;",
          },
          {
            item: "Більше 500 корисних іграшок, що розвивають;",
          },
          {
            item: "Понад 10 завдань в одному уроці! Діти не нудьгують, а постійно зайняті;",
          },
          {
            item: "Жодного уроку з абонементу ви не втрачаєте, ми цінуємо ваші гроші і за відсутності дитини пропущений урок відпрацьовується з педагогом;",
          },
        ],
      },

      therapist: {
        title: {
          sub: "Програма",
          main: "«Логопед»",
        },
        time: "Тривалість – 60 хв.",
        desc: {
          one: "У дитячому центрі проходять індивідуальні логопедичні заняття, під час яких логопед ставить і закріплює звуки, вводить їх в активну мову дитини. Діти освоюють гімнастику артикуляції, закріплюють отримані навички.",
          two: "Робота логопеда з дитиною залежить від її мовних особливостей і відбувається за індивідуальним планом. Тривалість корекційного курсу також залежить від складності ситуації.",
          three: "Дуже важливо вчасно помітити та скоригувати такі порушення у розвитку мови, як:",
        },
        listFirst: [
          {
            item: "Невиразна, нечітка мова;",
          },
          {
            item: "Дефекти звуковимови;",
          },
          {
            item: "Бідність словникового запасу;",
          },
          {
            item: "Спотворена вимова складних слів;",
          },
          {
            item: "Дитина додає або пропускає склади;",
          },
          {
            item: "Труднощі при переказі прочитаного тексту;",
          },
          {
            item: "Труднощі заучування вірш;",
          },
          {
            item: "Стійкі аграматизми;",
          },
          {
            item: "Порушення узгодження слів у роді, числі і відмінку;",
          },
          {
            item: "Несформованість фонематичного слуху;",
          },
          {
            item: "Надалі у дитини виникають великі проблеми з грамотністю;",
          },
        ],
      },

      school: {
        title: {
          sub: "Програма",
          main: "«Підготовка до школи»",
        },
        age: "Від 4-х до 6-ти років",
        time: "Заняття 2 або 3 рази на тиждень, тривалість – 55 хв.",
        desc: {
          one: "Заняття групи дозволять легко пройти складний період адаптації. Дитина отримає необхідну підготовку для успішного навчання, почуватиметься спокійно та впевнено на заняттях.",
          two: "Отримані комунативні навички допоможуть легко знайти нових друзів.",
          three: "Для дітей від 5 років років заняття включає три блоки:",
          four: "У багатьох школах введено тестування першокласників прийому до школи. Під час наших занять діти вивчають весь перелік питань, які ставляться на співбесіді майбутньому першокласнику.",
        },
        list: {
          one: "Читання - письмо;",
          two: "Математика - Логіка;",
          three: "Розвиток мовлення;",
        },
      },

      drawing: {
        title: {
          sub: "Програма",
          main: "«Творчість не має меж»",
        },
        age: "Проведено більше 200 майстер-класів",
        time: "Відвідало понад 700 дітей",
        desc: {
          one: "Ми проводимо кулінарні майстер-класи з усіх видів Мило власного виробництва та створюємо цукеркові.",
          two: "В кінці кожного заходу, ви забираєте з собою свою роботу — пряник ручної роботи чи маршмеллоу, мило чи солодкі витвори своїми руками. Ваші шедеври стануть приємними подарунками родичам та друзям, або прикрасять полиці у вашій власній оселі. Можливо, хтось відкриє в собі нові можливості і зробить творчість своєю роботою.",
        },
      },

      vocal: {
        title: {
          sub: "Програма",
          main: "«Уроки вокалу»",
        },
        time: "Неділя о 11:00",
        desc: {
          one: "У нас працюють досвідчені педагоги, які знають, як розкрити творчий потенціал і артистичність кожного малюка. Адже всі дітки індивідуальні.",
          two: "Ми розробили унікальні програми навчання. На заняттях з вокалу ми вчимо дітей правильно дихати під час співу, управляти своїм голосом, щоб видавати необхідні звуки. Розширюємо діапазон голосу, зміцнюємо голосові зв’язки, що важливо для діток. Адже їх голосовий апарат відрізняється від дорослого, піддається великим навантаженням і вдеяких випадках вони можуть скаржитися на болі, подразнення у горлі, захриплість.",
          three: "На уроках з вокалу для дітей ми знайомимо малюків з різними музичними стилями, прищеплюємо смак, вчимо відчувати пісні, висловлювати свої емоції. Працюємо над бар’єрами, сором’язливістю, вчимо розспівуватися, підбираємо музичний матеріал (естрадний, джазовий, академічний), відточуємо майстерність вокального виконання, ставимо концерти. Дитина може спробувати себе в ролі вокаліста, а також учасника ансамблю.",
          four: "Запрошуємо відвідати наші індивідуальні уроки вокалу для дітей. Ми допоможемо розкрити таланти кожного малюка і зробити їх ще трохи щасливішими! Вартість занять вказана на сайті.",
        },
      },

      art: {
        title: {
          sub: "Програма",
          main: "«Художня студия»",
        },
        age: "Від 3-х до 16-ти років",
        time: "Тривалість – 60 хв.",
        desc: {
          one: "Малюнками дитини завішаний весь холодильник? Знаємо, як перетворити їх на шедеври! Уже чекаємо на малюка у нашій студії живопису для дітей, де творчість живе в кожному куточку! Хто сказав, що академічний малюнок – це складно? Наша педагог-художниця закохана у свою справу і переконана, що малювати може кожний. Вона навчить дітей, як оживити на мольберті найсміливіші фантазії та познайомить з різними техніками живопису.",
          two: "Живопис для дітей розрахований на різні вікові групи – від 3 років і до 16. Для кожної групи розроблена своя навчальна програма. В середньому тривалість заняття – 60 хвилин. За цей час дітки встигають повністю зануритися в процес і не втратити зацікавленість (особливо це стосується маленьких, активних кіндерів).",
          three: "Чекаємо вас в нашому центрі!",
        },
      },

      modal: {
        title: "Бронювання місця",
        form: {
          name: "Ваше ім`я",
          phone: "+380 99 999-99-99",
          error: "Повідомлення не надіслано! Будь ласка, заповніть будь-ласка всі поля.",
          success: "Повідомлення успішно відправлено!",
          btn: "Надіслати повідомлення",
        },
      },
    },
  },

  footer: {
    desc: {
      first: "Бути волонтером, або допомогати як спеціаліст.",
      second: "Тисніть кнопку!",
    },
    btn: "Хочу бути корисним",
    list: {
      title: "Корисні посилання",
      about: "Про наш фонд",
      news: "Новини",
      contact: "Контакти",
    },
    info: {
      title: "Додаткова інформація",
      one: "ЄДРПОУ 44341172",
      two: "п/р UA763003460000026004098334201",
      three: "в АТ «АЛЬФА-БАНК»",
      four: "МФО 300346",
      five: "ЄДРПОУ 23494714",
    },
  },
};
