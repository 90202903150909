import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";

const Info = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "about.info.";

  return (
    <section className="company">
      <Container>
        <Row className="align-items-center mb-70">
          <Col lg={6} className="info">
            <h3>
              <img src={require("../../assets/img/logo-icon.png").default} alt="" />
              {t(basePath + "title")}
            </h3>

            <h4>{t(basePath + "subtitle")}</h4>

            <p>{t(basePath + "desc.one")}</p>
            <p>{t(basePath + "desc.two")}</p>
            <p>{t(basePath + "desc.three")}</p>
          </Col>
          <Col lg={6}>
            <div className="widget">
              <img src={require("../../assets/img/widget-3.png").default} alt="" />
              <img src={require("../../assets/img/widget-4.png").default} alt="" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="order-info-2">
            <div className="video">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/8L1kEwB8wrs"
                title="Video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </Col>
          <Col lg={6} className="info">
            <h4>{t(basePath + "war.title")}</h4>
            <p>{t(basePath + "war.desc.first")}</p>
            <p>
              {t(basePath + "war.desc.second.main")}{" "}
              <img className="flag" src={require("../../assets/img/flags/ua.png").default} alt="" />
              {t(basePath + "war.desc.second.sub")}
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Info;
