import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { send } from "emailjs-com";
import { faCheckCircle, faPalette, faPaperPlane, faPhone, faTimesCircle, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModalOrderTherapist = (props) => {
  // Translation
  const { t } = useTranslation();
  const basePath = "kindgarden.direc.";

  const [defaultValues, setDefaultValues] = useState({
    name: "",
    phone: "",
  });

  const handleChange = (e) => {
    setDefaultValues({ ...defaultValues, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    if (defaultValues.name === "" || defaultValues.phone === "") {
      document.querySelector(".error").classList.add("active");
      document.querySelector(".success").classList.remove("active");
    } else {
      send("service_yt7ukk4", "template_btzwfiq", defaultValues, "KwLPLw6G84xmWMfAa").then(
        (result) => {
          document.querySelector(".error").classList.remove("active");
          document.querySelector(".success").classList.add("active");
        },
        (error) => {
          console.log(error.text);
        }
      );
    }
  };

  useEffect(() => {
    setDefaultValues({
      ...defaultValues,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{t(basePath + "modal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-order">
        <h5>
          <FontAwesomeIcon icon={faPalette} />
          {t(basePath + "therapist.title.sub")} <span>{t(basePath + "therapist.title.main")}</span>
        </h5>
        <Form>
          <Row>
            <Form.Group as={Col} md="12">
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">
                  <FontAwesomeIcon icon={faUser} />
                </InputGroup.Text>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder={t(basePath + "modal.form.name")}
                  value={defaultValues.name}
                  onChange={handleChange}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col} md="12">
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">
                  <FontAwesomeIcon icon={faPhone} />
                </InputGroup.Text>
                <Form.Control
                  name="phone"
                  type="number"
                  value={defaultValues.phone}
                  onChange={handleChange}
                  placeholder={t(basePath + "modal.form.phone")}
                />
              </InputGroup>
            </Form.Group>

            <div className="valid">
              <span className="invalid-feedback error">
                <FontAwesomeIcon icon={faTimesCircle} />
                {t(basePath + "modal.form.error")}
              </span>
              <span className="invalid-feedback success">
                <FontAwesomeIcon icon={faCheckCircle} />
                {t(basePath + "modal.form.success")}
              </span>
            </div>

            <div className="d-flex justify-content-center mt-30">
              <Button onClick={onSubmit} className="btn-st">
                <FontAwesomeIcon icon={faPaperPlane} />
                {t(basePath + "modal.form.btn")}
              </Button>
            </div>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalOrderTherapist;
