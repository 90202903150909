import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingMedical } from "@fortawesome/free-solid-svg-icons";
import { faInstagramSquare, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "footer.";

  return (
    <footer>
      <Container>
        <Row>
          <Col lg={4} md={6}>
            <div className="left-side">
              <img src={require("../assets/img/logo.png").default} className="logo" alt="" />
              <p>
                {t(basePath + "desc.first")} <br /> {t(basePath + "desc.second")}
              </p>
              <div className="d-flex">
                <Button href="#help" className="btn-st">
                  <FontAwesomeIcon icon={faHandHoldingMedical} />
                  {t(basePath + "btn")}
                </Button>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className="center-side">
              <h5>{t(basePath + "list.title")}</h5>
              <ul>
                <li>
                  <Link to="/about">{t(basePath + "list.about")}</Link>
                </li>
                <li>
                  <Link to="/news">{t(basePath + "list.news")}</Link>
                </li>
                <li>
                  <Link to="/contact">{t(basePath + "list.contact")}</Link>
                </li>
              </ul>
              <div className="social">
                <a href="https://www.instagram.com/hto_yaksho_ne_my/">
                  <FontAwesomeIcon icon={faInstagramSquare} />
                </a>
                <a href="https://www.facebook.com/HYNMDnipro">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              </div>
            </div>
          </Col>
          <Col lg={4}  md={12}>
            <div className="right-side">
              <h5>{t(basePath + "info.title")}</h5>
              <p>{t(basePath + "info.one")}</p>
              <p>{t(basePath + "info.two")}</p>
              <p>{t(basePath + "info.three")}</p>
              <p>
                {t(basePath + "info.four")} | {t(basePath + "info.five")}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
