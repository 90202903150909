import React from "react";
import PageBanner from "../components/PageBanner";
import ContactComponents from "../components/Contact";
import Footer from "../components/Footer";

const Contact = () => {
  return (
    <>
      <PageBanner />
      <ContactComponents />
      <Footer />
    </>
  );
};

export default Contact;
