export const TRANSLATIONS_ES = {
  navBar: {
    site: "Nuestro sitio web",
    btnHelp: "CÓMO PUEDES AYUDAR",
    btnRequ: "PEDIR AYUDA",

    link: {
      home: "Casa",
      center: "Centro infantil",
      about: "Sobre nuestro fondo",
      news: "Noticias",
      nft: "Galería NFT",
      contact: "Contactos",
    },

    lang: {
      title: "Idioma",
      "en-US": "inglés",
      "ua-UA": "ucranio",
      "es-ES": "español",
      "fr-FR": "francés",
      "de-DE": "alemán",
      "it-IT": "italiano",
      "pl-PL": "polaco",
      "pt-PT": "portugués",
    },
  },

  pageBanner: {
    home: "Casa",
    about: "Sobre nuestro fondo",
    news: "Noticias",
    nft: "Galería NFT",
    contact: "Contactos",
  },

  copy: {
    copy: "Dupdo",
    success: "copiado",
  },

  home: {
    banner: {
      title: {
        first: "FUNDACIÓN CARITATIVA",
        second: '"QUIÉN, SI NO NOSOTROS"',
      },
      desc: 'Nuestro fondo "QUIÉN SI NO NOSOTROS" es totalmente responsable del uso de los fondos recaudados, cada aporte tendrá un fin directo. ¡Lo principal para nosotros es su confianza!',
      btnHelp: "Como puedes ayudar",
      btnRequ: "Pedir ayuda",
      btnDonat: "Sacrificio",
    },

    company: {
      title: "Fundación Benéfica Quién Si No Nosotros",
      desc: "Los niños son nuestro futuro. Juntos podemos superar todo y hacer nuestro futuro saludable, feliz y con fe en nuestro país. La fundación benéfica Who If Not Us organiza la recaudación de fondos para ayudar a los niños necesitados y sus familias. Asistencia a niños gravemente enfermos, a saber, pago del período de tratamiento y rehabilitación.",
      descMil: "Adquisición continua de munición necesaria para las Fuerzas Armadas de Ucrania.",
      mission: {
        title: "La misión de la Fundación",
        desc: "Dar tanto como sea posible la alegría de vivir a los niños y brindar apoyo psicológico a los padres que lo necesitan ahora.",
      },
      purpose: {
        title: "Objetivo del fondo",
        desc: "Organización de actividades infantiles que inciden en el desarrollo infantil. Apoyo psicológico a los niños y sus padres. Apoyo financiero y humanitario a las víctimas del conflicto armado en Ucrania.",
      },
      military: {
        title: "Apoyo militar",
        desc: "Suministro de municiones y automóviles a los defensores en los puntos más calientes de Ucrania.",
      },
      widget: {
        title: {
          first: "¡Juntos, cambiaremos el mundo!",
          second: "¡Comienza con nosotros ahora!",
        },
      },
      work: {
        disability: "Ayudamos a las personas con discapacidad",
        retirees: "Ayudamos a los jubilados",
        orphans: "Ayudamos a los huérfanos",
        difficult: "Ayudamos a los niños afectados durante el conflicto armado en Ucrania",
        military: "Provisión de las Fuerzas Armadas",
      },
    },

    fees: {
      title: "Cuando no hay esperanza, ni fuerza, ni dinero... ¡podemos estar ahí para ti!",
      feesContent: [
        {
          title: "650 000 UAH.",
          desc: "recaudado para ayudar a los niños",
        },
        {
          title: "93 500 UAH.",
          desc: 'gastado en el programa "Sanar con alegría"',
        },
        {
          title: "196 800 UAH.",
          desc: 'gastado en el programa "Terapia de recuperación"',
        },
        {
          title: "342 000 UAH.",
          desc: 'gastado en el programa "Salvar nuestra vida juntos"',
        },
      ],
    },

    our_help: {
      title: "Nuestra ayuda",
      desc: "El restaurante de pescado Soul abrió un cuartel general de socorro durante la guerra. Ayudan con alimentos para las Fuerzas Armadas, la policía y los refugiados-desplazados “Desde hace 41 días, cada uno de nosotros ayuda en lo que puede. Todo esto es energía fuerte e invencible, nos unió en un todo. Y gracias a eso nos estamos moviendo.",
      btn: "Ayudar",
    },

    donat: {
      title: "Asistencia a los niños heridos durante la guerra en el territorio de Ucrania",
      btn: "Donar",
      btc: {
        title: "BTC",
        modal: {
          header: "BTC",
          title: "Monedero para donaciones BTC",
        },
      },
      usdt: {
        title: "USDT",
        modal: {
          header: "USDT TRC-20",
          title: "Monedero para donaciones USDT TRC-20",
        },
      },
      visa: {
        title: "Visa/MasterCard",
      },
      p2p: {
        title: "Р/Р",
        modal: {
          header: "Cuenta actual",
        },
      },
    },

    help: {
      title: "¿Cómo podemos ayudar?",
      desc: "Tal vez tenga una forma de ayudar a una familia en particular. Escríbanos y nos pondremos en contacto con usted.",
      form: {
        name: "Su nombre",
        phone: "+380 99 999-99-99",
        select: {
          one: "Elige cómo puedes ayudar",
          two: "Dinero",
          three: "Voluntario",
          four: "Especialista",
          five: "Nuestra ayuda",
        },
        mass: "Tu mensaje",
        error: "¡Mensaje no enviado! Porfavor llene todos los campos.",
        success: "¡Mensaje enviado con éxito!",
        btn: "Enviar mensaje",
      },
      specialityContent: [
        {
          title: "Como voluntario de la Fundación",
          desc: "Debe poder participar en los proyectos de la fundación de forma permanente o puntual. Debes tener al menos 18 años.",
        },
        {
          title: "Como especialista",
          desc: "Como médico para ayudar a los enfermos, como psicólogo u otro especialista.",
        },
        {
          title: "Como blogger de internet",
          desc: "Listo para ayudar con la difusión de información en Internet.",
        },
        {
          title: "Como tienda asociada",
          desc: "Realización de promociones relacionadas con cosas y necesidades básicas.",
        },
        {
          title: "Ayuda voluntaria con dinero",
          desc: "Aceptamos transferencias voluntarias a la cuenta de la tarjeta de la Fundación.",
        },
      ],
    },

    request: {
      title: "Pedir ayuda",
      desc: "Escriba sus contactos, describa la situación en detalle:",
      info: {
        title: "Puede obtener información detallada contactando a nuestro especialista:",
        name: "Malajova Angelina Konstantinovna",
      },
    },

    transfer: {
      title: "Transferencia de dinero",
      error: "Error",
      success: "Exitosamente",
      thank: "Gracias por el apoyo a los niños de Ucrania.",
    },
  },

  about: {
    info: {
      title: "QUIÉN, SI NO NOSOTROS Fundación Benéfica",
      subtitle: "Cómo empezó todo…",
      desc: {
        one: "El fondo de caridad se registró en mayo de 2021, pero el trabajo real de apoyo a los ucranianos ha estado en marcha desde 2020.",
        two: 'El objetivo principal del fondo "¿Quién sino nosotros?" - ayuda para los niños. Nuestras tareas también incluyen el apoyo a personas con discapacidad y jubilados. Nuestra fundación cree que la generosidad y la generosidad son las cualidades humanas más importantes.',
        three:
          "Estamos convencidos de que haciendo el bien nos volvemos más fuertes y felices, mejoramos las relaciones, creamos un mundo mejor. Todo el mundo debería poder compartir tiempo, dinero o energía. Esta es una forma confiable de crear armonía entre las personas.",
      },

      war: {
        title: "La guerra nos cambió a cada uno de nosotros.",
        desc: {
          first:
            "La Fundación Who If Not Us continúa trabajando las 24 horas del día, los 7 días de la semana para ayudar a cualquier persona que lo necesite.",
          second: {
            main: "Desde Polonia nos enviaban ropa, comida, productos de higiene personal. ¡Animamos a todos a unirse a nuestra lucha contra nuestro enemigo común y unirse al equipo! ¡Gracias a todos los que ya han hecho una contribución significativa! ¡Juntos somos capaces de crear cosas increíbles! ¡Juntos marchamos hacia la victoria! ¡Gloria a Ucrania!",
            sub: "¡La victoria será nuestra!",
          },
        },
      },
    },

    what: {
      title: "lo que hacemos",
      item: {
        one: "Ayudamos a las personas con discapacidad",
        two: "Ayudamos a los huérfanos",
        three: "Ayudamos a los jubilados",
        four: "Ayudamos a los niños afectados durante el conflicto armado en Ucrania",
      },
    },

    history: {
      title: "Nuestra historia",
      desc: {
        fond: "La fundación benéfica WHO IF NOT WE se estableció con el único propósito de hacer el bien a las personas. Intentamos trabajar en diferentes direcciones:",
        believ:
          "Nuestra fundación cree que la amabilidad y la generosidad son las principales cualidades humanas que nos diferencian de los animales.",
        generate:
          "La fundación benéfica WHO IF NOT fue creada para reunir a personas que están dispuestas a ayudar. Tratamos de asegurarnos de que todos, independientemente de su estado financiero y edad, tengan la oportunidad de ayudar a los niños. Nuestra organización quiere que todos los niños infelices tengan una infancia feliz y pacífica.",
      },

      list: {
        money: "Apoyo económico y psicológico a personas en situaciones críticas;",
        therapy: "Tratamiento de niños gravemente enfermos;",
        moral:
          "Apoyo a los niños afectados por el conflicto armado en Ucrania;",
      },
    },
  },

  news: {
    btn: "Lee mas",
    report: "reportaje fotográfico",
    thanks: "cartas de agradecimientoі",
    page: {
      latest: "Últimas noticias",
      help: {
        title: "Ayudar",
        desc: "Sea voluntario, o ayude como especialista. ¡Presiona el botón!",
        btn: "Sacrificio",
      },
    },
  },

  contact: {
    title: "Información del contacto",
    address: {
      title: "Dirección",
      desc: "Dnipro, calle Korolenka, 8, oficina 10",
    },
    mail: {
      title: "Correo",
    },
    phone: {
      title: "Teléfono",
    },
    work: {
      title: "Horas Laborales",
      desc: "Lunes a Viernes: 10:00 a 17:00",
    },
  },

  nft: {
    title: "Galería",
    btn: "Comprar",
    price: "Precio",

    block: {
      title: {
        main: "El equipo creó el NFT de nuestro fondo, el dinero de la venta se destina a ayudar a las Fuerzas Armadas de Ucrania.",
        sub: "y personas sin hogar.",
      },
    },

    info: {
      title: {
        main: "Febrero 2022 a Ucrania",
        sub: "vino la guerra.",
      },

      desc: {
        war: "La guerra iniciada por Rusia. Todos los ciudadanos de nuestro país se han unido en un todo, en un escudo de acero en la lucha y el enfrentamiento de un país que ya ha derrotado a millones de ucranianos comunes.",
        team: 'El equipo "Quién, si no nosotros", como miles de otras organizaciones benéficas, desde el primer día de la guerra toma todas las partes posibles en el destino y la asistencia a nuestro país y nuestros ciudadanos.',
        friend: "Estimados amigos y filántropos, esperamos cualquier ayuda y apoyo que su organización pueda brindar.",
      },
      together: "Después de todo, juntos somos Ucrania.",
    },

    nftContent: [
      {
        img: "4",
        cat: "Ciudades heroicas de Ucrania",
        title: "Irpen",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304559263746621540",
      },
      {
        img: "1",
        cat: "Ciudades heroicas de Ucrania",
        title: "Bucha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304558164234993764",
      },
      {
        img: "7",
        cat: "Ciudades heroicas de Ucrania",
        title: "Okhtirka",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304557064723365988",
      },
      {
        img: "10",
        cat: "Ciudades heroicas de Ucrania",
        title: "Chernihiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304553766188482660",
      },
      {
        img: "6",
        cat: "Ciudades heroicas de Ucrania",
        title: "Mykolayiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304552666676854884",
      },
      {
        img: "2",
        cat: "Ciudades heroicas de Ucrania",
        title: "Volnovakha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304551567165227108",
      },
      {
        img: "9",
        cat: "Ciudades heroicas de Ucrania",
        title: "Kherson",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304550467653599332",
      },
      {
        img: "3",
        cat: "Ciudades heroicas de Ucrania",
        title: "Gostomel",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304548268630343780",
      },
      {
        img: "8",
        cat: "Ciudades heroicas de Ucrania",
        title: "Kharkiv",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304547169118716004",
      },
      {
        img: "5",
        cat: "Ciudades heroicas de Ucrania",
        title: "Mariupol",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304546069607088228",
      },
    ],
  },

  kindgarden: {
    banner: {
      subtitle: "Nuestro centro infantil",
      title: "\"Quién, si no nosotros\" , crea un cuento de hadas increíblemente creativo para niños en forma de clases magistrales?",
      btn: "Más detalles",
    },

    donat: {
      title: "Estaremos agradecidos por su ayuda.",

      switch: {
        one: "Una vez",
        monthly: "Mensual",
      },

      other: "Otra cantidad",
      btn: "Donar",
      btnCrypto: "Donar en criptomoneda",

      modal: {
        title: "Criptomoneda",
        btc: "Monedero de donación BTC",
        usdt: "Cartera de donación USDT TRC-20",
      },
    },

    statistic: {
      title: "Cada mes que gastamos",
      list: {
        one: "Clases magistrales",
        two: "Clases de preparacion escolar",
        three: "Clases de pintura",
        four: "Jornadas mensuales de caridad",
      },

      items: {
        one: "Constantemente damos ayuda y apoyamos a los niños que lo necesitan.",
        two: "Cooperamos con socios estrella que apoyan nuestras actividades.",
        three: "Organizamos eventos sociales para apoyar a todos los niños que sufrieron la guerra.",
      },

      desc: {
        one: "¡Todos los meses recibimos la visita de unos",
        two: "niños felices que esperan con ansias la próxima reunión con nosotros!",
      }
    },

    direc: {
      title: "Nuestras direcciones",
      btnMore: "Más detalles",
      btnBook: "Reserva un lugar",

      brain: {
        title: {
          sub: "Programa",
          main: "«Aptitud cerebral»",
        },
        age: "De 3 a 4 años",
        time: "Clases 2 veces por semana, duración – 55 minutos.",
        desc: {
          one: "¿Qué obtendrá el niño después de las clases?",
          two: "Porque nos deberias elegir?",
        },
        listFirst: [
          {
            item: "Mejorar el lenguaje, aprender nuevas palabras sobre temas, según la edad;",
          },
          {
            item: "Aprenderá a contar y aprenderá letras;",
          },
          {
            item: "Se adapta al equipo, se acostumbra a estar en compañía de los niños;",
          },
          {
            item: "Acostúmbrate a la disciplina y al orden;",
          },
        ],
        listSecond: [
          {
            item: "No es necesario que lleve a su hijo a clases adicionales: el reloj incluye letras, números, dibujos y tocar instrumentos musicales; ",
          },
          {
            item: "Los maestros creativos preparan tareas especiales y cuentos de hadas para trabajar con letras, gracias a los cuales los niños los recuerdan fácilmente y todo el proceso de aprender a leer se lleva a cabo en un juego y sin dificultades;",
          },
          {
            item: "Más de 500 útiles juguetes educativos;",
          },
          {
            item: "¡Más de 10 tareas en una lección! Los niños no están aburridos, sino constantemente ocupados;",
          },
          {
            item: "No se pierde una sola lección con la suscripción, apreciamos su dinero y en ausencia del niño, la lección perdida se resolverá con el maestro;",
          },
        ],
      },

      therapist: {
        title: {
          sub: "Programa",
          main: "«Logopeda»",
        },
        time: "Duración – 60 minutos.",
        desc: {
          one: "En el centro infantil, se llevan a cabo clases individuales de terapia del habla, durante las cuales el terapeuta del habla establece y corrige los sonidos y los introduce en el lenguaje activo del niño. Los niños dominan la gimnasia de articulación, consolidan las habilidades adquiridas.",
          two: "El trabajo de un terapeuta del habla con un niño depende de sus características lingüísticas y se lleva a cabo de acuerdo con un plan individual. La duración del curso correctivo también depende de la complejidad de la situación.",
          three: "Es muy importante notar y corregir tales trastornos en el desarrollo del lenguaje a tiempo como:",
        },
        listFirst: [
          {
            item: "Habla poco clara, poco clara;",
          },
          {
            item: "Defectos del habla;",
          },
          {
            item: "Pobre vocabulario;",
          },
          {
            item: "Pronunciación distorsionada de palabras complejas;",
          },
          {
            item: "El niño agrega u omite sílabas;",
          },
          {
            item: "Dificultades en la traducción del texto leído;",
          },
          {
            item: "Dificultades para memorizar poemas;",
          },
          {
            item: "Agramatismos estables;",
          },
          {
            item: "Violación del acuerdo de palabras en género, número y caso;",
          },
          {
            item: "No formación de la audición fonémica;",
          },
          {
            item: "En el futuro, el niño tiene grandes problemas con la alfabetización;",
          },
        ],
      },

      school: {
        title: {
          sub: "Programa",
          main: "«Preparación para la escuela»",
        },
        age: "De 4 a 6 años",
        time: "Lecciones 2 o 3 veces por semana, duración - 55 minutos.",
        desc: {
          one: "Las clases en grupo facilitarán el paso por un difícil período de adaptación. El niño recibirá la preparación necesaria para un aprendizaje exitoso, se sentirá tranquilo y confiado en las clases.",
          two: "Las habilidades de comunicación adquiridas te ayudarán a encontrar fácilmente nuevos amigos.",
          three: "Para niños a partir de 5 años, la clase incluye tres bloques:",
          four: "En muchas escuelas se han introducido pruebas de admisión a la escuela para los alumnos de primer grado. Durante nuestras clases, los niños estudian la lista completa de preguntas que se hacen en una entrevista a un futuro alumno de primer grado.",
        },
        list: {
          one: "Leyendo - Escribiendo;",
          two: "Matemáticas - Lógica;",
          three: "Desarrollo del habla;",
        },
      },

      drawing: {
        title: {
          sub: "Programa",
          main: "«La creatividad no tiene límites»",
        },
        age: "Se han realizado más de 200 clases magistrales",
        time: "Más de 700 niños visitaron",
        desc: {
          one: "Realizamos clases magistrales culinarias sobre todos los tipos de jabón de nuestra propia producción y creamos dulces.",
          two: "Al final de cada evento, te llevas tu trabajo: un pan de jengibre hecho a mano o malvavisco, jabón o dulces creaciones con tus propias manos. Sus obras maestras se convertirán en agradables regalos para familiares y amigos, o decorarán los estantes de su propia casa. Tal vez alguien descubra nuevas oportunidades y haga de la creatividad su trabajo.",
        },
      },

      vocal: {
        title: {
          sub: "Programa",
          main: "«Lecciones vocales»",
        },
        time: "Domingo a las 11:00",
        desc: {
          one: "Contamos con maestros experimentados que saben cómo revelar el potencial creativo y el arte de cada niño. Después de todo, todos los niños son individuales.",
          two: "Hemos desarrollado programas de formación únicos. En las clases de canto enseñamos a los niños a respirar correctamente mientras cantan, a controlar su voz para producir los sonidos necesarios. Ampliamos el rango de la voz, fortalecemos las cuerdas vocales, lo cual es importante para los niños. Después de todo, su aparato vocal es diferente al de un adulto, está expuesto a cargas pesadas y, en algunos casos, pueden quejarse de dolor, irritación de garganta y ronquera.",
          three: "En las lecciones de canto para niños, presentamos a los niños diferentes estilos musicales, inculcamos el gusto, les enseñamos a sentir canciones, expresar sus emociones. Trabajamos barreras, timidez, aprendemos a cantar, seleccionamos material musical (pop, jazz, académico), perfeccionamos la habilidad de interpretación vocal, conciertos escénicos. Un niño puede probarse a sí mismo como vocalista, así como también como miembro de un conjunto.",
          four: "Te invitamos a visitar nuestras clases individuales de canto para niños. ¡Ayudaremos a revelar los talentos de cada niño y hacerlos un poco más felices! El costo de las clases se indica en el sitio web.",
        },
      },

      art: {
        title: {
          sub: "Programa",
          main: "«Estudio de arte»",
        },
        age: "De 3 a 16 años",
        time: "Duración – 60 minutos.",
        desc: {
          one: "¿Está todo el refrigerador cubierto con dibujos de niños? ¡Sabemos cómo convertirlos en obras maestras! ¡Ya estamos esperando al bebé en nuestro estudio de pintura para niños, donde la creatividad habita en cada rincón! ¿Quién dijo que el dibujo académico es difícil? Nuestra maestra-artista está enamorada de su trabajo y está convencida de que cualquiera puede dibujar. Enseñará a los niños cómo hacer realidad sus fantasías más salvajes en un caballete y les presentará diversas técnicas de pintura.",
          two: "La pintura para niños está diseñada para diferentes grupos de edad, de 3 a 16 años. Cada grupo tiene su propio programa educativo. En promedio, la duración de la lección es de 60 minutos. Durante este tiempo, los niños tienen tiempo para sumergirse por completo en el proceso y no perder el interés (esto es especialmente cierto para los pequeños y activos niños de kindergarten).",
          three: "¡Te esperamos en nuestro centro!",
        },
      },

      modal: {
        title: "Reservar un lugar",
        form: {
          name: "Su nombre",
          phone: "+380 99 999-99-99",
          error: "¡Mensaje no enviado! Por favor, rellene todos los campos.",
          success: "¡Mensaje enviado con exito!",
          btn: "Enviar un mensaje",
        },
      },
    },
  },

  footer: {
    desc: {
      first: "Sea voluntario, o ayude como especialista.",
      second: "¡Presiona el botón!",
    },
    btn: "Quiero ser útil",
    list: {
      title: "Enlaces útiles",
      about: "Sobre nuestro fondo",
      news: "Noticias",
      contact: "Contactos",
    },
    info: {
      title: "información adicional",
      one: "USREOU 44341172",
      two: "UA763003460000026004098334201",
      three: 'en JSC "ALFA-BANCO"',
      four: "IMF 300346",
      five: "USREOU 23494714",
    },
  },
};
