import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faHandHoldingDollar, faMedkit, faUsers } from "@fortawesome/free-solid-svg-icons";

const History = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "about.history.";

  const settings = {
    dots: false,
    arrows: true,
    speed: 800,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const sliderItem = [
    { img: require("../../assets/img/history/ph-1.jpg") },
    { img: require("../../assets/img/history/ph-7.jpg") },
    { img: require("../../assets/img/history/ph-8.jpg") },
    { img: require("../../assets/img/history/ph-4.jpg") },
    { img: require("../../assets/img/history/ph-5.jpg") },
    { img: require("../../assets/img/history/ph-6.jpg") },
    { img: require("../../assets/img/history/ph-2.jpg") },
    { img: require("../../assets/img/history/ph-3.jpg") },
  ];

  return (
    <div className="history">
      <Container>
        <Row className="align-items-center">
          <Col lg={12}>
            <h2 className="title">
              <FontAwesomeIcon icon={faBook} />
              {t(basePath + "title")}
            </h2>
          </Col>
          <Col lg={6}>
            <p className="text">{t(basePath + "desc.fond")}</p>
            <ul>
              <li>
                <FontAwesomeIcon icon={faHandHoldingDollar} />
                {t(basePath + "list.money")}
              </li>
              <li>
                <FontAwesomeIcon icon={faMedkit} />
                {t(basePath + "list.therapy")}
              </li>
              <li>
                <FontAwesomeIcon icon={faUsers} />
                {t(basePath + "list.moral")}
              </li>
            </ul>
            <p>{t(basePath + "desc.believ")}</p>
            <p>{t(basePath + "desc.generate")}</p>
          </Col>
          <Col lg={6} className="slider">
            <Row>
              <Slider {...settings}>
                {sliderItem.map((item, i) => (
                  <Col lg={3} md={6} key={i} className="mb-30">
                    <div className="slid" key={i}>
                      <div className="item">
                        <div
                          className="img"
                          style={{
                            backgroundImage: `url(${item.img.default})`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Slider>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default History;
