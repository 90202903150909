import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { faSchool } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Brain from "./Direct/Brain";
import Therapist from "./Direct/Therapist";
import School from "./Direct/School";
import Drawing from "./Direct/Drawing";
import Vocals from "./Direct/Vocals";
import Art from "./Direct/Art";

function Directions(props) {
  // Translation
  const { t } = useTranslation();
  const basePath = "kindgarden.direc.";

  return (
    <section id="direct" className="k-direct">
      <Container>
        <Row>
          <Col lg={12}>
            <h3 className="title">
              <FontAwesomeIcon icon={faSchool} />
              {t(basePath + "title")}
            </h3>
          </Col>
        </Row>

        {/* Brain Fitness
        <Brain /> */}

        {/* Speech Therapist */}
        <Therapist />

        {/* Preparation School */}
        <School />

        {/* Drawing */}
        <Drawing />

        {/* Vocals */}
        {/* <Vocals /> */}

        {/* Art Studio */}
        {/* <Art /> */}
      </Container>
    </section>
  );
}

export default Directions;
