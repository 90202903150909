import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faIcons, faImages, faWallet } from "@fortawesome/free-solid-svg-icons";

import PageBanner from "../components/PageBanner";
import Footer from "../components/Footer";

const NFT = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "nft.";

  let nftContent = [].concat(t(basePath + "nftContent", { returnObjects: true }));

  return (
    <>
      <PageBanner />
      <section className="nft">
        <Container>
          <Row className="align-items-center">
            <Col lg={5}>
              <div className="block">
                <h6>
                  {t(basePath + "block.title.main")}
                  <img className="flag" src={require("../assets/img/flags/ua.png").default} alt="" />{" "}
                  {t(basePath + "block.title.sub")}
                </h6>
                <img className="img" src={require("../assets/img/icon/opensea.svg").default} alt="" />
                <div className="btn-groups">
                  <Button href="https://opensea.io/CF_Who_if_not_us" className="btn-st">
                    <FontAwesomeIcon icon={faIcons} />
                    OpenSea
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={7}>
              <div className="info">
                <h4 className="mb-30">
                  {t(basePath + "info.title.main")}{" "}
                  <img className="flag" src={require("../assets/img/flags/ua.png").default} alt="" />
                  {t(basePath + "info.title.sub")}
                </h4>
                <p>{t(basePath + "info.desc.war")}</p>
                <p>{t(basePath + "info.desc.team")}</p>
                <p>{t(basePath + "info.desc.friend")}</p>
                <h4 className="text-center">
                  {t(basePath + "info.together")}
                  <FontAwesomeIcon icon={faHeart} />
                </h4>
              </div>
            </Col>
          </Row>
          <div className="gallery">
            <Row>
              <Col lg={12}>
                <h2 className="title">
                  <FontAwesomeIcon icon={faImages} />
                  {t(basePath + "title")}
                </h2>
              </Col>
            </Row>
            <Row>
              {nftContent.map((item, i) => (
                <Col lg={3} md={6} key={i}>
                  <div className="item">
                    <img src={require(`../assets/img/nft/img-${item.img}.jpg`).default} alt="" />
                    <div className="desc">
                      <div className="name">
                        <span>
                          {item.cat} <img className="flag" src={require("../assets/img/flags/ua.png").default} alt="" />
                        </span>
                        <h4>{item.title}</h4>
                      </div>
                      <div className="price">
                        <span>{t(basePath + "price")}</span>
                        <h4>
                          {item.price}
                          <img src={require("../assets/img/icon/eth.svg").default} alt="" />
                        </h4>
                      </div>
                    </div>
                    <div className="btn-groups">
                      <a href={item.link} className="btn-buy">
                        <FontAwesomeIcon icon={faWallet} />
                        {t(basePath + "btn")}
                      </a>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default NFT;
