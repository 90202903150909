import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWheelchair, faChild, faBlind, faUserInjured, faJetFighter } from "@fortawesome/free-solid-svg-icons";

const Company = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "home.company.";

  const options = {
    thumbnails: {
      showThumbnails: false,
    },
  };

  return (
    <section className="company">
      <Container>
        <Row>
          <Col lg={6}>
            <div className="widget">
              <SimpleReactLightbox>
                <SRLWrapper options={options}>
                  <a href={require("../../assets/img/about/about-1.jpg").default}>
                    <img src={require("../../assets/img/about/about-1.jpg").default} alt="" />
                  </a>
                  <a href={require("../../assets/img/about/about-2.jpg").default}>
                    <img src={require("../../assets/img/about/about-2.jpg").default} alt="" />
                  </a>
                  <a href={require("../../assets/img/about/about-3.jpg").default}>
                    <img src={require("../../assets/img/about/about-3.jpg").default} alt="" />
                  </a>
                  <a href={require("../../assets/img/about/about-4.jpg").default}>
                    <img src={require("../../assets/img/about/about-4.jpg").default} alt="" />
                  </a>
                </SRLWrapper>
              </SimpleReactLightbox>
            </div>
          </Col>
          <Col lg={6} className="info">
            <h3>
              <img src={require("../../assets/img/logo-icon.png").default} alt="" />
              {t(basePath + "title")}
            </h3>
            <p>{t(basePath + "desc")}</p>
            <p>{t(basePath + "descMil")}</p>
            <div className="mission">
              <div className="item">
                <h4>{t(basePath + "mission.title")}</h4>
                <p>{t(basePath + "mission.desc")}</p>
              </div>
              <div className="item">
                <h4>{t(basePath + "purpose.title")}</h4>
                <p>{t(basePath + "purpose.desc")}</p>
              </div>
            </div>
            <div className="mission">
              <div className="item w-100 mb-30">
                <h4>{t(basePath + "military.title")}</h4>
                <p>{t(basePath + "military.desc")}</p>
              </div>
            </div>
            <Row className="work">
              <Col lg={6} md={6}>
                <span>
                  <FontAwesomeIcon icon={faWheelchair} />
                  <p>{t(basePath + "work.disability")}</p>
                </span>
                <span>
                  <FontAwesomeIcon icon={faChild} />
                  <p>{t(basePath + "work.orphans")}</p>
                </span>
                <span>
                  <FontAwesomeIcon icon={faJetFighter} />
                  <p>{t(basePath + "work.military")}</p>
                </span>
              </Col>
              <Col lg={6} md={6}>
                <span>
                  <FontAwesomeIcon icon={faBlind} />
                  <p>{t(basePath + "work.retirees")}</p>
                </span>
                <span>
                  <FontAwesomeIcon icon={faUserInjured} />
                  <p>{t(basePath + "work.difficult")}</p>
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Company;
