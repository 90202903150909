import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Banner(props) {
  // Translation
  const { t } = useTranslation();
  const basePath = "kindgarden.banner.";

  return (
    <section className="k-banner">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8}>
            <h2>{t(basePath + "subtitle")}</h2>
            <h1>{t(basePath + "title")}</h1>
            <div className="d-flex justify-content-center">
              <Button href="#direct" className="btn-st">
                {t(basePath + "btn")}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Banner;
