import React from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const PageBanner = () => {
  const { t } = useTranslation();
  const basePath = "pageBanner";
  let pagePath = window.location.pathname.split("/");
  pagePath.length = 2;
  pagePath = pagePath.join(".");

  return (
    <div className="page-banner">
      <Container>
        <h1>{t(basePath + pagePath)}</h1>
        <Breadcrumb>
          <Breadcrumb.Item href="/">{t(basePath + ".home")}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t(basePath + pagePath)}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </div>
  );
};

export default PageBanner;
