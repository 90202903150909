export const NEWS_ARRAY = [
  {
    id: 9,
    data: {
      "en-US": {
        title: "Children are our wealth and our future",
        short_desc:
          'The first lesson of the group for children of psychological support on the project "NEAR" 🕊 Children are our future, and in order to create maximum support for them now, the psychologists of the OK Psychology Center work under the program of the Ministry of Education and Science of Ukraine, UNICEF - where they help children minimize the consequences of war in their lives. Kids have something to say, they feel even more than you can imagine',
        tags: "News, Charity",
      },
      "ua-UA": {
        title: `Співпраця з центром психологічної підтримки "Ок"`,
        short_desc: `Перше заняття групи для дітей психологічної підтримки по проекту «ПОРУЧ» 🕊 Діти - наше майбутнє і щоб створити для них зараз максимальну підтримку, психологині  центру психології  ОК працюють за програмою Міністерства освіти та науки України, ЮНІСЕФ – де допомогають дітям мінімізувати наслідки війни в їх життях. Дітям є про що сказати, вони відчувають навіть більше, ніж ви можете уявити`,
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "Kindertag",
        short_desc:
          "Die erste Unterrichtsstunde der Gruppe für Kinder der psychologischen Betreuung zum Projekt „NEAR“ 🕊 Kinder sind unsere Zukunft, und um jetzt maximale Unterstützung für sie zu schaffen, arbeiten die Psychologen des OK-Psychologiezentrums im Rahmen des Programms des Ministeriums für Bildung und Wissenschaft der Ukraine, UNICEF, wo sie Kindern helfen, die Folgen des Krieges in ihrem Leben zu minimieren lebt. Kinder haben etwas zu sagen, sie fühlen noch mehr, als Sie sich vorstellen können",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "Día del Niño",
        short_desc:
          'La primera lección del grupo para niños de apoyo psicológico sobre el proyecto "NEAR" 🕊 Los niños son nuestro futuro, y para crear el máximo apoyo para ellos ahora, los psicólogos del Centro de Psicología OK trabajan bajo el programa del Ministerio de Educación y Ciencia de Ucrania, UNICEF, donde ayudan a los niños a minimizar las consecuencias de la guerra en su vive. Los niños tienen algo que decir, sienten incluso más de lo que te imaginas',
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "La journée des enfants",
        short_desc:
          "Le premier cours du groupe pour enfants de soutien psychologique sur le projet \"NEAR\" 🕊 Les enfants sont notre avenir, et afin de créer un maximum de soutien pour eux maintenant, les psychologues du centre de psychologie OK travaillent dans le cadre du programme du ministère de l'Éducation et des Sciences de l'Ukraine, UNICEF - où ils aident les enfants à minimiser les conséquences de la guerre dans leur des vies. Les enfants ont quelque chose à dire, ils ressentent encore plus que vous ne pouvez l'imaginer",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "Giorno dei bambini",
        short_desc:
          "La prima lezione del gruppo per bambini di sostegno psicologico sul progetto \"VICINO\" 🕊 I bambini sono il nostro futuro e, al fine di creare loro il massimo sostegno ora, gli psicologi del Centro di Psicologia OK lavorano nell'ambito del programma del Ministero dell'Istruzione e della Scienza dell'Ucraina, UNICEF - dove aiutano i bambini a ridurre al minimo le conseguenze della guerra nella loro vite. I bambini hanno qualcosa da dire, si sentono anche più di quanto tu possa immaginare",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Dzień Dziecka",
        short_desc:
          "Pierwsza lekcja grupy dla dzieci wsparcia psychologicznego w ramach projektu „BLIŻEJ” Dzieci są naszą przyszłością i aby teraz stworzyć dla nich maksymalne wsparcie, psychologowie Centrum Psychologii OK działają w ramach programu Ministerstwa Edukacji i Nauki Ukrainy UNICEF - gdzie pomagają dzieciom zminimalizować konsekwencje wojny w ich zyje. Dzieci mają coś do powiedzenia, czują nawet więcej, niż możesz sobie wyobrazić",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Dia das Crianças",
        short_desc:
          'A primeira aula do grupo para crianças de apoio psicológico no projeto "NEAR" 🕊 As crianças são o nosso futuro e, para lhes dar o máximo apoio agora, os psicólogos do OK Psychology Center trabalham no âmbito do programa do Ministério da Educação e Ciência da Ucrânia, UNICEF - onde ajudam as crianças a minimizar as consequências da guerra nas suas vidas. As crianças têm algo a dizer, elas sentem ainda mais do que você pode imaginar',
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-10/2.JPG"),
    time: "04.06.2022",
    img_report: [
      { img: require("../assets/img/news/news-10/1.JPG") },
      { img: require("../assets/img/news/news-10/2.JPG") },
      { img: require("../assets/img/news/news-10/3.JPG") },
      { img: require("../assets/img/news/news-10/4.JPG") },
    ],
    letters_thanks: {
      state: false,
    },
  },
  {
    id: 8,
    data: {
      "en-US": {
        title: "Children are our wealth and our future",
        short_desc:
          'That\'s why we created a competition for our children🥳 Conditions: Children up to 6 years old will need to record their talent on video - poetry, song, dance, any creative production💃 And children over 7 years old will need to create any product you know - something sew, blind, tie, glue, draw - the flight of fancy is not limited. Do not forget to videotape the work process and bring it with the product to the office of our foundation 🚀 A prerequisite for everyone - there must be something patriotic in the frame. Or in clothes, for example to be in an embroidered shirt or colors of a flag, or in jewelry - bracelets, earrings. Maybe there will be a picture or a Ukrainian song in the frame🤗 At the end of the video the child will say the following phrase: "Who, if not me, will support my country" 🎉 The winners will be chosen by voting on Instagram in the fund. Voting starts on June 17 And, of course, the most important information. Prizes provided by the children\'s toy store "Bontoy"',
        tags: "News, Charity",
      },
      "ua-UA": {
        title: `Діти — це наше багатство і наше майбутнє`,
        short_desc: `Тому ми створили конкурс для наших діток🥳 Умови: Діткам до 6 років треба буде свій талант записати на відео - вірш, пісня, танець, будь-яка творча постановка💃 А дітям старше 7 років треба створити виріб будь-який, яку вмієте - щось пошити, сліпити, зв\'язати, склеїти, намалювати - політ фантазії не обмежений. Процес роботи не забудьте зняти на відео і принести разом з виробом в офіс нашого фонду🚀 Обов\'язкова умова для усіх - в кадрі повинно бути щось патріотичне. Або в одязі, наприклад бути у вишиванці або кольорах прапора, або в прикрасах - браслети, сережки. Може в кадр буде якийсь малюнок або звучати українська пісня🤗 У кінці відео дитина скаже таку фразу: "Хто, як не я, підтримає мою країну" 🎉 Переможці будуть вибрані шляхом голосування в инстаграм в фонду. Старт голосування 17 червня. І, звичайно, найголовніша інформація. Призи надані магазином дитячих іграшок "Bontoy"`,
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "Kindertag",
        short_desc:
          'Aus diesem Grund haben wir einen Wettbewerb für unsere Kinder ins Leben gerufen🥳 Bedingungen: Kinder bis 6 Jahre müssen ihr Talent auf Video festhalten – Poesie, Gesang, Tanz, jede kreative Produktion💃 Und Kinder über 7 Jahre müssen jedes von Ihnen erstellte Produkt erstellen wissen - etwas nähen, blind machen, binden, kleben, zeichnen - der Fantasie sind keine Grenzen gesetzt. Vergesst nicht den Arbeitsprozess auf Video aufzunehmen und mit dem Produkt ins Büro unserer Stiftung zu bringen 🚀 Voraussetzung für alle - es muss etwas Patriotisches im Rahmen sein. Oder in Kleidung, zum Beispiel in einem bestickten Hemd oder in den Farben einer Flagge, oder in Schmuck - Armbändern, Ohrringen. Vielleicht wird ein Bild oder ein ukrainisches Lied im Rahmen sein🤗 Am Ende des Videos sagt das Kind den folgenden Satz: „Wer, wenn nicht ich, wird mein Land unterstützen“ 🎉 Die Gewinner werden per Abstimmung auf Instagram ermittelt im Fonds. Die Abstimmung startet am 17. Juni Und natürlich die wichtigsten Informationen. Preise des Kinderspielzeugladens "Bontoy"',
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "Día del Niño",
        short_desc:
          'Es por eso que creamos un concurso para nuestros niños🥳 Condiciones: Los niños de hasta 6 años deberán registrar su talento en video: poesía, canción, baile, cualquier producción creativa💃 Y los niños mayores de 7 años deberán crear cualquier producto que saber - algo coser, cegar, atar, pegar, dibujar - el vuelo de la fantasía no está limitado. No olvide grabar en video el proceso de trabajo y llevarlo con el producto a la oficina de nuestra fundación 🚀 Un requisito previo para todos: debe haber algo patriótico en el marco. O en ropa, por ejemplo para estar en una camisa bordada o colores de una bandera, o en joyas - pulseras, aretes. Tal vez haya una foto o una canción ucraniana en el marco 🤗 Al final del video, el niño dirá la siguiente frase: "¿Quién, si no yo, apoyará a mi país?" 🎉 Los ganadores serán elegidos votando en Instagram en el fondo La votación comienza el 17 de junio Y, por supuesto, la información más importante. Premios proporcionados por la juguetería infantil "Bontoy"',
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "La journée des enfants",
        short_desc:
          "C'est pourquoi nous avons créé un concours pour nos enfants🥳 Conditions : Les enfants jusqu'à 6 ans devront enregistrer leur talent sur vidéo - poésie, chanson, danse, toute production créative💃 Et les enfants de plus de 7 ans devront créer n'importe quel produit que vous savoir - quelque chose coudre, aveugler, attacher, coller, dessiner - le vol de fantaisie n'est pas limité. N'oubliez pas de filmer le processus de travail et de l'apporter avec le produit au bureau de notre fondation 🚀 Une condition préalable pour tout le monde - il doit y avoir quelque chose de patriotique dans le cadre. Ou dans les vêtements, par exemple pour être dans une chemise brodée ou aux couleurs d'un drapeau, ou dans des bijoux - bracelets, boucles d'oreilles. Peut-être y aura-t-il une photo ou une chanson ukrainienne dans le cadre🤗 A la fin de la vidéo l'enfant dira la phrase suivante : \"Qui, si ce n'est moi, soutiendra mon pays\" 🎉 Les gagnants seront choisis en votant sur Instagram dans le fonds. Le vote commence le 17 juin Et, bien sûr, l'information la plus importante. Lots offerts par le magasin de jouets pour enfants \"Bontoy\"",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "Giorno dei bambini",
        short_desc:
          'Ecco perché abbiamo creato un concorso per i nostri bambini🥳 Condizioni: i bambini fino a 6 anni dovranno registrare il loro talento in video - poesia, canto, danza, qualsiasi produzione creativa💃 E i bambini di età superiore ai 7 anni dovranno creare qualsiasi prodotto tu sapere - qualcosa cuci, acceca, lega, incolla, disegna - il volo della fantasia non è limitato. Non dimenticare di filmare il processo di lavoro e portarlo con il prodotto nell\'ufficio della nostra fondazione 🚀 Un prerequisito per tutti: ci deve essere qualcosa di patriottico nell\'inquadratura. O nei vestiti, ad esempio per essere in una camicia ricamata o nei colori di una bandiera, o in gioielli: bracciali, orecchini. Magari nella cornice ci sarà una foto o una canzone ucraina🤗 Alla fine del video il bambino pronuncerà la seguente frase: "Chi, se non io, sosterrà il mio paese" 🎉 I vincitori saranno scelti votando su Instagram nel fondo. Le votazioni iniziano il 17 giugno E, naturalmente, le informazioni più importanti. Premi forniti dal negozio di giocattoli per bambini "Bontoy"',
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Dzień Dziecka",
        short_desc:
          "Dlatego stworzyliśmy konkurs dla naszych dzieci🥳 Warunki: Dzieci w wieku do 6 lat będą musiały nagrywać swój talent na wideo - poezja, piosenka, taniec, każda kreatywna produkcja💃 A dzieci powyżej 7 lat będą musiały stworzyć dowolny produkt, który wiesz - coś uszyj, zaślep, zawiąż, przyklej, narysuj - lot fantazji nie jest ograniczony. Nie zapomnij nagrać na taśmę wideo procesu pracy i zabrać go z produktem do biura naszej fundacji 🚀 Warunek wstępny dla każdego - w kadrze musi być coś patriotycznego. Albo w ubraniach, np. do haftowanej koszuli lub kolorów flagi, albo w biżuterii - bransoletkach, kolczykach. Może w kadrze będzie zdjęcie lub ukraińska piosenka Na końcu filmu dziecko powie zdanie: „Kto, jeśli nie ja, wesprze mój kraj” 🎉 Zwycięzców wyłoni głosowanie na Instagramie w funduszu. Głosowanie rozpoczyna się 17 czerwca No i oczywiście najważniejsze informacje. Nagrody zapewnione przez sklep z zabawkami dla dzieci „Bontoy”",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Dia das Crianças",
        short_desc:
          'Por isso criamos um concurso para nossas crianças🥳 Condições: Crianças até 6 anos precisarão registrar seu talento em vídeo - poesia, música, dança, qualquer produção criativa💃 E crianças acima de 7 anos precisarão criar qualquer produto que você sabe - costurar, cegar, amarrar, colar, desenhar - o vôo da fantasia não é limitado. Não se esqueça de filmar o processo de trabalho e trazê-lo com o produto para o escritório de nossa fundação 🚀 Um pré-requisito para todos - deve haver algo patriótico no quadro. Ou em roupas, por exemplo, em uma camisa bordada ou nas cores de uma bandeira, ou em joias - pulseiras, brincos. Talvez haja uma foto ou uma música ucraniana no quadro🤗 Ao final do vídeo a criança dirá a seguinte frase: "Quem, se não eu, apoiará meu país" 🎉 Os vencedores serão escolhidos por votação no Instagram no fundo. A votação começa em 17 de junho E, claro, as informações mais importantes. Prêmios fornecidos pela loja de brinquedos infantis "Bontoy"',
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-9/1.jpg"),
    time: "02.06.2022",
    img_report: [{ img: require("../assets/img/news/news-9/1.jpg") }],
    letters_thanks: {
      state: false,
    },
  },
  {
    id: 7,
    data: {
      "en-US": {
        title: "Children's Day",
        short_desc:
          "Since the beginning of the Russian invasion, 243 children have died. Every day in our country 2 children die and 4 are injured ... ❤️ Russia steals childhood from our children, but we believe that it will not be able to steal the future from them. From an early age they grow up to be real Ukrainians. They learned what evil is, but they also learned what good, strength, courage, fidelity are! Great love for their homeland, which will grow with them and grow stronger every day. We will win! 🕊 For their sake! For the sake of the future! Bright and kind! Everything will be Ukraine 💙💛 It was on 01.06.2022 that we decided to create a holiday for our children. We had a holiday for 100 children and this is just the beginning) After all, who but us, is able to protect them😍",
        tags: "News, Charity",
      },
      "ua-UA": {
        title: `День захисту дітей`,
        short_desc:
          "З початку вторгнення росії загинуло 243 дитини. Кожного дня в нашій країні гине 2 дитини та 4 отримують поранення...❤️ росія краде дитинство у наших дітей, але ми віримо, що майбутнє вона не зможе в них вкрасти. Вже з малечку вони ростуть справжніми українцями Вони дізналися, що таке зло, але вони дізналися і що таке добро, сила, відвага, вірність! Величезна любов до своєї Батьківщини, яка буде рости разом з ними й кріпнути кожен день. Ми переможемо! 🕊 За ради них! За ради майбутнього! Світлого і доброго! Все буде Україна 💙💛 Саме 01.06.2022 ми вирішили створити свято для наших діток. Нас було свято на 100 дітей і це тільки початок) Адже,хто як не ми,в силах їх захистити😍",
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "Kindertag",
        short_desc:
          "Seit Beginn der russischen Invasion sind 243 Kinder gestorben. Jeden Tag sterben in unserem Land 2 Kinder und 4 werden verletzt ... ❤️ Russland stiehlt unseren Kindern die Kindheit, aber wir glauben, dass es ihnen die Zukunft nicht stehlen kann. Von klein auf werden sie zu echten Ukrainern, sie haben gelernt, was böse ist, aber sie haben auch gelernt, was gut, Stärke, Mut, Treue sind! Große Liebe zu ihrer Heimat, die mit ihnen wachsen und jeden Tag stärker werden wird. Wir werden gewinnen! 🕊 Ihnen zuliebe! Der Zukunft zuliebe! Hell und freundlich! Alles wird Ukraine 💙💛 Am 01.06.2022 haben wir beschlossen, einen Feiertag für unsere Kinder zu schaffen. Wir hatten einen Urlaub für 100 Kinder und das ist erst der Anfang) Denn wer außer uns kann sie beschützen😍",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "Día del Niño",
        short_desc:
          "Desde el comienzo de la invasión rusa han muerto 243 niños. Todos los días en nuestro país mueren 2 niños y 4 resultan heridos... ❤️ Rusia les roba la infancia a nuestros hijos, pero creemos que no podrá robarles el futuro. Desde una edad temprana crecen para ser verdaderos ucranianos. Aprendieron lo que es el mal, pero también aprendieron lo que es el bien, la fuerza, el coraje y la fidelidad. Gran amor por su patria, que crecerá con ellos y se fortalecerá cada día. ¡Ganaremos! 🕊 ¡Por su bien! ¡Por el bien del futuro! ¡Brillante y amable! Todo será Ucrania 💙💛 Fue el 01.06.2022 que decidimos crear unas vacaciones para nuestros hijos. Tuvimos unas vacaciones para 100 niños y esto es solo el comienzo) Después de todo, quién sino nosotros puede protegerlos😍",
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "La journée des enfants",
        short_desc:
          "Depuis le début de l'invasion russe, 243 enfants sont morts. Chaque jour dans notre pays 2 enfants meurent et 4 sont blessés... ❤️ La Russie vole l'enfance à nos enfants, mais nous pensons qu'elle ne pourra pas leur voler l'avenir. Dès leur plus jeune âge, ils ont grandi pour devenir de vrais Ukrainiens, ils ont appris ce qu'est le mal, mais ils ont aussi appris ce que sont le bien, la force, le courage, la fidélité ! Un grand amour pour leur patrie, qui grandira avec eux et se renforcera chaque jour. Nous gagnerons! 🕊 Pour eux ! Au nom de l'avenir ! Lumineux et gentil ! Tout sera Ukraine 💙💛 C'est le 01.06.2022 que nous avons décidé de créer des vacances pour nos enfants. Nous avons eu des vacances pour 100 enfants et ce n'est que le début) Après tout, qui d'autre que nous est capable de les protéger😍",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "Giorno dei bambini",
        short_desc:
          "Dall'inizio dell'invasione russa sono morti 243 bambini. Ogni giorno nel nostro Paese muoiono 2 bambini e 4 rimangono feriti... ❤️ La Russia ruba l'infanzia ai nostri figli, ma crediamo che non riuscirà a rubare loro il futuro. Fin da piccoli sono diventati dei veri ucraini, hanno imparato cos'è il male, ma hanno anche imparato cosa sono il bene, la forza, il coraggio, la fedeltà! Grande amore per la loro terra, che crescerà con loro e si rafforzerà ogni giorno. Vinceremo! 🕊 Per il loro bene! Per il bene del futuro! Luminoso e gentile! Tutto sarà Ucraina 💙💛 È stato il 01.06.2022 che abbiamo deciso di creare una vacanza per i nostri bambini. Abbiamo fatto una vacanza per 100 bambini e questo è solo l'inizio) In fondo chi, se non noi, può proteggerli😍",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Dzień Dziecka",
        short_desc:
          "Od początku rosyjskiej inwazji zginęło 243 dzieci. Każdego dnia w naszym kraju ginie dwoje dzieci, a czwórka zostaje rannych… ❤️ Rosja kradnie naszym dzieciom dzieciństwo, ale wierzymy, że nie będzie w stanie ukraść im przyszłości. Od najmłodszych lat wyrośli na prawdziwych Ukraińców, nauczyli się, czym jest zło, ale też nauczyli się, czym jest dobro, siła, odwaga, wierność! Wielka miłość do ojczyzny, która będzie rosła wraz z nimi i z każdym dniem stawała się silniejsza. Wygramy! 🕊 Ze względu na nich! W trosce o przyszłość! Jasny i miły! Wszystko będzie Ukrainą 💙💛 To właśnie 01.06.2022 postanowiliśmy stworzyć wakacje dla naszych dzieci. Mieliśmy wakacje na 100 dzieci i to dopiero początek) W końcu kto oprócz nas jest w stanie je ochronić😍",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Dia das Crianças",
        short_desc:
          "Desde o início da invasão russa, 243 crianças morreram. Todos os dias em nosso país morrem 2 crianças e 4 ficam feridas ... ❤️ A Rússia rouba a infância de nossos filhos, mas acreditamos que não poderá roubar o futuro deles. Desde cedo eles se tornam verdadeiros ucranianos, aprenderam o que é o mal, mas também o que é o bem, a força, a coragem, a fidelidade! Grande amor por sua pátria, que crescerá com eles e se fortalecerá a cada dia. Nós ganharemos! 🕊 Pelo bem deles! Em prol do futuro! Brilhante e gentil! Tudo será Ucrânia 💙💛 Foi no dia 01.06.2022 que decidimos criar um feriado para os nossos filhos. Tivemos um feriado para 100 crianças e isso é só o começo) Afinal, quem além de nós, é capaz de protegê-los😍",
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-8/preview.jpg"),
    time: "01.06.2022",
    img_report: [
      { img: require("../assets/img/news/news-8/1.jpg") },
      { img: require("../assets/img/news/news-8/2.jpg") },
      { img: require("../assets/img/news/news-8/3.jpg") },
      { img: require("../assets/img/news/news-8/5.jpg") },
      { img: require("../assets/img/news/news-8/6.jpg") },
      { img: require("../assets/img/news/news-8/8.jpg") },
      { img: require("../assets/img/news/news-8/4.jpg") },
      { img: require("../assets/img/news/news-8/7.jpg") },
      { img: require("../assets/img/news/news-8/9.jpg") },
      { img: require("../assets/img/news/news-8/10.jpg") },
      { img: require("../assets/img/news/news-8/11.jpg") },
      { img: require("../assets/img/news/news-8/12.jpg") },
      { img: require("../assets/img/news/news-8/13.jpg") },
    ],
    letters_thanks: {
      state: false,
    },
  },
  {
    id: 6,
    data: {
      "en-US": {
        title: "Animals from Marshmallow",
        short_desc:
          'Every Saturday we meet for master classes from our team of volunteers. Many thanks to the restaurant "Watercolor" for the hospitable reception. Together to victory🎉',
        tags: "News, Charity",
      },
      "ua-UA": {
        title: `Звірята з Маршмеллоу`,
        short_desc:
          'Кожну суботу ми зустрічаємось на майстер класах від нашої команди волонтерів. Велика вдячніть ресторану "Акварель" за гостинний прийом. Разом до перемоги🎉',
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "Tiere aus Marshmallow",
        short_desc:
          'Jeden Samstag treffen wir uns zu Meisterkursen unseres Teams von Freiwilligen. Vielen Dank an das Restaurant "Watercolor" für den gastfreundlichen Empfang. Gemeinsam zum Sieg🎉',
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "Animales de Marshmallow",
        short_desc:
          'Todos los sábados nos reunimos para clases magistrales de nuestro equipo de voluntarios. Muchas gracias al restaurante "Acuarela" por la hospitalaria recepción. Juntos a la victoria 🎉',
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "Animaux de Guimauve",
        short_desc:
          'Tous les samedis, nous nous réunissons pour des classes de maître de notre équipe de bénévoles. Un grand merci au restaurant "Aquarelle" pour l\'accueil chaleureux. Ensemble vers la victoire🎉',
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "Animali da Marshmallow",
        short_desc:
          'Ogni sabato ci incontriamo per i corsi di perfezionamento del nostro team di volontari. Mille grazie al ristorante "Watercolor" per l\'ospitale accoglienza. Insieme alla vittoria🎉',
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Zwierzęta z Marshmallow",
        short_desc:
          'W każdą sobotę spotykamy się na lekcjach mistrzowskich z naszym zespołem wolontariuszy. Serdeczne podziękowania dla restauracji "Akwarela" za gościnne przyjęcie. Razem do zwycięstwa🎉',
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Animais de marshmallow",
        short_desc:
          'Todos os sábados nos reunimos para master classes da nossa equipe de voluntários. Muito obrigado ao restaurante "Aquarela" pela hospitaleira recepção. Juntos para a vitória🎉',
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-7/6.jpg"),
    time: "22.05.2022",
    img_report: [
      { img: require("../assets/img/news/news-7/2.jpg") },
      { img: require("../assets/img/news/news-7/3.jpg") },
      { img: require("../assets/img/news/news-7/4.jpg") },
      { img: require("../assets/img/news/news-7/5.jpg") },
      { img: require("../assets/img/news/news-7/6.jpg") },
      { img: require("../assets/img/news/news-7/1.jpg") },
      { img: require("../assets/img/news/news-7/7.jpg") },
      { img: require("../assets/img/news/news-7/8.jpg") },
    ],
    letters_thanks: {
      state: false,
    },
  },
  {
    id: 5,
    data: {
      "en-US": {
        title: "Courage is to be a Ukrainian mother",
        short_desc:
          "Only our mothers are able to continue to delight children in this difficult time. They make every effort to ensure that these gray days do not spoil the bright imagination of children about this world. That everyone remembered the childhood in bright colors🇺🇦. Every Saturday, the Who If Not Us Foundation organizes small but such family events for mothers and children, where we have the opportunity to communicate, and children create masterpieces with their own hands in master classes😇. Our children are strong because they are the daughters and sons of an indomitable people.",
        tags: "News, Charity",
      },
      "ua-UA": {
        title: `Сміливість - бути українською мамою`,
        short_desc:
          "Тільки наші матусі здатні продовжити радувати дітей у цей складний час Вони роблять максимальні зусилля, щоб ці сірі дні, не зіпсували яскраву уяву дітей про цей світ. Щоб кожен згадував дитинство в яскравих кольорах🇺🇦. Фонд «Хто,якщо не ми» кожну суботу влаштовує для матусь та дітей невеликі але такі сімейні заходи де у нас є можливість поспілкуватись , а діти власними руками на майстер-класах створюють шедеври😇. Наші діти-сильні,бо вони доньки та сини незламного народу💪🏻",
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "Mut ist, eine ukrainische Mutter zu sein",
        short_desc:
          "Nur unsere Mütter können die Kinder in dieser schwierigen Zeit weiterhin erfreuen und setzen alles daran, dass diese grauen Tage die heitere Fantasie der Kinder von dieser Welt nicht verderben. Dass sich alle in bunten Farben an die Kindheit erinnerten🇺🇦. Jeden Samstag organisiert die Who If Not Us Foundation kleine, aber feine Familienevents für Mütter und Kinder, bei denen wir die Möglichkeit haben, uns auszutauschen, und Kinder in Meisterkursen Meisterwerke mit ihren eigenen Händen erschaffen😇. Unsere Kinder sind stark, weil sie die Töchter und Söhne eines unbezwingbaren Volkes sind.",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "Valor es ser madre ucraniana",
        short_desc:
          "Solo nuestras madres son capaces de seguir deleitando a los niños en este momento difícil y se esfuerzan al máximo para que estos días grises no echen a perder la brillante imaginación de los niños sobre este mundo. Que todos recordaran la infancia en colores brillantes 🇺🇦. Todos los sábados, la Fundación Who If Not Us organiza pequeños pero tales eventos familiares para madres e hijos, donde tenemos la oportunidad de comunicarnos, y los niños crean obras maestras con sus propias manos en clases magistrales😇. Nuestros hijos son fuertes porque son hijas e hijos de un pueblo indomable.",
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "Le courage est d'être une mère ukrainienne",
        short_desc:
          "Seules nos mères sont capables de continuer à ravir les enfants en cette période difficile.Elles mettent tout en œuvre pour que ces journées grises ne viennent pas gâcher l'imaginaire lumineux des enfants sur ce monde. Que tout le monde se souvienne de l'enfance aux couleurs vives🇺🇦. Tous les samedis, la Fondation Who If Not Us organise de petits événements familiaux pour les mères et les enfants, où nous avons la possibilité de communiquer, et les enfants créent des chefs-d'œuvre de leurs propres mains lors de master classes😇. Nos enfants sont forts parce qu'ils sont les filles et les fils d'un peuple indomptable.",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "Il coraggio è essere una madre ucraina",
        short_desc:
          "Solo le nostre madri possono continuare a deliziare i bambini in questo momento difficile e fanno ogni sforzo per garantire che questi giorni grigi non rovinino la brillante immaginazione dei bambini su questo mondo. Che tutti ricordassero l'infanzia a colori vivaci🇺🇦. Ogni sabato, la Fondazione Who If Not Us organizza piccoli ma tali eventi familiari per mamme e bambini, dove abbiamo l'opportunità di comunicare, e i bambini creano capolavori con le proprie mani nelle masterclass😇. I nostri figli sono forti perché sono figlie e figli di un popolo indomito.",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Odwaga to bycie matką Ukrainką",
        short_desc:
          "Tylko nasze mamy potrafią nadal zachwycać dzieci w tym trudnym czasie, dokładają wszelkich starań, aby te szare dni nie popsuły dziecięcej wyobraźni o tym świecie. Że wszyscy pamiętali dzieciństwo w jasnych kolorach🇺🇦. W każdą sobotę Fundacja Kto Nie My organizuje małe, ale takie rodzinne imprezy dla mam i dzieci, podczas których mamy możliwość komunikowania się, a dzieci własnoręcznie tworzą arcydzieła na lekcjach mistrzowskich😇. Nasze dzieci są silne, ponieważ są córkami i synami niezłomnego ludu.",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Coragem é ser mãe ucraniana",
        short_desc:
          "Somente nossas mães são capazes de continuar a encantar as crianças neste momento difícil e fazem todos os esforços para garantir que esses dias cinzentos não estraguem a imaginação brilhante das crianças sobre este mundo. Que todos se lembrassem da infância em cores vivas🇺🇦. Todos os sábados, a Fundação Who If Not Us organiza pequenos eventos familiares para mães e filhos, onde temos a oportunidade de nos comunicar, e as crianças criam obras-primas com as próprias mãos em master classes😇. Nossos filhos são fortes porque são filhas e filhos de um povo indomável.",
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-6/preview.jpg"),
    time: "16.05.2022",
    img_report: [
      { img: require("../assets/img/news/news-6/2.jpg") },
      { img: require("../assets/img/news/news-6/3.jpg") },
      { img: require("../assets/img/news/news-6/10.jpg") },
      { img: require("../assets/img/news/news-6/5.jpg") },
      { img: require("../assets/img/news/news-6/6.jpg") },
      { img: require("../assets/img/news/news-6/7.jpg") },
      { img: require("../assets/img/news/news-6/8.jpg") },
      { img: require("../assets/img/news/news-6/9.jpg") },
      { img: require("../assets/img/news/news-6/13.jpg") },
      { img: require("../assets/img/news/news-6/11.jpg") },
      { img: require("../assets/img/news/news-6/12.jpg") },
      { img: require("../assets/img/news/news-6/4.jpg") },
    ],
    letters_thanks: {
      state: false,
    },
  },
  {
    id: 4,
    data: {
      "en-US": {
        title: "Issuance of humanitarian aid in the Dnieper for temporarily displaced families",
        short_desc: "Thank you for your cooperation! Together we are a force!",
        tags: "News, Charity",
      },
      "ua-UA": {
        title: "Видача гуманітарної допомоги у Дніпрі для тимчасово переміщенних сімей",
        short_desc: "Дякуємо за співпрацю! Разом ми-сила!",
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "Bereitstellung humanitärer Hilfe im Dnjepr für vorübergehend vertriebene Familien",
        short_desc: "Danke für Ihre Kooperation! Zusammen sind wir eine Kraft!",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "Emisión de ayuda humanitaria en el Dnieper para familias desplazadas temporalmente",
        short_desc: "¡Gracias por su cooperación! ¡Juntos somos una fuerza!",
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "Distribution d'aide humanitaire dans le Dniepr pour les familles temporairement déplacées",
        short_desc: "Merci de votre collaboration! Ensemble nous sommes une force !",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "Emissione di aiuti umanitari nel Dnepr per famiglie temporaneamente sfollate",
        short_desc: "Grazie per la collaborazione! Insieme siamo una forza!",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Wydawanie pomocy humanitarnej w Dnieprze dla tymczasowo przesiedlonych rodzin",
        short_desc: "Dziękuję za współpracę! Razem jesteśmy siłą!",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Emissão de ajuda humanitária no Dnieper para famílias temporariamente deslocadas",
        short_desc: "Obrigado por sua cooperação! Juntos somos uma força!",
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-5/preview.jpg"),
    time: "04.05.2022",
    img_report: [
      { img: require("../assets/img/news/news-5/1.jpg") },
      { img: require("../assets/img/news/news-5/2.jpg") },
      { img: require("../assets/img/news/news-5/3.jpg") },
      { img: require("../assets/img/news/news-5/4.jpg") },
      { img: require("../assets/img/news/news-5/5.jpg") },
      { img: require("../assets/img/news/news-5/6.jpg") },
      { img: require("../assets/img/news/news-5/7.jpg") },
    ],
    letters_thanks: {
      state: true,
      img_map: [
        {
          img: require("../assets/img/news/news-5/8.jpg"),
        },
        {
          img: require("../assets/img/news/news-5/9.jpg"),
        },
      ],
    },
  },
  {
    id: 3,
    data: {
      "en-US": {
        title: 'Assistance to the Municipal Institution "Vasylkiv Psychoneurological Boarding School "DOR""',
        short_desc:
          'On November 30, the Vasylkiv Psychoneurological Boarding School "DOR" received from the Charitable Foundation "Who but us?" help.',
        tags: "News, Charity",
      },
      "ua-UA": {
        title: "Допомога Комунальному закладу «Васильківський психоневрологічний інтернат „ДОР“»",
        short_desc:
          '30 листопада Комунальний заклад "Васильківський психоневрологічний інтернат "ДОР" отримав від Благодійного Фонду «Хто, як не ми?» допомогу.',
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: 'Unterstützung der städtischen Einrichtung "Vasylkiv Psychoneurological Internat "DOR""',
        short_desc:
          'Am 30. November erhielt das psychoneurologische Internat "DOR" von Vasylkiv von der Wohltätigkeitsstiftung "Wer außer uns?" Hilfe.',
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: 'Asistencia a la Institución Municipal "Escuela de Internado Psiconeurológico Vasylkiv "DOR""',
        short_desc:
          'El 30 de noviembre, el internado Psiconeurológico Vasylkiv "DOR" recibió de la Fundación Caritativa "¿Quién sino nosotros?" ayudar.',
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: 'Assistance à l\'institution municipale "Internat psychoneurologique Vasylkiv "DOR""',
        short_desc:
          'Le 30 novembre, l\'internat psychoneurologique Vasylkiv "DOR" a reçu de la Fondation caritative "Qui d\'autre que nous?" aider.',
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: 'Assistenza all\'Istituzione Municipale "Convitto Psiconeurologico Vasylkiv "DOR""',
        short_desc:
          'Il 30 novembre, il Collegio Psiconeurologico Vasylkiv "DOR" ha ricevuto dalla Fondazione di beneficenza "Chi se non noi?" aiuto.',
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Pomoc dla Zakładu Miejskiego „Wasylkowa Psychoneurologiczna Szkoła z Internatem ”DOR”",
        short_desc:
          "30 listopada Wasylkowa Psychoneurologiczna Szkoła z internatem „DOR” otrzymała od Fundacji Dobroczynnej „Kto oprócz nas?” Wsparcie.",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: 'Assistência à Instituição Municipal "Internato Psiconeurológico Vasylkiv "DOR""',
        short_desc:
          'Em 30 de novembro, o Internato Psiconeurológico Vasylkiv "DOR" recebeu da Fundação de Caridade "Quem senão nós?" ajuda.',
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-4/preview.jpg"),
    time: "22.12.2021",
    img_report: [
      { img: require("../assets/img/news/news-4/1.jpg") },
      { img: require("../assets/img/news/news-4/2.jpg") },
      { img: require("../assets/img/news/news-4/3.jpg") },
      { img: require("../assets/img/news/news-4/4.jpg") },
    ],
    letters_thanks: {
      state: false,
    },
  },
  {
    id: 2,
    data: {
      "en-US": {
        title: "On October 16, they visited the village of Zaliniyne, Zachepyliv district, Kharkiv region",
        short_desc:
          "The villagers turned to their compatriots, now the founders of the foundation, for help in purchasing the necessary equipment to improve the work of the staff: the Library, the club, the medical staff.",
        tags: "News, Charity",
      },
      "ua-UA": {
        title: "16 жовтня відвідали село Залінійне, у Зачепилівського району, Харківської області",
        short_desc:
          "Жителі села звернулися за допомогою до своїх земляків, наразі засновників фонду у придбанні необхідної техніки для поліпшення роботи працівників: Бібліотеки, клубу, медичного персоналу.",
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title:
          'Gemeinnützige Stiftung "Wer außer uns?" Am 16. Oktober besuchten sie das Dorf Zaliniyne im Bezirk Zachepyliv in der Region Charkiw',
        short_desc:
          "Die Dorfbewohner wandten sich an ihre Landsleute, jetzt die Gründer der Stiftung, um Hilfe beim Kauf der notwendigen Ausrüstung zu erhalten, um die Arbeit der Angestellten zu verbessern: Bibliothek, Club, medizinisches Personal.",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title:
          'Fundación Benéfica "¿Quién sino nosotros?" El 16 de octubre visitaron el pueblo de Zaliniyne, distrito de Zachepyliv, región de Kharkiv.',
        short_desc:
          "Los aldeanos recurrieron a sus compatriotas, ahora los fundadores de la fundación, en busca de ayuda en la compra de los equipos necesarios para mejorar el trabajo de los empleados: la Biblioteca, el club, el personal médico.",
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title:
          'Fondation caritative "Qui d\'autre que nous?" Le 16 octobre, ils ont visité le village de Zaliniyne, district de Zachepyliv, région de Kharkiv',
        short_desc:
          "Les villageois se sont tournés vers leurs compatriotes, aujourd'hui fondateurs de la fondation, pour les aider à acheter les équipements nécessaires à l'amélioration du travail des salariés : la Bibliothèque, le club, le personnel médical.",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title:
          'Fondazione di beneficenza "Chi se non noi?" Il 16 ottobre hanno visitato il villaggio di Zaliniyne, distretto di Zachepyliv, regione di Kharkiv',
        short_desc:
          "Gli abitanti del villaggio si sono rivolti ai loro connazionali, ora fondatori della fondazione, per un aiuto nell'acquisto delle attrezzature necessarie per migliorare il lavoro dei dipendenti: la Biblioteca, il circolo, il personale medico.",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title:
          "Fundacja Charytatywna „Kto jak nie my?” 16 października odwiedzili wieś Zaliniyne, obwód Zachepylow, obwód charkowski",
        short_desc:
          "Mieszkańcy wsi zwrócili się do rodaków, obecnie założycieli fundacji, o pomoc w zakupie niezbędnego sprzętu usprawniającego pracę pracowników: biblioteki, klubu, personelu medycznego.",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title:
          'Fundação de Caridade "Quem senão nós?" Em 16 de outubro, eles visitaram a aldeia de Zaliniyne, distrito de Zachepyliv, região de Kharkiv',
        short_desc:
          "Os aldeões recorreram aos seus compatriotas, agora fundadores da fundação, para obter ajuda na compra dos equipamentos necessários para melhorar o trabalho dos funcionários: a Biblioteca, o clube, o pessoal médico.",
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-3/preview.jpg"),
    time: "14.11.2021",
    img_report: [
      { img: require("../assets/img/history/ph-1.jpg") },
      { img: require("../assets/img/history/ph-4.jpg") },
      { img: require("../assets/img/history/ph-7.jpg") },
      { img: require("../assets/img/history/ph-8.jpg") },
      { img: require("../assets/img/history/ph-2.jpg") },
      { img: require("../assets/img/history/ph-3.jpg") },
      { img: require("../assets/img/history/ph-5.jpg") },
      { img: require("../assets/img/history/ph-6.jpg") },
    ],
    letters_thanks: {
      state: false,
    },
  },
  {
    id: 1,
    data: {
      "en-US": {
        title: "Two weeks ago we received such a letter in the mail…",
        short_desc: '"Help buy a specialized stroller for a child with special needs" wrote Mom ...',
        tags: "News, Charity",
      },
      "ua-UA": {
        title: "Два тижні тому до нас на пошту прийшов такий лист…",
        short_desc: "« …допоможіть придбати спеціалізований візок для дитини з особливими потребами» писала Мама... ",
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "Vor zwei Wochen bekamen wir so einen Brief per Post…",
        short_desc:
          '"Helfen Sie, einen speziellen Kinderwagen für ein Kind mit besonderen Bedürfnissen zu kaufen", schrieb Mama ...',
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "Hace dos semanas recibimos una carta así por correo...",
        short_desc: '"Ayuda a comprar un cochecito especializado para un niño con necesidades especiales", escribió mamá...',
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "Il y a deux semaines, nous avons reçu une telle lettre par la poste…",
        short_desc: '"Aidez à acheter une poussette spécialisée pour un enfant ayant des besoins spéciaux" a écrit maman ...',
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "Due settimane fa abbiamo ricevuto una lettera del genere per posta...",
        short_desc:
          '"Aiutate a comprare un passeggino specializzato per un bambino con bisogni speciali" ha scritto la mamma...',
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Dwa tygodnie temu otrzymaliśmy taki list pocztą…",
        short_desc: "„Pomóż kupić specjalistyczny wózek dla dziecka ze specjalnymi potrzebami” napisała mama…",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Há duas semanas, recebemos uma carta dessas pelo correio…",
        short_desc:
          '"Ajude a comprar um carrinho especializado para uma criança com necessidades especiais", escreveu a mãe...',
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-2/preview.jpg"),
    time: "14.11.2021",
    img_report: [
      { img: require("../assets/img/news/news-2/1.jpg") },
      { img: require("../assets/img/news/news-2/2.jpg") },
      { img: require("../assets/img/news/news-2/3.jpg") },
      { img: require("../assets/img/news/news-2/4.jpg") },
      { img: require("../assets/img/news/news-2/5.jpg") },
    ],
    letters_thanks: {
      state: false,
    },
  },
  {
    id: 0,
    data: {
      "en-US": {
        title: "Our volunteers lovingly choose the best fruits, because today we go to the orphanage with gifts",
        short_desc: "Visited the Orphanage Periwinkle in the Dnieper on the street. Amber.",
        tags: "News, Charity",
      },
      "ua-UA": {
        title: "Наші волонтери з любов’ю вибирають кращі фрукти, бо ми сьогодні ідемо з подарунками у дитячий будинок",
        short_desc: "Відвідали Дитячий будинок Барвінок у Дніпрі на вул. Янтарній.",
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title:
          "Unsere Freiwilligen wählen liebevoll die besten Früchte aus, denn heute gehen wir mit Geschenken ins Waisenhaus",
        short_desc: "Besuchte das Waisenhaus Periwinkle im Dnjepr auf der Straße. Bernstein.",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "Nuestros voluntarios eligen con amor las mejores frutas, porque hoy vamos al orfanato con regalos",
        short_desc: "Visitó el Orfanato Periwinkle en el Dnieper en la calle. Ámbar.",
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title:
          "Nos bénévoles choisissent avec amour les meilleurs fruits, car aujourd'hui nous allons à l'orphelinat avec des cadeaux",
        short_desc: "Visité l'orphelinat Pervenche dans le Dniepr dans la rue. Ambre.",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "I nostri volontari scelgono amorevolmente i frutti migliori, perché oggi andiamo all'orfanotrofio con i regali",
        short_desc: "Ho visitato l'orfanotrofio Pervinca nel Dnepr per strada. Ambra.",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Nasi wolontariusze z miłością wybierają najlepsze owoce, bo dzisiaj idziemy do Domu Dziecka z prezentami",
        short_desc: "Odwiedziłem sierociniec Barwinek w Dnieprze na ulicy. Bursztyn.",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Nossos voluntários escolhem com carinho as melhores frutas, pois hoje vamos ao orfanato com presentes",
        short_desc: "Visitei o Orfanato Periwinkle no Dnieper na rua. Âmbar.",
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-1/preview.jpg"),
    time: "12.11.2021",
    img_report: [
      { img: require("../assets/img/news/news-1/1.jpg") },
      { img: require("../assets/img/news/news-1/2.jpg") },
      { img: require("../assets/img/news/news-1/3.jpg") },
      { img: require("../assets/img/news/news-1/4.jpg") },
    ],
    letters_thanks: {
      state: false,
    },
  },
];
