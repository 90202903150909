export const TRANSLATIONS_EN = {
  navBar: {
    site: "Our website",
    btnHelp: "HOW YOU CAN HELP",
    btnRequ: "ASK FOR HELP",

    link: {
      home: "Home",
      center: "Children's center",
      about: "About our fund",
      news: "News",
      nft: "NFT Gallery",
      contact: "Contacts",
    },

    lang: {
      title: "Language",
      "en-US": "English",
      "ua-UA": "Ukrainian",
      "es-ES": "Spanish",
      "fr-FR": "French",
      "de-DE": "German",
      "it-IT": "Italian",
      "pl-PL": "Polish",
      "pt-PT": "Portuguese",
    },
  },

  pageBanner: {
    home: "Home",
    about: "About our fund",
    news: "News",
    nft: "NFT Gallery",
    contact: "Contacts",
  },

  copy: {
    copy: "Copy",
    success: "Copied",
  },

  home: {
    banner: {
      title: {
        first: "CHARITABLE FOUNDATION",
        second: '"WHO IF NOT US"',
      },
      desc: 'Our fund "WHO IF NOT US" is fully responsible for the use of funds raised, each contribution will have a direct purpose. The main thing for us is your trust!',
      btnHelp: "How can you help",
      btnRequ: "Ask for help",
      btnDonat: "Donate",
    },

    company: {
      title: '"WHO IF NOT US" Charitable Foundation',
      desc: "Children are our future. Together, we can overcome everything and make our future healthy, happy and with faith in our country. The Who, If Not Us Charitable Foundation organizes fundraising to help children and their families in need. Assistance to seriously ill children, namely payment for treatment and rehabilitation period.",
      descMil: "Continuous procurement of necessary ammunition for the Armed Forces of Ukraine.",
      mission: {
        title: "Foundation mission",
        desc: "Give as much as possible the joy of life to children and provide psychological support to parents who need it now.",
      },
      purpose: {
        title: "The purpose of the fund",
        desc: "Organization of children's activities that affect children's development. Psychological support for children and their parents. Financial and humanitarian support to the victims of the armed conflict in Ukraine.",
      },
      military: {
        title: "Military support",
        desc: "Supply of defenders with ammunition and cars in the hottest spots of Ukraine.",
      },
      widget: {
        title: {
          first: "Together - we will change the world!",
          second: "Start with us now!",
        },
      },
      work: {
        disability: "We help people with disabilities",
        retirees: "We help retirees",
        orphans: "We help orphans",
        difficult: "We help children affected during the armed conflict in Ukraine",
        military: "Provision of the Armed Forces",
      },
    },

    fees: {
      title: "When there is no hope, no strength, and no money, we can be there for you!",
      feesContent: [
        {
          title: "650 000 UAH.",
          desc: "collected to help children",
        },
        {
          title: "93 500 UAH.",
          desc: 'spent on the "Cure with Joy" program',
        },
        {
          title: "196 800 UAH.",
          desc: 'spent on the program "Restorative Therapy"',
        },
        {
          title: "342 000 UAH.",
          desc: 'spent on the program "Saving our Life Together"',
        },
      ],
    },

    our_help: {
      title: "Our help",
      desc: "The Soul fish restaurant opened a relief headquarters during the war. They help with food for the Armed Forces, the police and refugees-displaced persons “For 41 days now, each of us has been helping as much as we can. This is all strong and invincible energy, it united us into one whole. And thanks to that we are moving.",
      btn: "Help",
    },

    donat: {
      title: "Assistance to children injured during the war on the territory of Ukraine",
      btn: "Donate",
      btc: {
        title: "BTC",
        modal: {
          header: "BTC",
          title: "Wallet for donations BTC",
        },
      },
      usdt: {
        title: "USDT",
        modal: {
          header: "USDT TRC-20",
          title: "Wallet for donations USDT TRC-20",
        },
      },
      visa: {
        title: "Visa/MasterCard",
      },
      p2p: {
        title: "Р/Р",
        modal: {
          header: "Current account",
        },
      },
    },

    help: {
      title: "How do we help?",
      desc: "Perhaps you have a way to help this or that family. Please write us and we will contact you.",
      form: {
        name: "Your name",
        phone: "+380 99 999-99-99",
        select: {
          one: "Choose how you can help",
          two: "Money",
          three: "Volunteer",
          four: "Specialist",
          five: "My Help",
        },
        mass: "Your message",
        error: "Message not sent! Please fill in all the fields.",
        success: "Message sent successfully!",
        btn: "Send message",
      },
      specialityContent: [
        {
          title: "As a foundation volunteer",
          desc: "Participate in foundation projects permanently or as a one-time volunteer. You must be over 18 years of age.",
        },
        {
          title: "As a specialist",
          desc: "As a doctor to help the sick, as a psychologist or other specialist.",
        },
        {
          title: "As an internet blogger",
          desc: "Ready to help with the spread of information on the Internet.",
        },
        {
          title: "As a partner store",
          desc: " Conducting promotions related to things and basic necessities.",
        },
        {
          title: "Volunteer help with money",
          desc: "We accept voluntary transfers to the foundation's card account.",
        },
      ],
    },

    request: {
      title: "Ask for help",
      desc: "Write your contacts, describe the situation in detail:",
      info: {
        title: "You can get detailed information by contacting our specialist:",
        name: "Victoria Oleksandrivna Chernyshova",
      },
    },

    transfer: {
      title: "Money transfer",
      error: "Error",
      success: "Successfully",
      thank: "Thank you for the support of the children of Ukraine",
    },
  },

  about: {
    info: {
      title: '"WHO IF NOT US" Charitable Foundation:',
      subtitle: "How it all started…",
      desc: {
        one: "The charity fund was registered in May 2021, but the actual work of supporting Ukrainians has been underway since 2020.",
        two: 'The main goal of the fund "WHO IF NOT US" - help for children. Our tasks also include support for people with disabilities and retirees. Our foundation believes that generosity and generosity are the most important human qualities.',
        three:
          "We are convinced that by doing good, we become stronger and happier, improve relationships, create a better world. Everyone should be able to share time, money or energy. This is a reliable way to create harmony between people.",
      },

      war: {
        title: "The war changed each of us",
        desc: {
          first: 'The "WHO IF NOT US" Foundation continues to work 24/7 to help anyone in need.',
          second: {
            main: "Clothes, food, personal hygiene products were all sent to us from Poland. We encourage everyone to join our fight against our common enemy and join the team! Thank you to everyone who has already made a significant contribution! Together we are able to create incredible things! Together we are marching to victory! Glory to Ukraine!",
            sub: "Victory will be ours!",
          },
        },
      },
    },

    what: {
      title: "What we do",
      item: {
        one: "We help people with disabilities",
        two: "We help orphans",
        three: "We help retirees",
        four: "We help children affected during the armed conflict in Ukraine",
      },
    },

    history: {
      title: "Our story",
      desc: {
        fond: 'The "WHO IF NOT US" Charitable Foundation was established with the sole purpose of doing good to people. We try to work in different directions:',
        believ:
          "Our foundation believes that kindness and generosity are the main human qualities that separate us from animals.",
        generate:
          'The "WHO IF NOT US" Charitable Foundation was created to bring together people who are willing to help. We try to make sure that everyone, regardless of financial status and age, has the opportunity to help children. Our organization wants every unhappy child to have a happy and peaceful childhood.',
      },

      list: {
        money: "Financial and psychological support for people in critical situations;",
        therapy: "Treatment of seriously ill children;",
        moral:
          "Support for children affected by the armed conflict in Ukraine;",
      },
    },
  },

  news: {
    btn: "Read more",
    report: "Photo report",
    thanks: "Letters of thanks",
    page: {
      latest: "Latest news",
      help: {
        title: "Help",
        desc: "Be a volunteer, or help as a specialist. Press the button!",
        btn: "Donate",
      },
    },
  },

  contact: {
    title: "Contact Information",
    address: {
      title: "Address",
      desc: "Dnipro, street Korolenka, 8, office 10",
    },
    mail: {
      title: "Mail",
    },
    phone: {
      title: "Phone",
    },
    work: {
      title: "Working hours",
      desc: "Monday to Friday: 10:00 to 17:00",
    },
  },

  nft: {
    title: "Gallery",
    btn: "Buy",
    price: "Price",

    block: {
      title: {
        main: "The team created the NFT of our fund, the money from the sale goes to help the Armed Forces of Ukraine",
        sub: "and homeless people.",
      },
    },

    info: {
      title: {
        main: "February 2022 to Ukraine",
        sub: "the war came.",
      },

      desc: {
        war: "The war started by Russia. All the citizens of our country have united in one whole - in a steel shield in the struggle and confrontation of a country that has already broken the millions of ordinary Ukrainians.",
        team: 'The team "WHO IF NOT US", like thousands of other charities, from the first day of the war takes every possible part in the fate and assistance to our country and our citizens.',
        friend: "Dear friends and philanthropists, we look forward to any help and support your organization can provide.",
      },
      together: "After all, together we are Ukraine",
    },

    nftContent: [
      {
        img: "4",
        cat: "Hero cities of Ukraine",
        title: "Irpen",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304559263746621540",
      },
      {
        img: "1",
        cat: "Hero cities of Ukraine",
        title: "Bucha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304558164234993764",
      },
      {
        img: "7",
        cat: "Hero cities of Ukraine",
        title: "Okhtirka",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304557064723365988",
      },
      {
        img: "10",
        cat: "Hero cities of Ukraine",
        title: "Chernihiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304553766188482660",
      },
      {
        img: "6",
        cat: "Hero cities of Ukraine",
        title: "Mykolayiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304552666676854884",
      },
      {
        img: "2",
        cat: "Hero cities of Ukraine",
        title: "Volnovakha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304551567165227108",
      },
      {
        img: "9",
        cat: "Hero cities of Ukraine",
        title: "Kherson",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304550467653599332",
      },
      {
        img: "3",
        cat: "Hero cities of Ukraine",
        title: "Gostomel",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304548268630343780",
      },
      {
        img: "8",
        cat: "Hero cities of Ukraine",
        title: "Kharkiv",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304547169118716004",
      },
      {
        img: "5",
        cat: "Hero cities of Ukraine",
        title: "Mariupol",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304546069607088228",
      },
    ],
  },

  kindgarden: {
    banner: {
      subtitle: "Our Children's Center",
      title: "\"Who if not us\" create an incredibly creative fairy tale for children in the form of master classes",
      btn: "Read more",
    },

    donat: {
      title: "We will be grateful for your help",

      switch: {
        one: "One time",
        monthly: "Monthly",
      },

      other: "Another amount",
      btn: "Donate",
      btnCrypto: "Donate in Cryptocurrency",

      modal: {
        title: "Cryptocurrency",
        btc: "BTC donation wallet",
        usdt: "USDT Donation Wallet TRC-20",
      },
    },

    statistic: {
      title: "Every month we spend",
      list: {
        one: "Master classes",
        two: "School preparation classes",
        three: "Painting lessons",
        four: "Monthly charity days",
      },

      items: {
        one: "We constantly give help and support children who need it.",
        two: "We cooperate with star partners who support our activities.",
        three: "We organize social events to support every child who suffered from the war.",
      },

      desc: {
        one: "Every month we are visited by about",
        two: "happy children who are looking forward to the next meeting with us!",
      }
    },

    direc: {
      title: "Our directions",
      btnMore: "More details",
      btnBook: "Book a place",

      brain: {
        title: {
          sub: "Program",
          main: "«Brain Fitness»",
        },
        age: "From 3 to 4 years",
        time: "Classes 2 times a week, duration – 55 minutes.",
        desc: {
          one: "What will the child get after the classes?",
          two: "Why should you choose us?",
        },
        listFirst: [
          {
            item: "Improve language, learn new words on topics, according to age;",
          },
          {
            item: "Will learn to count and learn letters;",
          },
          {
            item: "Adapts to the team, gets used to being in the company of children;",
          },
          {
            item: "Teach discipline and order;",
          },
        ],
        listSecond: [
          {
            item: "You don't need to take your child to additional classes - letters, numbers, drawing, playing musical instruments are included in the watch;",
          },
          {
            item: "Creative teachers prepare special tasks and fairy tales for working with letters, thanks to which children remember them easily, and the whole process of learning to read takes place in a game and without difficulties;",
          },
          {
            item: "More than 500 useful educational toys;",
          },
          {
            item: "More than 10 tasks in one lesson! Children are not bored, but constantly busy;",
          },
          {
            item: "You do not miss a single lesson with the subscription, we appreciate your money and in the absence of the child, the missed lesson will be worked out with the teacher;",
          },
        ],
      },

      therapist: {
        title: {
          sub: "Program",
          main: "«Speech Therapist»",
        },
        time: "Duration – 60 minutes",
        desc: {
          one: "In the children's center, individual speech therapy classes are held, during which the speech therapist sets and fixes sounds, introduces them into the child's active language. Children master articulation gymnastics, consolidate the acquired skills.",
          two: "The work of a speech therapist with a child depends on its language characteristics and is carried out according to an individual plan. The duration of the corrective course also depends on the complexity of the situation.",
          three: "It is very important to notice and correct such disorders in language development in time as:",
        },
        listFirst: [
          {
            item: "Unclear, unclear speech;",
          },
          {
            item: "Speech defects;",
          },
          {
            item: "Poor vocabulary;",
          },
          {
            item: "Distorted pronunciation of complex words;",
          },
          {
            item: "The child adds or omits syllables;",
          },
          {
            item: "Difficulties in translating the read text;",
          },
          {
            item: "Difficulties in memorizing poems;",
          },
          {
            item: "Stable agrammatisms;",
          },
          {
            item: "Violation of the agreement of words in gender, number and case;",
          },
          {
            item: "No formation of phonemic hearing;",
          },
          {
            item: "In the future, the child has big problems with literacy;",
          },
        ],
      },

      school: {
        title: {
          sub: "Program",
          main: "«Preparation for school»",
        },
        age: "From 4 to 6 years",
        time: "Lessons 2 or 3 times a week, duration - 55 minutes",
        desc: {
          one: "Group classes will make it easy to go through a difficult period of adaptation. The child will receive the necessary preparation for successful learning, will feel calm and confident in classes.",
          two: "The acquired communication skills will help you easily find new friends.",
          three: "For children from 5 years of age, the class includes three blocks:",
          four: "In many schools, testing of first-graders for admission to school has been introduced. During our classes, children study the entire list of questions that are asked at an interview to a future first-grader.",
        },
        list: {
          one: "Reading - Writing;",
          two: "Mathematics - Logic;",
          three: "Speech development;",
        },
      },

      drawing: {
        title: {
          sub: "Program",
          main: "«Creativity has no limits»",
        },
        age: "More than 200 master classes have been held",
        time: "More than 700 children visited",
        desc: {
          one: "We conduct culinary master classes on all types of soap of our own production and create candy.",
          two: "At the end of each event, you take your work with you - a handmade gingerbread or marshmallow, soap or sweet creations with your own hands. Your masterpieces will become pleasant gifts for relatives and friends, or decorate the shelves in your own home. Maybe someone will discover new opportunities and make creativity their job.",
        },
      },

      vocal: {
        title: {
          sub: "Program",
          main: "«Vocal lessons»",
        },
        time: "Sunday at 11:00",
        desc: {
          one: "We have experienced teachers who know how to reveal the creative potential and artistry of every child. After all, all children are individual.",
          two: "We have developed unique training programs. In vocal classes, we teach children to breathe correctly while singing, to control their voice in order to produce the necessary sounds. We expand the range of the voice, strengthen the vocal cords, which is important for children. After all, their vocal apparatus is different from an adult's, it is exposed to heavy loads, and in some cases they may complain of pain, throat irritation, and hoarseness.",
          three: "At vocal lessons for children, we introduce kids to different musical styles, instill taste, teach them to feel songs, express their emotions. We work on barriers, shyness, learn to sing, select musical material (pop, jazz, academic), hone the skill of vocal performance, stage concerts. A child can try himself as a vocalist, as well as a member of an ensemble.",
          four: "We invite you to visit our individual vocal lessons for children. We will help reveal the talents of each child and make them a little happier! The cost of classes is indicated on the website.",
        },
      },

      art: {
        title: {
          sub: "Program",
          main: "«Art studio»",
        },
        age: "From 3 to 16 years old",
        time: "Duration – 60 minutes",
        desc: {
          one: "Is the entire refrigerator covered with children's drawings? We know how to turn them into masterpieces! We are already waiting for the baby in our painting studio for children, where creativity lives in every corner! Who said that academic drawing is difficult? Our teacher-artist is in love with her work and is convinced that anyone can draw. She will teach children how to bring their wildest fantasies to life on an easel and introduce them to various painting techniques.",
          two: "Painting for children is designed for different age groups - from 3 years to 16. Each group has its own educational program. On average, the duration of the lesson is 60 minutes. During this time, children have time to completely immerse themselves in the process and not lose interest (this is especially true for small, active kindergartners).",
          three: "We are waiting for you in our center!",
        },
      },

      modal: {
        title: "Booking a place",
        form: {
          name: "Your name",
          phone: "+380 99 999-99-99",
          error: "Message not sent! Please, please fill in all fields.",
          success: "Message successfully sent!",
          btn: "Send message",
        },
      },
    },
  },

  footer: {
    desc: {
      first: "Be a volunteer, or help as a specialist.",
      second: "Press the button!",
    },
    btn: "I want to be useful",
    list: {
      title: "Useful links",
      about: "About our fund",
      news: "News",
      contact: "Contacts",
    },
    info: {
      title: "Additional Information",
      one: "USREOU 44341172",
      two: "UA763003460000026004098334201",
      three: 'in JSC "ALPHA-BANK"',
      four: "MFIs 300346",
      five: "USREOU 23494714",
    },
  },
};
