import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Modal, Row } from "react-bootstrap";

const ModalSuccess = (props) => {
  // Translation
  const { t } = useTranslation();
  const basePath = "home.transfer.";

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{t(basePath + "title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-center">
          <Col lg={12}>
            <img
              src={require("../../assets/img/transfer/success.svg").default}
              alt=""
              style={{
                maxWidth: 300,
              }}
            />
            <h1 className="transfer">{t(basePath + "success")}</h1>
            <h2 className="thank">
              {t(basePath + "thank")} <img className="flag" src={require("../../assets/img/flags/ua.png").default} alt="" />
            </h2>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalSuccess;
