import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Form, InputGroup, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandsHelping,
  faUser,
  faPhone,
  faPaperPlane,
  faHeadset,
  faEnvelope,
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { send } from "emailjs-com";

const Request = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "home.request.";

  const [defaultValuesRequest, setDefaultValuesRequest] = useState({
    name: "",
    phone: "",
    email: "",
    textarea: "",
  });

  const handleChange = (e) => {
    setDefaultValuesRequest({ ...defaultValuesRequest, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    if (
      defaultValuesRequest.name === "" ||
      defaultValuesRequest.phone === "" ||
      defaultValuesRequest.email === "" ||
      defaultValuesRequest.textarea === ""
    ) {
      document.querySelector(".error-req").classList.add("active");
      document.querySelector(".success-req").classList.remove("active");
    } else {
      send("service_yt7ukk4", "template_8vovrhq", defaultValuesRequest, "KwLPLw6G84xmWMfAa").then(
        (result) => {
          document.querySelector(".error-req").classList.remove("active");
          document.querySelector(".success-req").classList.add("active");
        },
        (error) => {
          console.log(error.text);
        }
      );
    }
  };

  useEffect(() => {
    setDefaultValuesRequest({
      ...defaultValuesRequest,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section id="request" className="request">
      <Container>
        <Row className="justify-content-center">
          <Col lg={7}>
            <h2 className="title">
              <FontAwesomeIcon icon={faHandsHelping} />
              {t(basePath + "title")}
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={10} className="bg">
            <Row>
              <Col lg={6} className="info">
                <p>
                  <FontAwesomeIcon icon={faHeadset} />
                  {t(basePath + "info.title")}
                </p>

                <span>{t(basePath + "info.name")}</span>
                <div className="icon">
                  <FontAwesomeIcon icon={faPhone} />
                  <a href="tel:+38 (098) 187-12-12">+38 (066) 044-73-03</a>
                </div>
                <div className="icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                  <a href="mailto:turbota.dp@gmail.com">turbota.dp@gmail.com</a>
                </div>
              </Col>
              <Col lg={6} className="form">
                <p>{t(basePath + "desc")}</p>
                <Form>
                  <Row>
                    <Form.Group as={Col} md="6">
                      <InputGroup>
                        <InputGroup.Text id="inputGroupPrepend">
                          <FontAwesomeIcon icon={faUser} />
                        </InputGroup.Text>
                        <Form.Control
                          name="name"
                          type="text"
                          placeholder={t("home.help.form.name")}
                          value={defaultValuesRequest.name}
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group as={Col} md="6">
                      <InputGroup>
                        <InputGroup.Text id="inputGroupPrepend">
                          <FontAwesomeIcon icon={faPhone} />
                        </InputGroup.Text>
                        <Form.Control
                          name="phone"
                          type="number"
                          placeholder={t("home.help.form.phone")}
                          value={defaultValuesRequest.phone}
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group as={Col} md="12">
                      <InputGroup>
                        <InputGroup.Text id="inputGroupPrepend">
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          name="email"
                          type="email"
                          placeholder="example@email.com"
                          value={defaultValuesRequest.email}
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group as={Col} md="12">
                      <InputGroup>
                        <Form.Control
                          name="textarea"
                          as="textarea"
                          placeholder={t("home.help.form.mass")}
                          value={defaultValuesRequest.textarea}
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Row>

                  <div className="valid">
                    <span className="invalid-feedback error-req">
                      <FontAwesomeIcon icon={faTimesCircle} />
                      {t("home.help.form.error")}
                    </span>
                    <span className="invalid-feedback success-req">
                      <FontAwesomeIcon icon={faCheckCircle} />
                      {t("home.help.form.success")}
                    </span>
                  </div>

                  <Button onClick={onSubmit} className="btn-st">
                    <FontAwesomeIcon icon={faPaperPlane} />
                    {t("home.help.form.btn")}
                  </Button>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Request;
