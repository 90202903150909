import React, { useEffect, useState } from "react";
import Banner from "../components/Home/Banner";
import Company from "../components/Home/Company";
import Fees from "../components/Home/Fees";
import OurHelp from "../components/Home/OurHelp";
import Donat from "../components/Home/Donat";
import Hepl from "../components/Home/Hepl";
import Request from "../components/Home/Request";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import ModalSuccess from "../components/Transfer/ModalSuccess";
import ModalError from "../components/Transfer/ModalError";

function Home() {
  const [modalShowTransferSuccess, setModalShowTransferSuccess] = useState(false);
  const [modalShowTransferError, setModalShowTransferError] = useState(false);

  const locationLink = window.location.search;

  useEffect(() => {
    if (locationLink === `?success`) {
      setModalShowTransferSuccess(true);
    } else if (locationLink === `?error`) {
      setModalShowTransferError(true);
    }
  }, [locationLink]);

  return (
    <>
      <Banner />
      <Donat />
      <Company />
      <Fees />
      <OurHelp />
      <Hepl />
      <Request />
      <Contact />
      <Footer />

      <ModalSuccess show={modalShowTransferSuccess} onHide={() => setModalShowTransferSuccess(false)} />
      <ModalError show={modalShowTransferError} onHide={() => setModalShowTransferError(false)} />
    </>
  );
}

export default Home;
