import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { TRANSLATIONS_EN } from "./translations/en/en";
import { TRANSLATIONS_UA } from "./translations/ua/ua";
import { TRANSLATIONS_DE } from "./translations/de/de";
import { TRANSLATIONS_ES } from "./translations/es/es";
import { TRANSLATIONS_FR } from "./translations/fr/fr";
import { TRANSLATIONS_IT } from "./translations/it/it";
import { TRANSLATIONS_PL } from "./translations/pl/pl";
import { TRANSLATIONS_PT } from "./translations/pt/pt";

export const languages = ["ua-UA", "en-US", "de-DE", "es-ES", "fr-FR", "it-IT", "pl-PL", "pt-PT"];

i18n.use(initReactI18next).init({
  fallbackLng: "en-US",
  resources: {
    "en-US": {
      translation: TRANSLATIONS_EN,
    },
    "ua-UA": {
      translation: TRANSLATIONS_UA,
    },
    "de-DE": {
      translation: TRANSLATIONS_DE,
    },
    "es-ES": {
      translation: TRANSLATIONS_ES,
    },
    "fr-FR": {
      translation: TRANSLATIONS_FR,
    },
    "it-IT": {
      translation: TRANSLATIONS_IT,
    },
    "pl-PL": {
      translation: TRANSLATIONS_PL,
    },
    "pt-PT": {
      translation: TRANSLATIONS_PT,
    },
  },
});

if (localStorage.getItem("i18nextLng") && languages.some((i) => i === localStorage.getItem("i18nextLng"))) {
  i18n.changeLanguage(localStorage.getItem("i18nextLng"));
} else {
  localStorage.setItem("i18nextLng", languages[0]);
  i18n.changeLanguage(languages[0]);
}

export default i18n;
