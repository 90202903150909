import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NEWS_ARRAY } from "../../utils/constants";
import { useLanguage } from "../../contexts/LanguageContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faArrowRight } from "@fortawesome/free-solid-svg-icons";

function NewsPreview() {
  // Translation
  const { t } = useTranslation();
  const basePath = "news.";

  const { language } = useLanguage();
  const newsList = useCallback(() => {
    return NEWS_ARRAY.map((item) => (
      <Col lg={4} md={6} key={item.id}>
        <div className="item">
          <div className="img">
            <img src={item.img_preview.default} alt="" />
          </div>
          <div className="info">
            <span>
              <FontAwesomeIcon icon={faCalendarAlt} />
              {item.time}
            </span>
            <h5>
              <Link
                to={{
                  pathname: `/news/${item.id}`,
                }}
              >
                {item.data[language].title}
              </Link>
            </h5>
            <div className="btn-news">
              <Link
                to={{
                  pathname: `/news/${item.id}`,
                }}
              >
                {t(basePath + "btn")}
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </div>
          </div>
        </div>
      </Col>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <Container>
      <Row className="news">{newsList()}</Row>
    </Container>
  );
}

export default NewsPreview;
