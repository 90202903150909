import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingDollar, faHandHoldingMedical, faHandsHelping } from "@fortawesome/free-solid-svg-icons";

const Banner = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "home.banner.";

  return (
    <section className="banner">
      <Container>
        <Row>
          <Col lg={7}>
            <h1>
              <span>{t(basePath + "title.first")}</span>
              <br />
              {t(basePath + "title.second")}
            </h1>
            <p>{t(basePath + "desc")}</p>
            <div className="btn-groups">
              <Button href="#donat" className="btn-st">
                <FontAwesomeIcon icon={faHandHoldingDollar} />
                {t(basePath + "btnDonat")}
              </Button>
              <Button href="#help" className="btn-st transparent">
                <FontAwesomeIcon icon={faHandHoldingMedical} />
                {t(basePath + "btnHelp")}
              </Button>
              <Button href="#request" className="btn-st">
                <FontAwesomeIcon icon={faHandsHelping} />
                {t(basePath + "btnRequ")}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
