import React from "react";
import PageBanner from "../components/PageBanner";
import NewsPreview from "../components/News/NewsPreview";
import Footer from "../components/Footer";

const News = () => {
  return (
    <>
      <PageBanner />
      <NewsPreview />
      <Footer />
    </>
  );
};

export default News;
